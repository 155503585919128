import { Language } from "./../data/models/ContractTypes";
import { atom } from "recoil";
import { AssociateContractViewer } from "../data/dataMerchant";
import { AssociateId } from "../data/models/ContractTypes";

export const viewerState = atom<AssociateContractViewer>({
  key: "viewer-state",
  default: {
    language: Language.UK,
    name: "",
    email: "",
    showConfirmation: false,
    showUploadCompanyDocuments: false,
    associateId: "" as AssociateId,
  },
});
