import React from "react";
import { Checkboxes } from "../../components/interactions/Checkboxes/Checkboxes";
import { useTranslation } from "react-i18next";
import { Country, TerminalType } from "../../data/models/ContractTypes";
import "./ContractAdditionalDocs.scss";
import { Status } from "../../data/types";
import { Terminal } from "../../data/dataMerchant";

interface Props {
  mcc: string;
  confirmedTerms: string;
  onChange?: () => void;
  country: Country;
  disabled?: boolean;
  linkId: string;
  terminals: Terminal[];
}

export const MCC_HOTEL = "7011";
export const MCC_CAR_RENTAL = "7512";

const getAdditionalDocLinks = (country: Country, terminals: Terminal[], mcc: string) => {
  switch (country) {
    case Country.CZECHIA:
      return getCzechiaDocuments(terminals, mcc);
    case Country.POLAND:
      return getPolandDocuments(terminals, mcc);
    case Country.CROATIA:
      return getCroatiaDocuments(terminals, mcc);
    default:
      return null;
  }
};

export const ContractAdditionalDocs: React.FunctionComponent<Props> = ({
  mcc,
  confirmedTerms,
  onChange,
  country,
  disabled = false,
  linkId,
  terminals,
}) => {
  const { t } = useTranslation();

  const documents = getAdditionalDocLinks(country, terminals, mcc);

  return (
    <div className="contract-additional-docs">
      <strong>{t("Additional documents")}</strong>
      <ul>
        {documents?.map((doc) => (
          <li>
            <a href={doc.link} target="_blank" rel="noreferrer">
              {t(doc.text)}
            </a>
          </li>
        ))}
      </ul>

      {onChange && (
        <Checkboxes
          className="compact"
          onChange={onChange}
          values={[confirmedTerms]}
          status={disabled ? Status.DISABLED : Status.DEFAULT}
          alternatives={[
            {
              value: linkId,
              text: t("Yes, I have read the additional information"),
            },
          ]}
        />
      )}
    </div>
  );
};

interface DocumentLink {
  text: string;
  link: string;
}

export const getCzechiaDocuments = (terminals: Terminal[], mcc: string): DocumentLink[] => {
  const hasSoftpos = terminals.some((terminal) => terminal.terminalType === TerminalType.SOFT_POS);
  const hasEp2Terminals = terminals.some(
    (terminal) => terminal.terminalType !== TerminalType.SOFT_POS
  );

  const documentLinks = [
    {
      condition: true,
      text: "PCI DSS compliance safety rules",
      link: "/docs/CZ/110011008-KB-ds-pci-dss-compliance-Instructions-int-cz.pdf",
    },
    {
      condition: true,
      text: "General terms and conditions for accepting cards",
      link: "/docs/CZ/116130508_KB_TC_GBC_for_Card_Acceptance_EULUX_CZ_09_2023.pdf",
    },
    {
      condition: hasSoftpos,
      text: "General terms and conditions for PayPhone",
      link: "/docs/CZ/116131708-KB_TC_GBC_for_Payment_Terminals_EULUX_CZ_07_2021.pdf",
    },
    {
      condition: hasEp2Terminals,
      text: "General terms and conditions for Payment Terminals",
      link: "/docs/CZ/116131708-KB_TC_GBC_for_Payment_Terminals_EULUX_CZ_07_2021.pdf",
    },
    {
      condition: mcc === MCC_HOTEL,
      text: "Hotel reservation by means of down payment with a credit card (Hotel Advance Deposit)",
      link: "/docs/CZ/110015408-KB-ds-hotel-advance-deposit-int-cz.pdf",
    },
    {
      condition: mcc === MCC_CAR_RENTAL,
      text: "Rental car reservation with a credit card",
      link: "/docs/CZ/110003808-KB-ds-rental-car-reservation-cc-int-cz.pdf",
    },
  ];

  return documentLinks.filter((doc) => doc.condition).map(({ text, link }) => ({ text, link }));
};

export const getPolandDocuments = (terminals: Terminal[], mcc: string): DocumentLink[] => {
  const documentLinks = [
    {
      condition: true,
      text: "PCI DSS compliance safety rules",
      link: "/docs/PL/110011005-ds-pci-dss-compliance-Instructions-int-pl-opt.pdf",
    },
    {
      condition: true,
      text: "General terms and conditions for accepting cards",
      link: "/docs/PL/116130705_TC_GBC_for_Card_Acceptance_POL_PL_04_2022.pdf",
    },
    {
      condition: false,
      text: "General terms and conditions for Tap on Mobile",
      link: "nothing here yet",
    },
    {
      condition: true,
      text: "General terms and conditions for payment terminals",
      link: "/docs/PL/116132005_TC_GBC_for_Payment_Terminals_POL_PL_07_2021.pdf",
    },
    {
      condition: mcc === MCC_HOTEL,
      text: "Hotel reservation guarantee per credit card",
      link: "/docs/PL/110015505-ds-hotel-reservation-creditcard-int-pl-opt.pdf",
    },
    {
      condition: mcc === MCC_HOTEL,
      text: "Hotel reservation by means of down payment with a credit card (Hotel Advance Deposit)",
      link: "/docs/PL/110015405_DS_Hotel_Advance_Deposit_INT_PL_opt.pdf",
    },
    {
      condition: mcc === MCC_CAR_RENTAL,
      text: "Rental car reservation with a credit card",
      link: "/docs/PL/110003805_DS_Rental_Car_Reservation_CC_INT_PL_opt.pdf",
    },
  ];

  return documentLinks.filter((doc) => doc.condition).map(({ text, link }) => ({ text, link }));
};

export const getCroatiaDocuments = (terminals: Terminal[], mcc: string): DocumentLink[] => {
  const documentLinks = [
    {
      condition: true,
      text: "PCI DSS compliance safety rules",
      link: "/docs/HR/110011012-ds-pci-dss-compliance-instructions-int-hr-opt.pdf",
    },
    {
      condition: true,
      text: "General terms and conditions for accepting cards",
      link: "/docs/HR/116130512_TC_GBC_for_Card_Acceptance_EULUX_HR-04-2022.pdf",
    },
    {
      condition: false,
      text: "General terms and conditions for Tap on Mobile",
      link: "nothing here yet",
    },
    {
      condition: true,
      text: "General terms and conditions for payment terminals",
      link: "/docs/HR/116131712_TC_GBC_for_Payment_Terminals_EULUX_HR_07_2021.pdf",
    },
    {
      condition: mcc === MCC_HOTEL,
      text: "Hotel reservation guarantee per credit card",
      link: "/docs/HR/110015512-ds-hotel-reservation-creditcard-int-hr-opt.pdf",
    },
    {
      condition: mcc === MCC_HOTEL,
      text: "Hotel reservation by means of down payment with a credit card (Hotel Advance Deposit)",
      link: "/docs/HR/110015412-ds-hotel-advance-deposit-int-hr-opt.pdf",
    },
    {
      condition: mcc === MCC_CAR_RENTAL,
      text: "Rental car reservation with a credit card",
      link: "/docs/HR/110003812-ds-rental-car-reservation-CC-int-hr-opt.pdf",
    },
  ];

  return documentLinks.filter((doc) => doc.condition).map(({ text, link }) => ({ text, link }));
};
