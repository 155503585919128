import { atom } from "recoil";
import { MerchantContractConfirmation } from "../data/dataMerchant";
import { UTCDate } from "../data/models/ContractTypes";

export const NAME_CONFIRMED = "wlx-confirmed";

function getTermsStatus() {
  const storedStringData = window.localStorage.getItem(NAME_CONFIRMED);
  if (storedStringData) {
    try {
      return JSON.parse(storedStringData).confirmedTerms || "";
    } catch (err) {
      return "";
    }
  }

  return "";
}

export const confirmedState = atom<MerchantContractConfirmation>({
  key: "confirmed-state",
  default: {
    confirmedTerms: getTermsStatus(),
    confirmed: false,
    pendingSalesConfirmation: false,
    contractComplete: false,
    sentForConfirmation: new Date().toUTCString() as UTCDate,
  },
});
