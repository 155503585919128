import React, { useCallback, useState } from "react";
import cx from "classnames";
import { dataKlarna, KlarnaAccount, KlarnaPollResponse } from "../../../../data/dataKlarna";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { CheckmarkTag } from "../../../../components/checkmarkTag/CheckmarkTag";
import { AnimateHeightMotion } from "../../../../components/animate/AnimateHeightMotion";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import { contractState } from "../../../../state/contractState";
import { useRecoilState } from "recoil";
import { Status } from "../../../../data/types";
import { BankStatementUpload } from "./BankStatementUpload";

import { generatePath, useNavigate } from "react-router-dom";
import { CONTRACT_PAGE_URL } from "../../Contract";
import { Radio } from "../../../../components/icons/Radio";
import "./KlarnaAccounts.scss";
import { useLinkId } from "../../../../hooks/useLinkId";

interface Props {
  klarnaResponse?: KlarnaPollResponse;
  onChangeBank: () => void;
}

function getText(account: KlarnaAccount) {
  if (account.accountStatus === "NO_HOLDER") {
    return "The account holder information was not provided by your bank. This account is therefore not selectable.";
  }

  if (account.accountStatus === "INVALID_TYPE") {
    return "The account type was not supplied or was incorrect. This account is therefore not selectable.";
  }

  if (account.accountStatus === "INVALID_CURRENCY") {
    return "The account can't be used since it does not have the valid currency. This account is therefore not selectable.";
  }

  return "Your bank did not provide adequate information for this account. This account is therefore not selectable.";
}

export const KlarnaAction: React.FunctionComponent<{
  account: KlarnaAccount;
  onClick: (account: KlarnaAccount) => void;
  t: TFunction;
  status: Status;
}> = ({ account, onClick, t, status }) => {
  if (account.accountStatus === "MATCH" || account.accountStatus === "VALID") {
    return (
      <Button
        ghost
        onClick={() => onClick(account)}
        status={status !== Status.DEFAULT ? Status.DISABLED : status}
      >
        {t("Select account")}
      </Button>
    );
  }

  return <div className="klarna-account-info">{t(getText(account))}</div>;
};

export const KlarnaAccounts: React.FunctionComponent<Props> = ({
  klarnaResponse,
  onChangeBank,
}) => {
  const [{ contract }, setContract] = useRecoilState(contractState);
  const linkId = useLinkId();
  const { t } = useTranslation();
  const recommendedAccount = klarnaResponse?.accounts?.find(
    (item) => item.accountStatus === "MATCH"
  );
  const [selectedAccount, setSelectedAccount] = useState<KlarnaAccount | undefined>(
    recommendedAccount
  );
  const [message, setMessage] = useState<string>("");
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const navigate = useNavigate();

  const onNext = useCallback(() => {
    if (!linkId) return;

    setMessage("");

    if (!selectedAccount) {
      setMessage("Please select an account before proceeding");
      return;
    }

    setStatus(Status.PENDING);

    setContract((prevContract) => {
      return {
        ...prevContract,
        contract: {
          ...prevContract.contract,
          bankAccount: {
            iban: selectedAccount.iban,
            accountHolder: selectedAccount.accountHolder,
          },
        },
      };
    });

    dataKlarna
      .selectAccount(linkId, selectedAccount.iban || "")
      .then(() => {
        navigate(generatePath(CONTRACT_PAGE_URL, { linkId }));
      })
      .catch((err) => {
        setMessage("Something went wrong. Try again?");
        setTimeout(() => {
          setStatus(Status.DEFAULT);
          setMessage("");
        }, 3000);
      });
  }, [selectedAccount, navigate, setContract, linkId]);

  if (!klarnaResponse) {
    return <div />;
  }

  if (!klarnaResponse.accounts?.length) {
    return (
      <div className="m-top-20">
        <h4>{t("No accounts found")}</h4>
        <p>
          {t(
            "We couldn't find any accounts. Instead you can verify the bank account associated with your business by uploading a bank statement that confirms the account."
          )}
        </p>
        <div className="m-top-40">
          <BankStatementUpload status={status} />
        </div>
      </div>
    );
  }

  return (
    <div className="m-top-20">
      <AnimateHeightMotion presence>
        {!!contract.bankAccount.statementUploaded ? (
          <div />
        ) : (
          <>
            <div className="available-klarna-accounts">
              <h4 className="m-bottom-10">{t("Available accounts")}</h4>
              <div>
                {klarnaResponse.accounts.map((account, idx) => {
                  const isSelectable =
                    account.accountStatus === "MATCH" || account.accountStatus === "VALID";

                  return (
                    <div
                      className={cx("klarna-account", {
                        selected: account.iban === selectedAccount?.iban,
                        available: isSelectable,
                        "is-disabled": !isSelectable,
                      })}
                      key={account.iban || idx}
                    >
                      {/* <Beacon validity={Validity.VALID} /> */}

                      <button
                        className="klarna-account-inner"
                        onClick={() => {
                          setMessage("");
                          setSelectedAccount(account);
                        }}
                      >
                        <CheckmarkTag
                          animated={true}
                          active={account.iban === selectedAccount?.iban}
                        />

                        <Radio checked={account.iban === selectedAccount?.iban} />

                        <ul>
                          <li>
                            <b className="truncate">{account.accountName || "-"}</b>
                          </li>
                          {isSelectable ? (
                            <li>
                              <span className="truncate">{account.accountHolder || "-"}</span>
                            </li>
                          ) : (
                            <div className="klarna-account-info">{t(getText(account))}</div>
                          )}
                        </ul>

                        {/* <KlarnaAction
                          status={status}
                          account={account}
                          t={t}
                          onClick={(account) => {
                            setMessage("");
                            setSelectedAccount(account);
                          }}
                        /> */}
                      </button>
                      {/* {!isSelectable && (
                        <div className="klarna-account-info">
                          {t(getText(account))}
                        </div>
                      )} */}
                    </div>
                  );
                })}
              </div>

              <div className="m-top-30">
                <AnimateHeightMotion presence>
                  {message ? <ErrorBox relative>{t(message)}</ErrorBox> : <div />}
                </AnimateHeightMotion>
              </div>

              <div className="m-top-10">
                <Button block status={status} onClick={onNext}>
                  {t("Done!")}
                </Button>

                <div className="center m-top-20">
                  <button onClick={onChangeBank} className="as-link">
                    {t("No wait, let me choose another bank")}
                  </button>
                </div>

                {/* <div className="center m-top-10">
                  <Link
                    to={generatePath(SIGNABLE_CONTRACT_PAGE_URL, { linkId })}
                  >
                    {t("Back")}
                  </Link>
                </div> */}
              </div>
            </div>
            <p className="klarna-cant-find-account">
              {t(
                "Can't find your account? You can add an account manually by uploading a bank statement"
              )}
            </p>
          </>
        )}
      </AnimateHeightMotion>

      <BankStatementUpload status={status} />
    </div>
  );
};
