import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { AnimateHeightMotion } from "../../../../components/animate/AnimateHeightMotion";
import { CardPageLayout } from "../../../../components/cardPageLayout/CardPageLayout";
import { Pending } from "../../../../components/icons/Pending";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Or } from "../../../../components/or/Or";
import {
  dataKlarna,
  KlarnaPollResponse,
  KlarnaState,
} from "../../../../data/dataKlarna";
import { KlarnaToken } from "../../../../data/models/ContractTypes";
import { Status } from "../../../../data/types";
import { contractState } from "../../../../state/contractState";
import { ContractLoader } from "../../ContractLoader/ContractLoader";
import { BankStatementUpload } from "./BankStatementUpload";
import { KlarnaAccounts } from "./KlarnaAccounts";
import "./AccountSelection.scss";

interface Props {
  withoutContractLoader?: boolean;
}

enum Reason {
  ABORTED,
  ERROR,
  DEFAULT,
}

// const isDev = "development" === process.env.NODE_ENV;

const POLLING_DELAY = 3000;
const INITIAL_DELAY = 3000;

export const ACCOUNT_SELECTION_URL = "/:linkId/select-account";

export const AccountSelection: React.FunctionComponent<Props> = ({
  withoutContractLoader = false,
}) => {
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [clientToken, setClientToken] = useState<KlarnaToken>();
  const [klarnaResponse, setKlarnaResponse] = useState<KlarnaPollResponse>();
  const [, setReason] = useState<Reason>(Reason.DEFAULT);
  const { t } = useTranslation();
  const [{ linkId }, setContract] = useRecoilState(contractState);
  const timer = useRef<number>();
  // const test = useRef<number>(0);

  const isFirstPoll = useRef<boolean>(true);
  const noOfRetries = useRef<number>(0);

  const deleteSession = useCallback(
    (cb?: () => void) => {
      dataKlarna
        .deleteSession(linkId)
        .then(() => {
          setStatus(Status.DEFAULT);
          setClientToken(undefined);
          cb && cb();
        })
        .catch(() => {
          setStatus(Status.DEFAULT);
          setClientToken(undefined);
          cb && cb();
        });
    },
    [linkId]
  );

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const pollForClientAccounts = useCallback(() => {
    if (!clientToken) {
      return;
    }

    dataKlarna
      .pollForClientAccounts(linkId)
      .then((data) => {
        console.log("data: ", data);
        noOfRetries.current = 0;
        const delay = isFirstPoll.current ? INITIAL_DELAY : POLLING_DELAY;
        isFirstPoll.current = false;

        if (data.state === KlarnaState.CONSUMER_INPUT_NEEDED) {
          // setClientToken(data.clientToken || clientToken);

          timer.current = window.setTimeout(() => {
            pollForClientAccounts();
          }, delay);
          return;
        }

        if (
          data.state === KlarnaState.ABORTED ||
          data.state === KlarnaState.EXCEPTION
        ) {
          deleteSession(() => {
            setStatus(Status.ERROR);
          });
          return;
        }

        if (data.state === KlarnaState.FINISHED) {
          setStatus(Status.SUCCESS);
          setKlarnaResponse(data);
          return;
        }

        timer.current = window.setTimeout(() => {
          pollForClientAccounts();
        }, delay);
      })
      .catch((err) => {
        console.log("err", err);

        if (noOfRetries.current > 4) {
          setStatus(Status.ERROR);

          deleteSession();
          return;
        }

        noOfRetries.current++;
        timer.current = window.setTimeout(() => {
          pollForClientAccounts();
        }, POLLING_DELAY);
      });
  }, [deleteSession, linkId, clientToken]);

  const request = useCallback(() => {
    Promise.all([dataKlarna.initKlarna(linkId), dataKlarna.getKlarnaSDK()])
      .then((response) => {
        const { clientToken: token } = response[0];
        setClientToken(token);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, [linkId]);

  useEffect(() => {
    if (!linkId) {
      return;
    }
    setStatus(Status.PENDING);
    setClientToken(undefined);
    request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkId]);

  const onRetry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(request, 2000);
  }, [request]);

  const onChangeBank = useCallback(() => {
    deleteSession(() => {
      setStatus(Status.PENDING);
      request();
    });
  }, [deleteSession, request]);

  const onClick = useCallback(() => {
    setStatus(Status.PENDING);
    setClientToken(undefined);
    request();
  }, [request]);

  useEffect(() => {
    if (!clientToken) {
      return;
    }

    // Removed this if-statement, breaks the flow in dev
    // if (isDev) {
    //   setContract((prevContract) => {
    //     return {
    //       ...prevContract,
    //       contract: {
    //         ...prevContract.contract,
    //         bankAccount: {
    //           statementUploaded: undefined,
    //         },
    //       },
    //     };
    //   });
    //   pollForClientAccounts();
    //   // if (test.current > 0) {
    //   //   setContract((prevContract) => {
    //   //     return {
    //   //       ...prevContract,
    //   //       contract: {
    //   //         ...prevContract.contract,
    //   //         bankAccount: {
    //   //           statementUploaded: undefined,
    //   //         },
    //   //       },
    //   //     };
    //   //   });
    //   //   pollForClientAccounts();
    //   //   return;
    //   // }

    //   // setTimeout(() => {
    //   //   setReason(Reason.ABORTED);
    //   //   deleteSession();
    //   //   test.current += 1;
    //   // }, 3000);
    //   return;
    // }

    try {
      window.XS2A.configure({
        appearenceMode: "light",
        autoClose: true,
        hideTransitionOnFlowEnd: true,
        openPoliciesInNewWindow: true,
        psd2RedirectMethod: "newWindow",
        skipInitialLoader: true,
        theme: "default",
        unfoldConsentDetails: false,
        onLoad: () => {
          console.log("onLoad called");
        },
        onReady: () => {
          console.log("onReady called");
        },
        onAbort: () => {
          console.log("onAbort called");
        },
        onError: (error: any) => {
          console.log("onError called", error);
        },
        onFinished: () => {
          console.log("onFinished called");
        },
        onClose: () => {
          console.log("onClose called");
        },
      });

      // Start the flow with the client_token from the flow response.
      window.XS2A.startFlow(clientToken, {
        onFinished: () => {
          setContract((prevContract) => {
            return {
              ...prevContract,
              contract: {
                ...prevContract.contract,
                bankAccount: {
                  statementUploaded: undefined,
                },
              },
            };
          });
          pollForClientAccounts();
        },
        onAbort: () => {
          setReason(Reason.ABORTED);
          deleteSession();
        },
        onError: (error: any) => {
          // Handle error that happened while opening the App
          setReason(Reason.ERROR);
          deleteSession();
          console.error(
            "onError: something bad happened during the flow.",
            error
          );
        },
      });
    } catch (e) {
      // Handle error that happened while opening the App
      deleteSession(() => {
        setStatus(Status.ERROR);
      });
      console.error("klarna error", e);
      setReason(Reason.ERROR);
    }
  }, [clientToken, deleteSession, pollForClientAccounts, setContract]);

  const getElem = useCallback(
    (currentStatus: Status) => {
      if (currentStatus === Status.DEFAULT) {
        return (
          <div className="m-top-30">
            <Or>
              <div>
                <Button block onClick={onClick} status={currentStatus}>
                  {t("Login to your bank")}
                </Button>
              </div>

              <div>
                <BankStatementUpload status={status} />
              </div>
            </Or>
          </div>

          // <>
          //   <Button block onClick={onClick} status={currentStatus}>
          //     {t("Login to your bank")}
          //   </Button>
          //   <div className="center m-top-10">
          //     <Link to={generatePath(SIGNABLE_CONTRACT_PAGE_URL, { linkId })}>
          //       {t("Back")}
          //     </Link>
          //   </div>
          // </>
        );
      }

      if (currentStatus === Status.PENDING) {
        return (
          <div className="center">
            {t("Waiting for your bank")}&nbsp; <Pending />
          </div>
        );
      }

      if (currentStatus === Status.SUCCESS) {
        return (
          <div>
            <KlarnaAccounts
              klarnaResponse={klarnaResponse}
              onChangeBank={onChangeBank}
            />
          </div>
        );
      }

      return (
        <div className="m-top-30">
          <div className="m-bottom-40">
            <b className="text-large">{t("Oh no!")}</b>
            <br />
            {t("Something went wrong. Try again?")}
          </div>
          <Or>
            <div>
              <Button block onClick={onRetry}>
                {t("Try again")}
              </Button>
            </div>

            <div>
              <BankStatementUpload status={status} />
            </div>
          </Or>
        </div>
      );
    },
    [onRetry, status, klarnaResponse, onClick, t, onChangeBank]
  );

  if (withoutContractLoader) {
    return (
      <div className="account-selection">
        <CardPageLayout>
          <div className="m-top-40">
            <AnimateHeightMotion presence>
              {getElem(status)}
            </AnimateHeightMotion>
          </div>
        </CardPageLayout>
      </div>
    );
  }

  return (
    <div className="account-selection">
      <ContractLoader>
        <CardPageLayout>
          <div className="m-top-40">
            <AnimateHeightMotion presence>
              {getElem(status)}
            </AnimateHeightMotion>
          </div>
        </CardPageLayout>
      </ContractLoader>
    </div>
  );
};
