import React, {
  Dispatch,
  SetStateAction,
  useState,
  VoidFunctionComponent,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { Associate } from "../../../data/dataMerchant";
import { SignatureModal } from "./SignatureModal";
import { DateTime } from "luxon";
import "./Signatures.scss";
import {
  isAccountHolder,
  isPrimary,
  isSignee,
} from "../../../state/associateState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { actionState } from "../../../state/actionsState";
import { useRequiresBankAccount } from "../../../hooks/useRequiresBankAccount";
import { useRequiresDocs } from "../../../hooks/useRequiresDocs";
import { useRequiresIndentification } from "../../../hooks/useRequiresIndentification";
import { confirmedState } from "../../../state/confirmedState";
import { contractState } from "../../../state/contractState";
import { ids } from "../Contract";

interface Props {
  associate?: Associate;
  signees?: Associate[];
}

const AddSignature: VoidFunctionComponent<{
  associate: Associate;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ associate, setIsModalOpen }) => {
  const { t } = useTranslation();
  const setActionsOpen = useSetRecoilState(actionState);
  const requiresIdentification = useRequiresIndentification();
  const requiresBankAccount = useRequiresBankAccount();
  const requiresDocs = useRequiresDocs();
  const confirmed = useRecoilValue(confirmedState);
  const { linkId } = useRecoilValue(contractState);

  if (associate.signatory?.signed) {
    return null;
  }

  return (
    <button
      type="button"
      className="signing-action"
      onClick={() => {
        if (isPrimary(associate)) {
          if (requiresDocs) {
            setActionsOpen(true);
            return;
          }
        }

        if (isSignee(associate) && !isPrimary(associate)) {
          if (linkId !== confirmed.confirmedTerms) {
            setActionsOpen(true);
            return;
          }
        }

        if (isAccountHolder(associate)) {
          if (requiresBankAccount) {
            setActionsOpen(true);
            return;
          }
        }

        if (requiresIdentification) {
          setActionsOpen(true);
          return;
        }

        setIsModalOpen(true);
      }}
    >
      <span>{t("Click to sign")}</span>
    </button>
  );
};

export const Signatures: VoidFunctionComponent<Props> = ({
  associate,
  signees,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!associate || !signees) {
    return null;
  }

  const associateIsSignee = isSignee(associate);

  return (
    <div className="signatures" id={ids.SIGNATURE}>
      <h4 className="signature-header">{t("Signatures")}</h4>
      <div className="signature-wrapper">
        {associateIsSignee && (
          <div className="signatory">
            {associate.signatory?.signed && (
              <div className="signature">
                <img
                  src={associate.signatory.signature}
                  alt={t(`Signature for {name}`, {
                    name:
                      associate.contact.firstName +
                      " " +
                      associate.contact.lastName,
                  })}
                />
              </div>
            )}
            <AddSignature
              setIsModalOpen={setIsModalOpen}
              associate={associate}
            />
            <div className="signee-name">
              {associate.contact.firstName} {associate.contact.lastName}
            </div>
            {associate.signatory?.signed && (
              <div className="signing-date">
                <Trans>Signed</Trans>{" "}
                {DateTime.fromISO(associate.signatory.signed).toFormat(
                  "yyyy-MM-dd HH:mm:ss z"
                )}
              </div>
            )}
          </div>
        )}
        {signees
          .filter((ass) => ass.associateId !== associate.associateId)
          .map((associate) => (
            <div className="signatory" key={associate.associateId}>
              {associate.signatory?.signed && (
                <div className="signature">
                  <img
                    src={associate.signatory.signature}
                    alt={t(`Signature for {name}`, {
                      name:
                        associate.contact.firstName +
                        " " +
                        associate.contact.lastName,
                    })}
                  />
                </div>
              )}
              {!associate.signatory?.signed && (
                <div className="signing-pending">
                  {t("Waiting for signature")}
                </div>
              )}
              <div className="signee-name">
                {associate.contact.firstName} {associate.contact.lastName}
              </div>
              {associate.signatory?.signed && (
                <div className="signing-date">
                  <Trans>Signed</Trans>{" "}
                  {DateTime.fromISO(associate.signatory.signed).toFormat(
                    "yyyy-MM-dd HH:mm:ss z"
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
      <SignatureModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onSignCompleted={() => setIsModalOpen(false)}
      />
    </div>
  );
};
