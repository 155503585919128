import React from "react";
import { ContractPackage, Store } from "../../../data/dataMerchant";
import { Trans, useTranslation } from "react-i18next";
import "./PricingCZ.scss";
import { VPay } from "../../../components/logos/VPay";
import { Mastercard } from "../../../components/logos/Mastercard";
import { Maestro } from "../../../components/logos/Maestro";
import { Checkmark } from "../../../components/icons/Checkmark";
import { Country, TerminalType } from "../../../data/models/ContractTypes";
import { Link } from "../../../components/link/Link";
import { useFirstInvoiceMonth } from "../../../hooks/useFirstInvoiceMonth";
import { TerminalPreview } from "./components/TerminalPreview";
import { Visa } from "../../../components/logos/Visa";

interface Props {
  contractPackage: ContractPackage;
  stores: Store[];
}

const CURRENCY = "CZK";
const WORLDLINE_FEE = 1.45;
const INTERCHANGE_URL =
  "http://www.worldline.com/merchant-services/interchange";
const SCHEMA_URL = "http://www.worldline.com/merchant-services/schemefees";
const CASHLESS_LINK =
  "https://www.payphone.cz/wp-content/uploads/2022/04/Podminky-nabidky-%E2%80%9ECesko-plati-kartou-100-sleva-na-12-mesicu-PayPhone.pdf";

function getNumberOfTerminalsOfType(stores: Store[], type: TerminalType) {
  return stores.reduce(
    (prev, currentStore) =>
      prev +
      currentStore.terminals.filter(
        (terminal) => terminal.terminalType === type
      ).length,
    0
  );
}

export const PricingCZ: React.FunctionComponent<Props> = ({
  contractPackage,
  stores,
}) => {
  const { t } = useTranslation();
  const firstInvoiceMonth = useFirstInvoiceMonth(
    contractPackage.promotionMonths
  );

  let noOfTerminals = getNumberOfTerminalsOfType(stores, TerminalType.SOFT_POS);

  return (
    <div className="pricing pricing-cz">
      <div className="tablet-columns">
        <div>
          <dl>
            <dt>{t("Package")}</dt>
            <dd>
              <TerminalPreview
                type={TerminalType.SOFT_POS}
                count={noOfTerminals}
                country={Country.CZECHIA}
              />
            </dd>
            <dt>{t("Services")}</dt>
            <dd>
              <ul>
                <li>{t("Hotline")}</li>
                <li>{t("App-download")}</li>
                <li>{t("Software updates")}</li>
                <li>{t("System operation")}</li>
              </ul>
            </dd>
            {contractPackage.promotionMonths > 0 ? (
              <>
                <dt>{t("First invoice")}</dt>
                <dd>{firstInvoiceMonth}</dd>
              </>
            ) : null}
          </dl>
        </div>
        <div>
          <dl>
            <dt>{t("Reimbursement currency")}</dt>
            <dd>{CURRENCY}</dd>
            <dt>{t("Reimbursement frequency")}</dt>
            <dd>{t("Daily")}</dd>
            <dt>{t("Reimbursement notice")}</dt>
            <dd>{t("Transaction level (detailed)")}</dd>
            <dt>{t("Features included")}</dt>
            <dd>{t("Online reports")}</dd>
          </dl>
        </div>
      </div>

      {contractPackage.cashlessPromotion && (
        <div>
          <dl>
            <dt>
              {t("Cashless payments")}
              <Checkmark />
            </dt>
            <dd className="text-small">
              <Trans>
                I have applied to the government program and benefit from 100%
                discount on fees from transactions made via the PayPhone
                application up to CZK 50,000 (EUR 2,000) per month for 12
                months. I agree with the{" "}
                <Link link={CASHLESS_LINK} external>
                  conditions of the special offer
                </Link>
                .
              </Trans>
            </dd>
          </dl>
        </div>
      )}

      <div>
        <dl>
          <dt>{t("Pricing tables presence business")}</dt>
          <dd>
            <div className="pricing-table-cz text-small">
              <div className="table-title">
                <span>{t("Pricing model Interchange (IF++)")}</span>
              </div>
              <div className="boxes">
                <div className="logos">
                  <Visa />
                  <VPay />
                  <Mastercard />
                  <Maestro />
                </div>
                <div className="box">
                  <div className="title">{t("Interchange fees")}</div>
                  <div className="content">
                    <p>
                      {t(
                        "As applied by the respective Card Scheme on behalf of the Card Issuers."
                      )}
                    </p>
                    <div>
                      {t("Refer to")}
                      <br />
                      <Link external link={INTERCHANGE_URL}>
                        {t("Interchange fees")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="title">{t("Card Scheme fees")}</div>
                  <div className="content">
                    <p>{t("As applied by the respective Card Scheme.")}</p>

                    <div>
                      {t("Refer to")}
                      <br />
                      <Link external link={SCHEMA_URL}>
                        {t("Card Scheme fees")}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="title">{t("Worldline acquiring fee")}</div>
                  <div className="content">
                    <p className="fee">{WORLDLINE_FEE} %</p>
                  </div>
                </div>
              </div>
            </div>
          </dd>
        </dl>
      </div>

      <p className="disclaimer text-small">
        <Trans>
          Worldline offers and invoices the Merchant for the agreed card brands
          and card types with different levels of interchange fees, individually
          itemized charges (interchange fees, card scheme fees and Worldline
          acquiring fees). Unless otherwise agreed, these shall also be charged
          in the case of a refund.
        </Trans>
      </p>
      <p className="disclaimer text-small">
        <Trans>
          The Merchant declares to have acknowledged the individually itemized
          information according to card brand and card type in relation to the
          different levels of interchange fees and card scheme fees available
          under{" "}
          <Link external link={INTERCHANGE_URL}>
            Interchange fees
          </Link>{" "}
          and{" "}
          <Link external link={SCHEMA_URL}>
            Card Scheme fees
          </Link>
        </Trans>
      </p>
    </div>
  );
};
