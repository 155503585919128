import { LinkId } from "../../../../data/models/ContractTypes";

import { IdDocumentType } from "../../../../data/dataMerchant";
import { DocumentUploadPhone } from "./DocumentUploadPhone";

export const ID_UPLOAD_URL = "/:linkId/identification/upload/:idType";

export type DocumentUploadParams = {
  linkId: LinkId;
  idType: IdDocumentType;
};

export const DocumentUpload = () => {
  return <DocumentUploadPhone />;
};
