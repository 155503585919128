import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { PricingModel } from "../../data/models/PricingTypes";

interface Props {
  model: PricingModel;
}

const PricingModelTranslation: Record<PricingModel, (t: TFunction) => string> =
  {
    [PricingModel.BLENDED]: (t) => t("Blended"),
    [PricingModel.ICPP]: (t) => t("Interchange ++"),
    [PricingModel.PACK]: (t) => t("Pack"),
  };

export const PricingModelDisplay: React.FC<Props> = ({ model }) => {
  const { t } = useTranslation();

  return <>{PricingModelTranslation[model](t)}</>;
};
