export type ContractId = string & { isContractId: true };
export type SigneeId = number & { isSigneeId: true };
export type AssociateId = string & { isAssociateId: true };
export type BeneficialOwnerId = number & { isOwnerId: true };
export type TerminalId = number & { isTerminalId: true };
export type StoreId = number & { isStoreId: true };
export type LinkId = string & { isLinkId: true };
export type UTCDate = string & { isUTCDate: true };
export type DocumentId = number & { isDocumentId: true };
export type ContractVersion = number & { isContractVersion: true };
export type KlarnaToken = string & { isKlarnaToken: true };
export type KlarnaSessionId = string & { isKlarnaSessionId: true };

export const NO_COUNTRY = undefined;

export enum IdType {
  NATIONAL_ID_CARD = "NATIONAL_ID_CARD",
  PASSPORT = "PASSPORT",
}

// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export enum Country {
  POLAND = "PL",
  CROATIA = "HR",
  CZECHIA = "CZ",
}

export enum Language {
  POLAND = "pl",
  CROATIA = "hr",
  UK = "en",
  FRANCE = "fr",
  CZECHIA = "cs",
}

export enum Currency {
  POLAND = "PLN",
  CROATIA = "EUR",
  CZECHIA = "CZK",
}

export interface Address {
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  countryCode: Country | typeof NO_COUNTRY;
}

export enum VerifiedByAlternativeMethod {
  KLARNA = "KLARNA",
}

export enum Saluation {
  HE = "HE",
  SHE = "SHE",
}

export enum PackageId {
  SOFTPOS_BUNDLE = "SOFTPOS_BUNDLE",
  MOVE_BUNDLE = "MOVE_BUNDLE",
  DESK_BUNDLE = "DESK_BUNDLE",
}

export enum TerminalType {
  SOFT_POS = "SOFT_POS",
  DESK_3500 = "DESK_3500",
  MOVE_3500 = "MOVE_3500",

  LINK_2500 = "LINK_2500",
  SATURN = "SATURN",
  DESK_5000 = "DESK_5000",
  MOVE_5000 = "MOVE_5000",
  DX_8000 = "DX_8000",
}

export enum DocumentType {
  ID_DOCUMENT = "ID_DOCUMENT",
  BANK_STATEMENT = "BANK_STATEMENT",
  BUSINESS_CERTIFICATE = "BUSINESS_CERTIFICATE",
  TAXI_LICENSE = "TAXI_LICENSE",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  PROOF_OF_ACCREDITATION = "PROOF_OF_ACCREDITATION",
  FINANCIAL_STATEMENT = "FINANCIAL_STATEMENT",
  SALES_CUSTOM_REQUEST = "SALES_CUSTOM_REQUEST",
}

export enum ContractType {
  INSTORE = "INSTORE",
  ECOMMERCE = "ECOMMERCE",
}

export interface Contact {
  saluation: Saluation;
  phoneNumber?: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface IdCard {
  idType?: IdType;
  idDocumentNo?: string;
  dateOfBirth?: string; //yyyy-mm-dd
  placeOfBirth?: string;
  nationalityCountryCode?: string;
  idIssuedIn?: string; // ???
  idIssuedByAuthority?: string;
  idIssuedByCountryCode?: string;
  idIssuedOn?: string; //yyyy-mm-dd
  idExpiry?: string; //yyyy-mm-dd
}

export interface BeneficialOwnerCompany {
  name: string;
  ownershipStake: number;
  street: string;
  postalCode: string;
  city: string;
  countryCode: string;
}

export interface ContractDocument {
  documentId: DocumentId;
  confirmed?: UTCDate;
  fileAvailable: boolean;
  uploaded?: UTCDate;
  verifiedByAlternativeMethod?: VerifiedByAlternativeMethod;
  fileName?: string;
  mimeType?: string;
  documentType: DocumentType;
  documentDescription?: string; //Den här är satt om sales har skrivit in ett custom dokument, annrs bör språkfil används
}

export function defaultLanguageFromCountry(country?: Country) {
  switch (country) {
    case Country.CROATIA: {
      return Language.CROATIA;
    }

    case Country.POLAND: {
      return Language.POLAND;
    }

    default: {
      return Language.UK;
    }
  }
}
