import { Trans, useTranslation } from "react-i18next";
import { Link } from "../../../../components/link/Link";
import {
  ContractPricing,
  PricingModel as PricingModelEnum,
} from "../../../../data/models/PricingTypes";
import { PricingTable } from "./PricingTable";
import styles from "./PricingModelDescription.module.scss";
import { PricingModelDisplay } from "../../../../components/pricingModelDisplay/PricingModelDisplay";
import { getPricingModelDisplayName } from "../../../../components/utils";
import { useFirstInvoiceMonth } from "../../../../hooks/useFirstInvoiceMonth";

export const INTERCHANGE_URL = "http://www.worldline.com/merchant-services/interchange";
export const SCHEMA_URL = "http://www.worldline.com/merchant-services/schemefees";

interface Props {
  model: PricingModelEnum;
  name: string;
  contractPricing: ContractPricing;
}

export const PricingModelDescription = ({ model, name, contractPricing }: Props) => {
  const { t } = useTranslation();
  const firstInvoiceMonth = useFirstInvoiceMonth(contractPricing.promotionMonths);

  return (
    <>
      <div className={styles.general}>
        <dl className={styles.general_content}>
          <div className={styles.row}>
            <div className={styles.label}>{t("Price plan")}</div>
            <div className={styles.value}>{name}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>{t("Pricing model")}</div>
            <div className={styles.value}>{getPricingModelDisplayName(model)}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.label}>{t("First invoice")}</div>
            <div className={styles.value}>{firstInvoiceMonth}</div>
          </div>
        </dl>
      </div>
      <div className={styles.description}>
        <div className="section-subtitle">{getPricingModelDisplayName(model)}</div>
        <div>{getDescription(model)}</div>
      </div>
    </>
  );
};

const getDescription = (model: PricingModelEnum) => {
  switch (model) {
    case PricingModelEnum.PACK:
      return <PackDescription />;
    case PricingModelEnum.BLENDED:
      return <BlendedDescription />;
    case PricingModelEnum.ICPP:
      return <ICPPDescription />;
    default:
      return null;
  }
};

const PackDescription = () => {
  return (
    <p>
      <Trans>
        The <i>Pack pricing model</i> presents merchants with a convenient and predictable fixed
        monthly fee that covers the cost of the terminal(s) and a specified monthly transaction
        volume, without any additional fees. If the merchant surpasses the specified monthly
        transaction volume, they will be charged a transaction fee for each subsequent transaction,
        as outlined in the table below.
      </Trans>
    </p>
  );
};

const BlendedDescription = () => {
  return (
    <p style={{ margin: 0 }}>
      <Trans>
        The Blended pricing includes the total cost for the merchant. There will be no additional
        fees. Worldline will compensate the card providers.
      </Trans>
    </p>
  );
};

const ICPPDescription = () => {
  return (
    <div className={styles.icpp}>
      <p>
        <Trans>
          The pricing model{" "}
          <i>
            "<PricingModelDisplay model={PricingModelEnum.ICPP} />"
          </i>{" "}
          consists of 3 components which add up to form the individual commission rate for each
          purchase or refund transaction.
        </Trans>
      </p>
      <PricingTable />
      <p>
        <Trans>
          Worldline offers and invoices the Merchant for the agreed card brands and card types with
          different levels of interchange fees, individually itemized charges (interchange fees,
          card scheme fees and Worldline acquiring fees). Unless otherwise agreed, these shall also
          be charged in the case of a refund.
        </Trans>
      </p>
      <p>
        <Trans>
          The Merchant declares to have acknowledged the individually itemized information according
          to card brand and card type in relation to the different levels of interchange fees and
          card scheme fees available under{" "}
          <Link external link={INTERCHANGE_URL}>
            Interchange fees
          </Link>{" "}
          and{" "}
          <Link external link={SCHEMA_URL}>
            Card Scheme fees
          </Link>
        </Trans>
      </p>
    </div>
  );
};
