import React from "react";
import cx from "classnames";
import { ReactNode } from "react";
import "./FieldSet.scss";

interface Props {
  header: string | ReactNode;
  children: ReactNode;
  className?: string;
}

export const FieldSet: React.FunctionComponent<Props> = ({
  header,
  children,
  className,
}) => {
  return (
    <div className={cx("fieldset", className)}>
      <h5 className="fieldset-header">{header}</h5>
      <div className="fieldset-inner">{children}</div>
    </div>
  );
};
