import React from "react";
import {
  ContractPackage,
  PricingStep,
  Store,
} from "../../../data/dataMerchant";
import { Trans, useTranslation } from "react-i18next";
import {
  Country,
  Language,
  PackageId,
  TerminalType,
} from "../../../data/models/ContractTypes";
import { getIntlCost } from "../../../components/utils";
import { useFirstInvoiceMonth } from "../../../hooks/useFirstInvoiceMonth";
import { TerminalPreview } from "./components/TerminalPreview";

interface Props {
  contractPackage: ContractPackage;
  stores: Store[];
}

const SOFT_POS_CONTRACT_DURATION = 12;
const STANDARD_PACKAGE_CONTRACT_DURATION = 24;
const SOFT_POS_MONTHLY = 3;
const DESK_MONTHLY = 8.9;
const MOVE_MONTHLY = 10.9;
const CURRENCY = "EUR";

const pricingMapping = {
  [PricingStep.LIGHT]: 750,
  [PricingStep.STANDARD]: 1500,
  [PricingStep.ADVANCED]: 2250,
};

// const costSoftPosMapping = {
//   [PricingStep.LIGHT]: 90,
//   [PricingStep.STANDARD]: 135,
//   [PricingStep.ADVANCED]: 165,
// };

// const costStandardMapping = {
//   [PricingStep.LIGHT]: 130,
//   [PricingStep.STANDARD]: 190,
//   [PricingStep.ADVANCED]: 225,
// };

export const costMappings = {
  [PackageId.SOFTPOS_BUNDLE]: {
    [PricingStep.LIGHT]: 12.9,
    [PricingStep.STANDARD]: 19.9,
    [PricingStep.ADVANCED]: 24.9,
  },
  [PackageId.DESK_BUNDLE]: {
    [PricingStep.LIGHT]: 19.9,
    [PricingStep.STANDARD]: 27.9,
    [PricingStep.ADVANCED]: 30.9,
  },
  [PackageId.MOVE_BUNDLE]: {
    [PricingStep.LIGHT]: 21.9,
    [PricingStep.STANDARD]: 29.9,
    [PricingStep.ADVANCED]: 32.9,
  },
};

// const FIRST_MOVE_TERMINAL_COST = 10;

function getNumberOfTerminalsOfType(stores: Store[], type: TerminalType) {
  return stores.reduce(
    (prev, currentStore) =>
      prev +
      currentStore.terminals.filter(
        (terminal) => terminal.terminalType === type
      ).length,
    0
  );
}

function getInfoForStep(step: PricingStep, lang: string, t: any) {
  if (step === PricingStep.LIGHT) {
    return (
      <>
        {t("Light - up to {{value}}", {
          value: getIntlCost(lang, pricingMapping[step], CURRENCY, 2),
        })}
      </>
    );
  }

  if (step === PricingStep.STANDARD) {
    return (
      <>
        {t("Standard - up to {{value}}", {
          value: getIntlCost(lang, pricingMapping[step], CURRENCY, 2),
        })}
      </>
    );
  }

  return (
    <>
      {t("Advanced - up to {{value}}", {
        value: getIntlCost(lang, pricingMapping[step], CURRENCY, 2),
      })}
    </>
  );
}

function getCommissionRate(
  step: PricingStep,
  packageId: PackageId,
  lang: string
) {
  if (!costMappings[packageId]) {
    return "-";
  }

  return getIntlCost(lang, costMappings[packageId][step], CURRENCY, 2);
}

function getAccountFee(
  numberOfTerminals: number,
  terminalType: TerminalType,
  lang: string,
  t: any
) {
  const additionalTerminals = Math.max(0, numberOfTerminals - 1);

  if (terminalType === TerminalType.SOFT_POS) {
    return (
      <>
        {t("{{cost}} per month", {
          cost: getIntlCost(
            lang,
            additionalTerminals * SOFT_POS_MONTHLY,
            CURRENCY,
            2
          ),
        })}{" "}
        <i>
          {t(
            "- based on {{numberOfTerminals}} account/accounts (one included)",
            {
              numberOfTerminals,
            }
          )}
        </i>
      </>
    );
  }

  const terminalCost =
    terminalType === TerminalType.DESK_3500 ? DESK_MONTHLY : MOVE_MONTHLY;

  return (
    <>
      {t("{{cost}} per month", {
        cost: getIntlCost(
          lang,
          additionalTerminals * terminalCost,
          CURRENCY,
          2
        ),
      })}
      <i>
        {" "}
        {t(
          "- based on {{numberOfTerminals}} terminal/terminals (one included)",
          {
            numberOfTerminals,
          }
        )}
      </i>
    </>
  );
}

export const PricingHR: React.FunctionComponent<Props> = ({
  contractPackage,
  stores,
}) => {
  const { t } = useTranslation();
  const step = contractPackage.pricingStep;
  let noOfTerminals = 0;
  let type = TerminalType.SOFT_POS;
  let monthlyKey = "Monthly acquiring service by Account";
  let accountOrTerminalKey = "Number of accounts";
  let contractDuration = SOFT_POS_CONTRACT_DURATION;
  const firstInvoiceMonth = useFirstInvoiceMonth(
    contractPackage.promotionMonths
  );

  if (contractPackage.packageId === PackageId.SOFTPOS_BUNDLE) {
    noOfTerminals = getNumberOfTerminalsOfType(stores, TerminalType.SOFT_POS);
    type = TerminalType.SOFT_POS;
    monthlyKey = "Monthly acquiring service by Account";
    accountOrTerminalKey = "Number of accounts";
    contractDuration = SOFT_POS_CONTRACT_DURATION;
  }

  if (contractPackage.packageId === PackageId.DESK_BUNDLE) {
    noOfTerminals = getNumberOfTerminalsOfType(stores, TerminalType.DESK_3500);
    type = TerminalType.DESK_3500;
    monthlyKey = "Monthly acquiring service by Terminal";
    accountOrTerminalKey = "Number of terminals";
    contractDuration = STANDARD_PACKAGE_CONTRACT_DURATION;
  }

  if (contractPackage.packageId === PackageId.MOVE_BUNDLE) {
    noOfTerminals = getNumberOfTerminalsOfType(stores, TerminalType.MOVE_3500);
    type = TerminalType.MOVE_3500;
    monthlyKey = "Monthly acquiring service by Terminal";
    accountOrTerminalKey = "Number of terminals";
    contractDuration = STANDARD_PACKAGE_CONTRACT_DURATION;
  }

  return (
    <div className="pricing">
      <div className="tablet-columns">
        <div>
          <dl>
            <dt>{t("Package")}</dt>
            <dd>
              <TerminalPreview
                type={type}
                count={noOfTerminals}
                country={Country.CROATIA}
              />
            </dd>
            <dt>{t("Monthly transaction volume included")}</dt>
            <dd>{getInfoForStep(step, Language.CROATIA, t)}</dd>
            <dt>{t("Fixed monthly commission rate")}</dt>
            <dd>
              {getCommissionRate(
                step,
                contractPackage.packageId,
                Language.CROATIA
              )}
            </dd>
            {noOfTerminals < 2 ? null : (
              <>
                <dt>{t(monthlyKey)}</dt>
                <dd>
                  {getAccountFee(noOfTerminals, type, Language.CROATIA, t)}
                </dd>
              </>
            )}
          </dl>
        </div>
        <div>
          <dl>
            <dt>{t("Minimum contract duration")}</dt>
            <dd>{t("{{contractDuration}} months", { contractDuration })}</dd>
            <dt>{t(accountOrTerminalKey)}</dt>
            <dd>{noOfTerminals}</dd>
            {contractPackage.promotionMonths > 0 ? (
              <>
                <dt>{t("First invoice")}</dt>
                <dd>{firstInvoiceMonth}</dd>
              </>
            ) : null}
          </dl>
        </div>
      </div>
      <p className="disclaimer text-small">
        <Trans>
          Surcharge on exceeding transaction volume included - applies to all
          transaction exceeding the monthly transaction volume included for all
          means of payment, 1,29% (of transaction amount). Surcharge commercial
          cards - applies to all transactions with commercial cards (e.g.
          Business cards) for all means of payment - 1,20% (of transaction
          amount).
        </Trans>
      </p>
    </div>
  );
};
