import React, { useCallback, useRef, useState } from "react";
import { ContractDocument, UTCDate } from "../../../data/models/ContractTypes";
import { useTranslation } from "react-i18next";
import { contractState } from "../../../state/contractState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { documentState } from "../../../state/documentState";
import { Beacon } from "../../../components/beacon/Beacon";
import { Validity } from "../../../components/icons/AssociateIcon";
import { DocUpload } from "../../ContractUploads/DocUpload";
import { useWidth } from "../../../hooks/useWidth";
import styles from "./DocumentUpload.module.scss";
import { documentTypeDescription, documentTypeDisplay } from "../../../data/models/DocumentTypes";
import { desktopWidth } from "../../../variables";
import cx from "classnames";

interface Props {
  document: ContractDocument;
}

export const DocumentUpload: React.FunctionComponent<Props> = ({ document }) => {
  const { t } = useTranslation();
  const contract = useRecoilValue(contractState);
  const setDocuments = useSetRecoilState(documentState);
  const [isUploading, setIsUploading] = useState(false);
  const width = useWidth();
  const isTouch = useRef<boolean>(
    window.matchMedia && window.matchMedia("(pointer:coarse)").matches && width < desktopWidth
  );

  const accept = isTouch.current
    ? "application/pdf;image/*;capture=camera"
    : "image/*,application/pdf";

  const update = useCallback(
    (fileAvailable: boolean, uploaded: UTCDate | undefined) => {
      setDocuments((docs) => {
        return docs.map((doc) => {
          if (doc.documentId === document.documentId) {
            return {
              ...document,
              fileAvailable,
              uploaded,
            };
          }
          return doc;
        });
      });
    },
    [setDocuments, document]
  );

  const onUpload = useCallback(() => {
    update(true, new Date().toUTCString() as UTCDate);
  }, [update]);

  const onError = useCallback(() => {
    update(false, undefined);
  }, [update]);

  const rootClass = cx([styles.root], { [styles.uploaded]: document.uploaded });
  const titleClass = cx([styles.title], { [styles.title_uploaded]: document.uploaded });

  return (
    <div className={rootClass}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h5 className={titleClass}>{t(documentTypeDisplay(document.documentType))}</h5>
          <Beacon
            className="small"
            isPending={isUploading}
            validity={document.uploaded ? Validity.VALID : Validity.MISSING}
          />
        </div>
        <div className={styles.description}>
          {document.documentDescription
            ? document.documentDescription
            : t(documentTypeDescription(document.documentType))}
        </div>
      </div>
      <DocUpload
        accept={accept}
        doc={document}
        linkId={contract.linkId}
        setUploadedDoc={onUpload}
        onError={onError}
        setIsUploading={setIsUploading}
      />

      <div className="document-upload-image"></div>
    </div>
  );
};
