import React from "react";
import { Store as StoreInterface } from "../../../../data/dataMerchant";
import { Address } from "../../../../components/address/Address";
import { Contact } from "../../../../components/contact/Contact";
import { useTranslation } from "react-i18next";
import { StoreTerminals } from "./StoreTerminals/StoreTerminals";
import "./Store.scss";

interface Props {
  store: StoreInterface;
  mcc: string;
  displayTerminals?: boolean;
}

export const Store: React.FunctionComponent<Props> = ({ store, mcc, displayTerminals = false }) => {
  const { t } = useTranslation();

  // const showReservation = mcc === MCC_HOTEL;

  return (
    <div className="store">
      <div className="tablet-columns">
        <div className="split split-larger">
          <dl>
            <dt>{t("Commercial name")}</dt>

            <dd>{store.commercialName}</dd>
          </dl>
        </div>
      </div>
      <div className="tablet-columns split">
        <Address header={t("Address")} address={store.address} />
        <Contact header={t("Contact at location")} contact={store.contact} />
      </div>
      {displayTerminals && <StoreTerminals terminals={store.terminals} />}
    </div>
  );
};
