import { FC, useEffect, useState } from "react";
import { Sheet } from "../../../../components/modal/Sheet";
import { useTranslation } from "react-i18next";
import { Contract, dataMerchant } from "../../../../data/dataMerchant";
import { useLinkId } from "../../../../hooks/useLinkId";
import { DocumentUpload } from "../../../ContractVerification/documents/DocumentUpload";
import styles from "./UploadDocumentSheet.module.scss";
import { WarningOutline } from "../../../../components/icons/WarningOutline";
import cx from "classnames";
import { Checkmark } from "../../../../components/icons/Checkmark";
import { ContractDocument } from "../../../../data/models/ContractTypes";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  documents: ContractDocument[];
}

export const UploadDocumentSheet: FC<Props> = ({ onClose, isOpen, documents }) => {
  const { t } = useTranslation();
  const linkId = useLinkId();
  const completedDocs = documents.filter((doc) => doc.fileAvailable);
  const [contract, setContract] = useState<Contract>();

  useEffect(() => {
    if (!linkId) return;
    dataMerchant.getContract(linkId).then(setContract);
  }, [linkId]);

  if (!contract) {
    return null;
  }

  const allUploaded = completedDocs.length === documents.length;

  const uploadStatusClass = cx([styles.uploaded], {
    [styles.allUploaded]: allUploaded,
  });

  return (
    <Sheet
      showModal={isOpen}
      closeOnOverlayClick={true}
      onClose={onClose}
      maxWidth={1200}
      contentClassName={styles.sheetContent}
      fullHeight
      noPadding
      disableScrollLock
    >
      <div className={styles.root}>
        <h3 className={styles.title}>{t("Upload documents")}</h3>
        <div className={styles.content}>
          {/* <div>{t("Upload statements and/or documentation")}</div> */}

          <ul className={styles.documents}>
            <div className={uploadStatusClass}>
              {allUploaded ? <Checkmark /> : <WarningOutline />}
              {`${completedDocs.length} / ${documents.length} ${t("uploaded")}`}
            </div>
            {documents.map((document) => (
              <li className={styles.doc} key={document.documentId}>
                <DocumentUpload document={document} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Sheet>
  );
};
