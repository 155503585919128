import { Trans, useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { CardPageLayout } from "../../../../components/cardPageLayout/CardPageLayout";
import { Card } from "../../../../components/cards/Card";
import { Image } from "../../../../components/images/Image";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Or } from "../../../../components/or/Or";
import { IdDocumentType } from "../../../../data/dataMerchant";
import { Country, LinkId } from "../../../../data/models/ContractTypes";
import { contractState } from "../../../../state/contractState";
import { ID_UPLOAD_URL } from "../DocumentUpload/DocumentUpload";
import "./IdentificationSelection.scss";

export const ID_SELECTION_URL = "/:linkId/identification/id-selection";

const passports = {
  [Country.CROATIA]: {
    url: "/images/passport_front_HR_250x351.jpeg",
    aspect: 250 / 351,
  },
  [Country.POLAND]: {
    url: "/images/passport_front_PL_296x425.jpeg",
    aspect: 296 / 425,
  },
  [Country.CZECHIA]: {
    url: "/images/passport_front_CZ_978x1424.jpg",
    aspect: 296 / 425,
  },
};

const ids = {
  [Country.CROATIA]: {
    front: {
      url: "/images/id_croatia_front_640x401.jpg",
      aspect: 640 / 401,
    },
    back: {
      url: "/images/id_croatia_back_640x402.jpg",
      aspect: 640 / 402,
    },
  },
  [Country.POLAND]: {
    front: { url: "/images/id_poland_front_600x376.jpg", aspect: 600 / 376 },
    back: { url: "/images/id_poland_back_600x376.jpg", aspect: 600 / 376 },
  },
  [Country.CZECHIA]: {
    front: { url: "/images/id_czechia_front_682x428.jpg", aspect: 682 / 428 },
    back: { url: "/images/id_czechia_back_675x425.jpg", aspect: 675 / 425 },
  },
};

export const IdentificationSelection = () => {
  const { linkId } = useParams<{ linkId: LinkId }>();
  const { t } = useTranslation();
  const { contract } = useRecoilValue(contractState);
  const navigate = useNavigate();
  const passport = passports[contract.contractData.country];
  const id = ids[contract.contractData.country];

  return (
    <CardPageLayout>
      <div className="identification-selection">
        <Card
          header={t("Select identification document")}
          className="device-card"
        >
          <div className="device-card">
            <Or className="white-bg">
              <div className="grid">
                <div className="grid-text">
                  {t(
                    "Continue with a passport. We will need a picture of the hard plastic page that shows your face"
                  )}
                </div>

                <div className="id-images">
                  <Image
                    alt="passport"
                    src={passport.url}
                    ratio={passport.aspect}
                  />
                </div>

                <Button
                  block
                  onClick={() => {
                    navigate(
                      generatePath(ID_UPLOAD_URL, {
                        linkId: linkId ? linkId : null,
                        idType: IdDocumentType.PASSPORT,
                      })
                    );
                  }}
                  ghost
                >
                  {t("Continue with passport")}
                </Button>
              </div>
              <div>
                <div className="grid">
                  <div className="grid-text">
                    <Trans>
                      Continue with a National identity card. <b>Both sides</b>{" "}
                      of the ID card will be required. Observe that your
                      driver's license is not sufficent.
                    </Trans>
                  </div>
                  <div className="id-images">
                    <Image
                      alt="id front"
                      className="id-front"
                      src={id.front.url}
                      ratio={id.front.aspect}
                    />

                    <Image
                      alt="id back"
                      className="id-back"
                      src={id.back.url}
                      ratio={id.back.aspect}
                    />
                  </div>
                  <Button
                    block
                    onClick={() =>
                      navigate(
                        generatePath(ID_UPLOAD_URL, {
                          linkId: linkId ? linkId : null,
                          idType: IdDocumentType.ID_CARD,
                        })
                      )
                    }
                    ghost
                  >
                    {t("Continue with ID card")}
                  </Button>
                </div>
              </div>
            </Or>
          </div>
        </Card>
      </div>
    </CardPageLayout>
  );
};
