import { usePdfViewer } from "./usePdfViewer";
import cx from "classnames";
import { Link } from "../link/Link";
import { SpinnerMotion } from "../spinner/SpinnerMotion";
import { AnimatePresence, motion } from "framer-motion";
import "./PdfViewer.scss";

interface Props {
  url: string;
  className?: string;
}

export const PdfViewer = ({ url, className }: Props) => {
  const { isLoading, isSlow } = usePdfViewer();

  return (
    <div className={cx("pdf-viewer", className)}>
      <AnimatePresence>
        {isLoading && (
          <motion.div
            className="loading-wrapper"
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            layout
          >
            <motion.div layout="position">
              <SpinnerMotion text={!isSlow ? "loading document" : undefined} />
            </motion.div>

            {isSlow && (
              <motion.div
                className="slow-warning"
                layout
                initial={{
                  opacity: 0,
                  y: 25,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.5,
                  },
                }}
              >
                <p>
                  Seems like it's taking a while to load the document... try
                  downloading it instead?
                </p>

                <Link
                  className="as-button m-top-10"
                  external
                  download={"WORLDLINE_MERCHANT_CONTRACT.pdf"}
                  link={url}
                >
                  Download
                </Link>
              </motion.div>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      <iframe
        title="PDF"
        src={`/libs/pdf/web/viewer.html?file=${url}`}
        width="100%"
        height="100%"
        frameBorder={0}
        className={className}
      />
    </div>
  );
};
