import React, { useCallback, useRef } from "react";
import cx from "classnames";
import { Status } from "../../../data/types";
import { Alternative } from "../Dropdown/Dropdown";
import { nanoid } from "nanoid";
import { Checkbox } from "../../icons/Checkbox";
import { Checkmark } from "../../icons/Checkmark";
import { Disabled } from "../../icons/Disabled";
import { Pending } from "../../icons/Pending";
import { Error } from "../../icons/Error";
import "../Radio/RadioButtons.scss";

interface Props {
  status?: Status;
  className?: string;
  values?: string[];
  hint?: string | React.ReactNode;
  label?: string | React.ReactNode;
  name?: string;
  message?: string | React.ReactNode;
  onChange: (values: string[], name: string, value: string) => void;
  onBlur?: (values: string[] | undefined, name: string, value: string) => void;
  alternatives: Alternative[];
}

export function Checkboxes({
  status = Status.DEFAULT,
  className,
  name,
  label = null,
  onChange,
  onBlur,
  message = null,
  hint,
  alternatives = [],
  values,
}: Props) {
  const identifier = useRef<string>(nanoid());

  const internalChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const targetValue = ev.target.value;
      const index = values?.indexOf(targetValue) ?? -1;
      const newValues = values ? [...values] : [];
      if (index > -1) {
        newValues.splice(index, 1);
      } else {
        newValues.push(targetValue);
      }
      return onChange(newValues, name || identifier.current, targetValue);
    },
    [onChange, name, values]
  );

  const indexLength = alternatives.length - 1;

  return (
    <div
      className={cx(
        "input",
        "checkbox-buttons",
        `length-${alternatives.length}`,
        className,
        status
      )}
    >
      {label && <div className="input-label">{label}</div>}
      {alternatives.map((option, idx) => {
        const current = option?.value as any;

        const checked = !!values?.find((value) => value === current);

        const labelId = `${name || identifier.current}-${idx}`;

        return (
          <label
            htmlFor={labelId}
            key={current}
            className={cx("checkbox-button", {
              "is-first": idx === 0,
              "is-last": indexLength === idx,
            })}
          >
            <Checkbox checked={checked} />
            <div className="checkbox-label">{option.text}</div>
            <input
              id={labelId}
              name={labelId}
              type="checkbox"
              onChange={internalChange}
              value={current}
              checked={checked}
              disabled={status === Status.DISABLED || status === Status.PENDING}
            />

            <div className="input-status">
              <Checkmark />
              <Disabled />
              <Pending />
              <Error />
            </div>
          </label>
        );
      })}

      <div className="input-messages">
        <div className="input-hint">{hint}</div>
        <div className="input-message">{message}</div>
      </div>
    </div>
  );
}
