import React, { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { documentState } from "../../state/documentState";
import {
  ContractId,
  ContractVersion,
  Country,
  DocumentId,
  DocumentType,
  Language,
  LinkId,
  PackageId,
  Saluation,
  TerminalId,
  TerminalType,
} from "../../data/models/ContractTypes";
import { ACTION_TYPE, contractState } from "../../state/contractState";
import { PricingStep } from "../../data/dataMerchant";
import { ContractFeature } from "../../data/dataMerchant";
import i18n from "../../i18n";
import { AccountSelection } from "../SignableContract/AddBankAccount/AccountSelection/AccountSelection";
import "./KlarnaTestPage.scss";

interface Props {
  ready: boolean;
}

const Inner: React.FunctionComponent<Props> = ({ ready }) => {
  if (!ready) {
    return null;
  }

  return <AccountSelection withoutContractLoader />;
};

export const KlarnaTestPage: React.FunctionComponent = () => {
  const setDocuments = useSetRecoilState(documentState);
  const setContractState = useSetRecoilState(contractState);
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    setDocuments([
      {
        documentId: 1 as DocumentId,
        fileAvailable: false,
        documentType: DocumentType.BANK_STATEMENT,
      },
      {
        documentId: 2 as DocumentId,
        fileAvailable: false,
        documentType: DocumentType.BUSINESS_CERTIFICATE,
      },
    ]);

    let lang = Language.UK as string;
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      ({ lang } = Object.fromEntries(urlSearchParams.entries()));
    } catch (err) {}

    i18n.changeLanguage(lang);

    setContractState({
      contract: {
        contractData: {
          companyRegistrationConfirmed: true,
          vatConfirmed: true,
          mccIndustry: "Consulting, management and public relations services",
          version: 7 as ContractVersion,
          country: "SE" as Country,
          companyRegistrationId: "1231119747",
          companyName: "CONSULTING JERZY CIECIERSKI",
          mcc: "7392",
          vatNumber: "1231119747",
          // beneficialOwnerType: "SOLE_PROPRIETOR",
          primaryAddress: {
            street: "ul. Piwna",
            streetNumber: "12/14",
            postalCode: "00-263",
            city: "Warszawa",
            countryCode: Country.POLAND,
          },
          cardholderStatementText: "CONSULTING CIECIERSKI",
          contractId: "PL-e8dabef95f6e4c0a8e1d-cbe1e0c70997" as ContractId,
        },
        contractPackage: {
          packageId: PackageId.SOFTPOS_BUNDLE,
          promotionMonths: 5,
          pricingStep: PricingStep.LIGHT,
          cashlessPromotion: true,
        },
        contractPricing: [],
        stores: [
          {
            terminals: [
              {
                id: 108 as TerminalId,
                terminalType: TerminalType.SOFT_POS,
                tip: false,
                bookAfterReservation: false,
                terminalClosure: "22:30:00",
              },
            ],
            address: {
              street: "ul. Piwna 12/14",
              streetNumber: "",
              postalCode: "00-263",
              city: "Warszawa",
              countryCode: Country.POLAND,
            },
            commercialName: "CONSULTING CIECIERSKI",
            contact: {
              saluation: Saluation.HE,
              phoneNumber: "+48608004400",
              email: "michal.nowak@six-group.com",
              firstName: "Michał",
              lastName: "Nowak",
            },
          },
        ],
        bankAccount: {
          iban: "",
          accountHolder: "",
          bank: "",
          bic: "",
        },
      },
      linkId: "EZABRBCITB" as LinkId,
      hasShownContract: false,
      hasShownIntro: false,
      type: ACTION_TYPE.VERIFICATION_ONLY,
      features: [ContractFeature.KLARNA],
    });
    setReady(true);
  }, [setDocuments, setContractState]);

  return (
    <section className="klarna-test-page">
      <article>
        <Inner ready={ready} />
      </article>
    </section>
  );
};
