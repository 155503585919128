import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useParams, useNavigate, generatePath } from "react-router-dom";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { ID_SELECTION_URL } from "../IdentificationSelection/IdentificationSelection";
import { SelfieUploadParams } from "./SelfieUpload";
import { SelfieUploadModal } from "./SelfieUploadModal";
import { CameraError } from "../components/CameraError";
import { Trans, useTranslation } from "react-i18next";
import { Status } from "../../../../data/types";
import { cache } from "../../../../network/Cache";
import { Pending } from "../../../../components/icons/Pending";
import { ErrorBox } from "../../../../components/boxes/ErrorBox";
import "./SelfieUploadPhone.scss";
import "../SelfieAndID.scss";
import { API, APP_ID } from "../../../../network/API";
import { AnimateHeightMotion } from "../../../../components/animate/AnimateHeightMotion";

export const SelfieUploadPhone = () => {
  const navigate = useNavigate();
  const webcamRef = useRef<Webcam>(null);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [image, setImage] = useState<string>();
  const [showModal, setShowModal] = useState(true);
  const [error, setError] = useState<string | DOMException>();
  const { linkId } = useParams<SelfieUploadParams>();
  const { t } = useTranslation();
  const [progress, setProgress] = useState<number>(0);
  const isPosting = useRef<boolean>(false);

  const captureImage = useCallback(() => {
    const screenshot = webcamRef.current?.getScreenshot();
    if (!screenshot) {
      return;
    }
    setImage(screenshot);
  }, []);

  const postSelfie = useCallback(() => {
    if (isPosting.current) {
      return;
    }

    if (!image) {
      return;
    }

    setStatus(Status.PENDING);
    isPosting.current = true;

    setError("");
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        setProgress((event.loaded / event.total) * 100);
      }
    });

    xhr.onload = function () {
      if (xhr.status === 200) {
        cache.delete(`/api/merchant/${linkId}`);
        navigate(
          generatePath(ID_SELECTION_URL, { linkId: linkId ? linkId : null })
        );
      } else {
        setStatus(Status.ERROR);
        isPosting.current = false;
        setProgress(0);
        setError(t("Failed to upload selfie, try again later"));
      }
    };

    xhr.open("POST", API.getUrl(`/api/merchant/identity/${linkId}/selfie`));
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    xhr.setRequestHeader("x-app-id", APP_ID);

    xhr.withCredentials = true;
    xhr.send(JSON.stringify({ image }));
  }, [image, linkId, navigate, t]);

  if (image) {
    return (
      <div className="selfie-confirm-phone">
        <img src={image} alt="selfie-preview" className="preview" />
        <div className="button-wrapper">
          <div
            className="camera-upload-progress"
            style={{ width: `${progress}%` }}
          />
          <AnimateHeightMotion presence className="selfie-upload-phone-buttons">
            <div>
              {status === Status.DEFAULT && (
                <div className="columns">
                  <div>
                    <Button
                      ghost
                      block
                      onClick={() => setImage(undefined)}
                      status={status}
                    >
                      <Trans>No, retake</Trans>
                    </Button>
                  </div>
                  <div>
                    <Button block status={status} onClick={postSelfie}>
                      <Trans>Yes, proceed</Trans>
                    </Button>
                  </div>
                </div>
              )}

              {status === Status.PENDING && (
                <div className="center">
                  <Pending />
                </div>
              )}

              {status === Status.ERROR && (
                <>
                  <ErrorBox relative>
                    <Trans>Something went wrong!</Trans>
                  </ErrorBox>
                  <div className="columns">
                    <div>
                      <Button
                        block
                        ghost
                        onClick={() => window.location.reload()}
                      >
                        <Trans>Reload page</Trans>
                      </Button>
                    </div>
                    <div>
                      <Button block onClick={postSelfie}>
                        <Trans>Try again?</Trans>
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </AnimateHeightMotion>
        </div>
      </div>
    );
  }

  return (
    <div className="selfie-upload-phone">
      <SelfieUploadModal
        isOpen={!error && showModal}
        onClose={() => setShowModal(false)}
      />
      <CameraError error={error} retry={() => setError(undefined)} />
      <React.Fragment>
        <div className="video-wrapper">
          {!error && (
            <Webcam
              ref={webcamRef}
              onUserMediaError={(error) => setError(error)}
              videoConstraints={{
                // Only request width, due to an iOS issue
                width: 1280,
                facingMode: "user",
              }}
              screenshotQuality={1}
              minScreenshotWidth={1280}
              screenshotFormat={"image/jpeg"}
              mirrored
            />
          )}
          <div className="video-face-overlay phone"></div>
        </div>
        <div className="button-wrapper">
          <Button block onClick={captureImage}>
            <Trans>Capture image</Trans>
          </Button>
        </div>
      </React.Fragment>
    </div>
  );
};
