import { useTranslation } from "react-i18next";
import { Logo } from "../components/images/Logo";

export function InvalidLink() {
  const { t } = useTranslation();
  return (
    <div className="confirm-page missing-id">
      <section>
        <article>
          <div className="logo-wrapper">
            <Logo />
          </div>
          {t("Please check the link in your text or email.")}
        </article>
      </section>
    </div>
  );
}
