import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../../components/cards/Card";
import { Associate } from "../../../data/dataMerchant";
import { Button } from "../../../components/interactions/Buttons/Button";
import { ContractDocument } from "../../../data/models/ContractTypes";

import { documentTypeDisplay } from "../../../data/models/DocumentTypes";
import { useRecoilValue } from "recoil";
import { documentState } from "../../../state/documentState";
import { isPrimary } from "../../../state/associateState";
import { UploadDocumentSheet } from "./UploadDocumentSheet/UploadDocumentSheet";
import styles from "./DocumentCard.module.scss";
import { FileTextIcon } from "../../../components/icons/FileTextIcon";
import cx from "classnames";
import { Checkmark } from "../../../components/icons/Checkmark";
import { FcDocument } from "react-icons/fc";
import { ids } from "../Contract";
import { WarningOutline } from "../../../components/icons/WarningOutline";

interface Props {
  associate?: Associate;
  documents?: ContractDocument[];
}

export const DocumentsCard: FC<Props> = ({ associate }) => {
  const [sheetIsOpen, setSheetIsOpen] = useState(false);
  const documents = useRecoilValue(documentState);
  const { t } = useTranslation();

  const completedDocs = documents?.every((doc) => doc.fileAvailable) ?? false;

  // Dont show documents if there are none or if the associate isnt primary contact
  if (documents.length === 0 || !isPrimary(associate)) {
    return null;
  }

  return (
    <div id={ids.DOCUMENTS}>
      <Card header={t("Documents")} maxContent completed={completedDocs} headerIcon>
        <div className={styles.icon_section}>
          <FcDocument size={62} />
        </div>
        <div className={styles.intro_text}>
          {completedDocs ? (
            <p className={"center fw-500 color-success"} style={{ margin: 0 }}>
              {t("You have provided all required documents")}
            </p>
          ) : (
            <p style={{ margin: 0 }} className="center fw-500">
              {t("We need you to provide some documents.")}
            </p>
          )}
        </div>
        <ul className={styles.document_list}>
          {documents?.map((document) => (
            <li
              className={cx(styles.list_item, {
                [styles["uploaded"]]: document.fileAvailable,
              })}
              key={document.documentId}
            >
              <div className={styles.header}>
                <FileTextIcon />
                <div className="fw-600">{t(documentTypeDisplay(document.documentType))}</div>
                {document.fileAvailable ? (
                  <Checkmark className={styles.icon} />
                ) : (
                  <WarningOutline className={styles.icon} />
                )}
              </div>
              {/* <div className={styles.description}>
                {document.documentDescription
                  ? document.documentDescription
                  : t(documentTypeDescription(document.documentType))}
              </div> */}
            </li>
          ))}
        </ul>

        <Button block onClick={() => setSheetIsOpen(true)}>
          {!completedDocs ? t("Upload documents") : t("View uploaded documents")}
        </Button>
      </Card>
      <UploadDocumentSheet
        documents={documents}
        isOpen={sheetIsOpen}
        onClose={() => setSheetIsOpen(false)}
      />
    </div>
  );
};
