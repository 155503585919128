import { atom } from "recoil";
import { SalesContact } from "../data/dataMerchant";

export const salesContactState = atom<SalesContact>({
  key: "sales-contact-state",
  default: {
    name: "",
    email: "",
  },
});
