import React from "react";
import { Terminal } from "../../../../../data/dataMerchant";
import { Country, TerminalType } from "../../../../../data/models/ContractTypes";

import { TerminalPreview } from "../../components/TerminalPreview";
import { useTranslation } from "react-i18next";
import styles from "./StoreTerminals.module.scss";

interface Props {
  terminals: Terminal[];
}

const TERMINAL_TYPES = Object.values(TerminalType);

export const StoreTerminals: React.FunctionComponent<Props> = ({ terminals }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <b className="section-subtitle">{t("Terminals")}</b>
      <div className={styles.terminals}>
        {TERMINAL_TYPES.map((type) => {
          const count = terminals.filter((terminal) => terminal.terminalType === type).length;
          if (count === 0) {
            return null;
          }
          return <TerminalPreview type={type} count={count} country={Country.POLAND} key={type} />;
        })}
      </div>
    </div>
  );
};
