import { useTranslation } from "react-i18next";
import { Card } from "../../../components/cards/Card";
import { MerchantContractInformation } from "../../../data/dataMerchant";
import { Store } from "./Store/Store";
import { ContractType } from "../../../data/models/ContractTypes";

interface Props {
  contract: MerchantContractInformation;
}

export const MultipleStores = ({ contract }: Props) => {
  const { t } = useTranslation();
  const { stores, contractPricing } = contract;
  const { mcc } = contract.contractData;

  const instoreContractPricing = contractPricing.find(
    (pricing) => pricing.contractType === ContractType.INSTORE
  );
  const displayTerminals = instoreContractPricing?.acceptance ? true : false;
  if (stores.length < 1) {
    return null;
  }

  return (
    <>
      {stores.map((store, idx) => (
        <div key={idx}>
          <Card header={t("Location") + ` ${idx + 1}`} withBackground={false}>
            <Store store={store} mcc={mcc} displayTerminals={displayTerminals} />
          </Card>
        </div>
      ))}
    </>
  );
};
