import { FC } from "react";
import styles from "./StepPricingTableDisplay.module.scss";
import { useTranslation } from "react-i18next";
import { SteppedBasedPricing } from "../../../../../data/models/PricingTypes";
import { getIntlNumberFormat } from "../../../../../components/utils";
import { Country, Language } from "../../../../../data/models/ContractTypes";
import { CURRENCY_BY_COUNTRY } from "../../../../../i18n";

interface Props {
  steppedBasedPricing: SteppedBasedPricing;
  country: Country;
}

const StepPricingTableDisplay: FC<Props> = ({ steppedBasedPricing, country }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as Language;
  const currency = CURRENCY_BY_COUNTRY[country];

  const { cashlessPromotionPricing, regularPricing } = steppedBasedPricing;

  return (
    <div className={styles.root}>
      {cashlessPromotionPricing && (
        <div>
          <div className={styles.header}>
            <div className={styles.cashless_header}>
              {t("First 12 months after contract signature")}
            </div>
            <div className={styles.labels}>
              <div>{t("Monthly transaction turnover")}</div>
              <div>{t("Transaction fee")}</div>
            </div>
          </div>
          {cashlessPromotionPricing.map((pricingStep, sectionIndex) => {
            const isLastItem = sectionIndex + 1 === cashlessPromotionPricing.length;

            return (
              <div className={styles.step} key={`section-display-${sectionIndex}`}>
                <div className={styles.threshold}>
                  {isLastItem
                    ? `> ${getIntlNumberFormat(language, pricingStep.fromThreshold)} ${currency}`
                    : `${getIntlNumberFormat(
                        language,
                        pricingStep.fromThreshold
                      )} - ${getIntlNumberFormat(language, pricingStep.toThreshold)} ${currency}`}
                </div>
                <div className={styles.fees}>{pricingStep.transactionFee} %</div>
              </div>
            );
          })}
        </div>
      )}

      <div className={styles.header}>
        <div className={styles.cashless_header}>
          {t("More than 12 months after contract signing")}
        </div>
        <div className={styles.labels}>
          <div>{t("Monthly transaction turnover")}</div>
          <div>{t("Transaction fee")}</div>
        </div>
      </div>
      {regularPricing.map((pricingStep, sectionIndex) => {
        const isLastItem = sectionIndex + 1 === regularPricing.length;

        return (
          <div className={styles.step} key={`section-display-${sectionIndex}`}>
            <div className={styles.threshold}>
              {isLastItem
                ? `> ${getIntlNumberFormat(language, pricingStep.fromThreshold)} ${currency}`
                : `${getIntlNumberFormat(
                    language,
                    pricingStep.fromThreshold
                  )} - ${getIntlNumberFormat(language, pricingStep.toThreshold)} ${currency}`}
            </div>
            <div className={styles.fees}>{pricingStep.transactionFee} %</div>
          </div>
        );
      })}
    </div>
  );
};

export default StepPricingTableDisplay;
