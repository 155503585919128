export enum DocumentType {
  ID_DOCUMENT = "ID_DOCUMENT",
  BANK_STATEMENT = "BANK_STATEMENT",
  BUSINESS_CERTIFICATE = "BUSINESS_CERTIFICATE",
  TAXI_LICENSE = "TAXI_LICENSE",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  PROOF_OF_ACCREDITATION = "PROOF_OF_ACCREDITATION",
  FINANCIAL_STATEMENT = "FINANCIAL_STATEMENT",
  SALES_CUSTOM_REQUEST = "SALES_CUSTOM_REQUEST",
}

export function documentTypeDisplay(type: DocumentType) {
  switch (type) {
    case DocumentType.ID_DOCUMENT:
      return "Passport / National ID card";
    case DocumentType.BANK_STATEMENT:
      return "Bank statement";
    case DocumentType.BUSINESS_CERTIFICATE:
      return "Business certificate";
    case DocumentType.TAXI_LICENSE:
      return "Taxi License";
    case DocumentType.POWER_OF_ATTORNEY:
      return "Power of attorney";
    case DocumentType.PROOF_OF_ACCREDITATION:
      return "Proof of accreditation";
    case DocumentType.FINANCIAL_STATEMENT:
      return "Finacial statment";
    case DocumentType.SALES_CUSTOM_REQUEST:
      return "Document requested";
  }
}

export function documentTypeDescription(type: DocumentType) {
  switch (type) {
    case DocumentType.ID_DOCUMENT:
      return "Copy of a valid personal document, a passport or national identification card";
    case DocumentType.BANK_STATEMENT:
      return "Confirmation of bank relation or recent account statement";
    case DocumentType.BUSINESS_CERTIFICATE:
      return "Excerpt from a national official business registry showing signatory power and company status";
    case DocumentType.TAXI_LICENSE:
      return "Proof of accreditation/taxi license";
    case DocumentType.POWER_OF_ATTORNEY:
      return "Copy of signed document showing the power to stand in for a representative of the company";
    case DocumentType.PROOF_OF_ACCREDITATION:
      return "Excerpt or copy of relevant accreditation documentation";
    case DocumentType.FINANCIAL_STATEMENT:
      return "The latest audited financial statement";
  }
  return "(Description missing for " + type.toString() + ")";
}
