import React, { ReactNode, useMemo } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import "./Or.scss";

interface Props {
  children: ReactNode;
  text?: string;
  className?: string;
}

export const Or: React.FunctionComponent<Props> = ({
  children,
  text,
  className = "",
}) => {
  const length = useMemo(() => React.Children.count(children), [children]) - 1;
  const { t } = useTranslation();

  return (
    <div className={cx("or", className)}>
      {React.Children.map(children, (child, idx) => {
        return (
          <>
            <div className="or-child">{child}</div>
            {idx === length ? null : (
              <div className="or-divider">
                <i>{text || t("or")}</i>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};
