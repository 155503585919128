import React, { VoidFunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Modal } from "../../../../components/modal/Modal";
import { IdDocumentType } from "../../../../data/dataMerchant";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  idDocumentType: IdDocumentType;
  takenPhotos: 0 | 1;
}

export const IdentificationUploadModal: VoidFunctionComponent<Props> = ({
  isOpen,
  onClose,
  idDocumentType,
  takenPhotos,
}) => {
  const { t } = useTranslation();
  return (
    <Modal showModal={isOpen} closeOnOverlayClick={true} onClose={() => onClose()}>
      {idDocumentType === IdDocumentType.PASSPORT && (
        <div className="id-upload-modal-content">
          <div>
            <h2 className="m-bottom-10">Passport</h2>
            <p>
              {t(
                "Take a picture of the plastic page in your passport. It's important that the image is clear and well lit so that the information is easily readable"
              )}
            </p>
          </div>
        </div>
      )}

      {idDocumentType === IdDocumentType.ID_CARD && takenPhotos === 0 && (
        <div className="id-upload-modal-content">
          <div>
            <h2 className="m-bottom-10">{t("Front of ID-card")}</h2>
            <p>
              {t(
                "Take a picture of the front of your ID-card. It's important that the image is clear and well lit so that the information is easily readable"
              )}
            </p>
          </div>
        </div>
      )}

      {idDocumentType === IdDocumentType.ID_CARD && takenPhotos === 1 && (
        <div className="id-upload-modal-content">
          <div className="icon"></div>
          <div>
            <h2 className="m-bottom-10">{t("Back of ID-card")}</h2>
            <p>
              {t(
                "Take a picture of the back of your ID-card. It's important that the image is clear and well lit so that the information is easily readable"
              )}
            </p>
          </div>
        </div>
      )}

      <Button onClick={() => onClose()} block className="m-top-20">
        {t("Continue")}
      </Button>
    </Modal>
  );
};
