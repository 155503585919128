import { FC } from "react";
import { ContractType } from "../../../data/models/ContractTypes";
import { MerchantContractInformation } from "../../../data/dataMerchant";
import { InstorePricing } from "./InstorePricing";

interface Props {
  contract: MerchantContractInformation;
}

const AdvancedPricing: FC<Props> = ({ contract }) => {
  const { stores, contractPricing, contractData } = contract;

  /*   const ecomPricing = contractPricing.find(
    (pricing) => pricing.contractType === ContractType.ECOMMERCE
  ); */

  const instorePricing = contractPricing.find(
    (pricing) => pricing.contractType === ContractType.INSTORE
  );

  return (
    <>
      {instorePricing && (
        <InstorePricing pricing={instorePricing} country={contractData.country} stores={stores} />
      )}
    </>
  );
};

export default AdvancedPricing;
