import { atom } from "recoil";

export enum ConfirmPages {
  VERIFICATION = 0,
  VIEW = 1,
  BANK_STATEMENT = 2,
  UPLOAD = 3,
  ASSOCIATE = 4,
  SIGNEE = 5,
  COMPANY_DOCUMENTS = 6,
  COMPLETE = 7,
}

export const pageState = atom<ConfirmPages>({
  key: "page-state",
  default: -1,
});

export const pageShowVersionWarning = atom<boolean>({
  key: "page-show-version-warning",
  default: false,
});
