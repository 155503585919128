import { FC, PropsWithChildren, ReactNode } from "react";
import cx from "classnames";
import "./Card.scss";
import { WarningOutline } from "../icons/WarningOutline";
import { Check } from "../icons/CheckIcon";

interface Props extends PropsWithChildren {
  className?: string;
  header: ReactNode;
  withBackground?: boolean;
  variant?: "v1" | "v2";
  completed?: boolean;
  noPadding?: boolean;
  maxContent?: boolean;
  headerIcon?: boolean;
}

export const Card: FC<Props> = ({ variant = "v2", ...props }) => {
  if (variant === "v2") {
    return <NewCard {...props} />;
  } else {
    return <OldCard {...props} />;
  }
};

const NewCard: FC<Props> = ({
  children,
  className,
  header,
  withBackground = false,
  completed = true,
  noPadding = false,
  maxContent = false,
  headerIcon = false,
}) => {
  return (
    <div className="new-card-wrapper">
      {header && (
        <h4
          className={cx("new-card-header", {
            "header-incomplete": !completed,
          })}
        >
          {header}
          {headerIcon ? completed ? <Check /> : <WarningOutline /> : null}
        </h4>
      )}
      <div
        className={cx("new-card", className, {
          "has-background": withBackground,
          "no-padding": noPadding,
        })}
      >
        <div className={maxContent ? "max-content" : ""}>{children}</div>
        <div className="card-bg">
          <svg viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M80 0 L100 100 L102 100 L100 0 Z" />
            <path d="M90 0 L100 100 L102 100 L100 0 Z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

const OldCard: FC<Props> = ({ children, className, header, withBackground = true }) => {
  return (
    <div className="card-wrapper">
      {header && <h4 className="card-header">{header}</h4>}
      <div
        className={cx("card", className, {
          "has-background": withBackground,
        })}
      >
        <div>{children}</div>
        <div className="card-bg">
          <svg viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M80 0 L100 100 L102 100 L100 0 Z" />
            <path d="M90 0 L100 100 L102 100 L100 0 Z" />
          </svg>
        </div>
      </div>
    </div>
  );
};
