import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getIntlCost, getIntlNumberFormat } from "../../../../components/utils";
import { Country, Language } from "../../../../data/models/ContractTypes";
import { Brand, PricingModel, TransactionFee } from "../../../../data/models/PricingTypes";
import { CURRENCY_BY_COUNTRY } from "../../../../i18n";
import { FeeBrandLogo } from "../FeeBrandLogo";
import "./TransactionFees.scss";

interface Props {
  transactionFees: TransactionFee[];
  country: Country;
  pricingModel: PricingModel;
}

export const TransactionFees = ({ country, transactionFees, pricingModel }: Props) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as Language;
  const currency = CURRENCY_BY_COUNTRY[country];
  const groupedFees = useMemo(() => groupFees(transactionFees), [transactionFees]);

  return (
    <div className="transaction-fees">
      <div id="transaction-fees" className="section-subtitle">
        {t("Transaction fees")}
      </div>
      <p className="m-bottom-10 fee-description">
        {pricingModel === PricingModel.PACK
          ? t(
              "If the included monthly transaction volume is exceeded, the merchant will be charged a transaction fee for each additional transaction."
            )
          : t("The following table shows the transaction fees for the different card brands")}
      </p>
      <div>
        {groupedFees.map((group, idx) => {
          return (
            <div className="transaction-fees-row" key={idx}>
              <div>
                <ul className="brand-list">
                  {group
                    .sort((a, b) => sortBrands(a, b))
                    .map(({ brand }) => (
                      <li key={brand}>
                        <FeeBrandLogo brand={brand} />
                      </li>
                    ))}
                </ul>
              </div>
              <div className="fee-columns">
                <div className="transaction-fee">
                  <span className="transaction-fee-label">{t("Fee per trx.")}</span>
                  <div className="transaction-fee-value">
                    {getIntlNumberFormat(language, group[0].transactionFee / 100, 2, "percent")}
                  </div>
                </div>
                <div className="transaction-fee">
                  <span className="transaction-fee-label">{t("Fixed fee per trx.")}</span>
                  <div className="transaction-fee-value">
                    {getIntlCost(language, group[0].fixedFee, currency, 2)}
                  </div>
                </div>
                <div className="transaction-fee">
                  <span className="transaction-fee-label">{t("Min fee per trx.")}</span>
                  <div className="transaction-fee-value">
                    {getIntlCost(language, group[0].minFee, currency, 2)}
                  </div>
                </div>
                {typeof group[0].maxFee !== "undefined" && (
                  <div className="transaction-fee">
                    <span className="transaction-fee-label">{t("Max fee per trx.")}</span>
                    <div className="transaction-fee-value">
                      {getIntlCost(language, group[0].maxFee, currency, 2)}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const groupFees = (fees: TransactionFee[]) => {
  const groupedFees: TransactionFee[][] = [];
  fees.forEach((fee) => {
    const group = groupedFees.find((group) => {
      const firstFee = group[0];
      return (
        firstFee.fixedFee === fee.fixedFee &&
        firstFee.maxFee === fee.maxFee &&
        firstFee.minFee === fee.minFee &&
        firstFee.transactionFee === fee.transactionFee
      );
    });
    if (group) {
      group.push(fee);
    } else {
      groupedFees.push([fee]);
    }
  });
  return groupedFees;
};

const brandOrder = [
  Brand.VISA_DEBIT,
  Brand.VISA_CREDIT,
  Brand.MASTERCARD_DEBIT,
  Brand.MASTERCARD_CREDIT,
];

const sortBrands = (a: TransactionFee, b: TransactionFee) => {
  const brandA = a.brand;
  const brandB = b.brand;

  const indexA = brandOrder.indexOf(brandA);
  const indexB = brandOrder.indexOf(brandB);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) {
    return -1;
  }

  if (indexB !== -1) {
    return 1;
  }

  return brandA.localeCompare(brandB);
};
