import { useMemo, FC, useState } from "react";
// import { useLinkId } from "../../hooks/useLinkId";
import { Button } from "../interactions/Buttons/Button";
import { ContractSheet } from "./ContractSheet";
import { API } from "../../network/API";
import { Card } from "../cards/Card";
import styles from "./ContractSheetSection.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { useLinkId } from "../../hooks/useLinkId";
import { EyeIcon } from "../icons/EyeIcon";
import { Link } from "../link/Link";

export const ContractSheetSection: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const linkId = useLinkId();

  const { t } = useTranslation();

  const pdfUrl = useMemo(() => API.getUrl(`/api/merchant/${linkId}/contract-preview`), [linkId]);

  return (
    <Card header="Contract preview" className="contract-preview" maxContent>
      <div className={styles.content}>
        <p style={{ margin: "10px 0" }}>
          <Trans>
            You can preview an initial version of the contract. Please note that not all data has
            been gathered, which means that certain fields might be blank.
          </Trans>
        </p>
        <div className={styles.buttons}>
          <Button block onClick={() => setIsOpen(true)} className={styles.button}>
            {t("Preview contract details")}
            <EyeIcon className={styles.icon} />
          </Button>
          <Link
            link={pdfUrl}
            external
            className={"as-button ghost"}
            externalDownloadIcon
            download={pdfUrl}
          >
            {t("Download contract")}
          </Link>
        </div>
      </div>
      <ContractSheet isOpen={isOpen} url={pdfUrl} onRequestClose={() => setIsOpen(false)} />
    </Card>
  );
};
