import { useRecoilValue } from "recoil";
import {
  legalAssociatesSelector,
  viewerAsAssociateSelector,
} from "../state/associateState";

export function useRequiresIndentification() {
  const signeesAndOwners = useRecoilValue(legalAssociatesSelector);
  const viewer = useRecoilValue(viewerAsAssociateSelector);

  const legalAssociate = signeesAndOwners.find(
    (item) => item.associateId === viewer?.associateId
  );

  if (!legalAssociate) {
    return false;
  }

  if (
    !!legalAssociate.remoteIdentity?.selfieUploaded &&
    !!legalAssociate.remoteIdentity?.idDocumentationUploaded
  ) {
    return false;
  }

  return true;
}
