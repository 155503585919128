import cx from "classnames";
import { nanoid } from "nanoid";
import React, { FC, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Error } from "../../components/icons/Error";
import { FileType, IdImageInterface } from "../../components/idUpload/IdImage";
import { Progress } from "../../components/idUpload/Progress";
import { dataMerchant } from "../../data/dataMerchant";
import { ContractDocument, DocumentId, LinkId } from "../../data/models/ContractTypes";
import { Status } from "../../data/types";
import { useIsMountedRef } from "../../hooks/useIsMounted";
import { API } from "../../network/API";
import "./DocUpload.scss";

interface Props {
  linkId: LinkId;
  doc: ContractDocument;
  setUploadedDoc: (uploaded: IdImageInterface) => void;
  onError?: (document: ContractDocument) => void;
  accept?: string;
  showCamera?: boolean;
  disabled?: boolean;
  setIsUploading: (isLoading: boolean) => void;
}

export interface ProgressInterface {
  progress: number;
  name: string;
}

export const MIME_PDF = "application/pdf";

const buildImage = (linkIdentifier: LinkId, doc: ContractDocument) => {
  if (!doc.fileAvailable) return undefined;

  const url = API.getUrl(`/api/merchant/${linkIdentifier}/documents/${doc.documentId}`);
  const fileType = doc.mimeType === MIME_PDF ? FileType.PDF : FileType.IMAGE;

  return { url: url, type: fileType };
};

export const DocUpload: FC<Props> = ({
  linkId,
  doc,
  setUploadedDoc,
  onError,
  accept = "image/*,application/pdf",
  disabled = false,
  setIsUploading,
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [image, setImage] = useState(buildImage(linkId, doc));
  const identifier = useRef<string>(nanoid());
  const fileElemRef = useRef<HTMLInputElement>(null);
  const mounted = useIsMountedRef();
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);
  const [progress, setProgress] = useState<ProgressInterface>({
    progress: 0,
    name: "",
  });

  const upload = useCallback(
    (targetFile: File, linkIdentifier: LinkId, docId: DocumentId) => {
      setStatus(Status.PENDING);
      setIsUploading(true);

      const request = new XMLHttpRequest();
      request.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setProgress({ progress, name: targetFile.name });
        }
      });

      request.onload = function () {
        if (request.status === 200) {
          setProgress({ progress: 100, name: targetFile.name });

          const isPdf = targetFile.type === MIME_PDF;
          const fileType = isPdf ? FileType.PDF : FileType.IMAGE;

          const url = `${API.getUrl(
            `/api/merchant/${linkIdentifier}/documents/${docId}`
          )}?c=${new Date().getTime()}${isPdf ? "#view=Fit" : ""}`;

          const newImage = { url: url, type: fileType };

          setImage(newImage);
          setUploadedDoc(newImage);
          setIsUploading(false);
          setTimeout(() => {
            if (!mounted.current) return;
            setStatus(Status.DEFAULT);
          }, 4000);
        } else {
          const errorMessage = request.responseText || "Could not upload file. Please try agian.";
          setApiErrorMessage(errorMessage);
          setImage(undefined);
          onError && onError(doc);
          setStatus(Status.ERROR);
          setIsUploading(false);
          setTimeout(() => setStatus(Status.DEFAULT), 4000);
        }
      };

      const formData = new FormData();
      formData.append("document", targetFile);
      formData.append("filename", targetFile.name);
      request.open("POST", API.getUrl(`/api/merchant/${linkIdentifier}/documents/${docId}`));
      request.withCredentials = true;
      request.send(formData);
    },
    [setUploadedDoc, setIsUploading, setImage, onError, doc, mounted]
  );

  const onAttachFile = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>): void => {
      setApiErrorMessage(null);
      const targetFile = ev.target.files?.[0];
      // no file was selected
      if (!targetFile) return;

      if (!image) return upload(targetFile, linkId, doc.documentId);

      dataMerchant
        .deleteUploadedDocument(linkId, doc.documentId)
        .then(() => {
          upload(targetFile, linkId, doc.documentId);
        })
        .catch(() => {
          // is this catch block really neccessary?
          setImage(undefined);
          upload(targetFile, linkId, doc.documentId);
        });
    },
    [linkId, doc.documentId, upload, image]
  );

  return (
    <div className={cx("doc-upload", status)}>
      <div>
        {apiErrorMessage && (
          <div className="error-box">
            <span>{apiErrorMessage}</span>
          </div>
        )}
        <div className="doc-upload-button-container">
          <input
            type="file"
            id={identifier.current}
            name={identifier.current}
            onChange={onAttachFile}
            ref={fileElemRef}
            multiple={false}
            accept={accept}
          />

          <label htmlFor={identifier.current}>
            <span
              className={cx("button", status, {
                block: true,
                // ghost: doc.fileAvailable,
                "is-disabled": disabled,
                // block: !doc.fileAvailable,
              })}
            >
              {doc.fileAvailable ? t("Upload new document") : t("Upload document")}
              {status === Status.ERROR && <Error />}
            </span>
          </label>
          {doc.fileAvailable && (
            <a href={image?.url} target="_blank" rel="noreferrer" className="doc-upload-view-link">
              {t("View file")}
            </a>
          )}
        </div>

        <Progress {...progress} />
      </div>
    </div>
  );
};
