import { ReactNode } from "react";

export const DataListItem = ({
  text,
  value,
}: {
  text: string | ReactNode;
  value?: ReactNode | string | number;
}) => {
  if (typeof value === "undefined") {
    return null;
  }

  return (
    <>
      <dt>{text}</dt>
      <dd>{value}</dd>
    </>
  );
};
