import React from "react";
import { Contact } from "../../data/models/ContractTypes";

interface Props {
  contact: Contact;
}

export const Name: React.FunctionComponent<Props> = ({ contact }) => {
  return <>{`${contact.firstName} ${contact.lastName}`}</>;
};
