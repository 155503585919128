import React from "react";
import { useTranslation } from "react-i18next";
import { Address } from "../../../components/address/Address";
import { Contact } from "../../../components/contact/Contact";
import { Associate as AssociateInterface } from "../../../data/dataMerchant";

interface Props {
  associate: AssociateInterface;
}

export const Associate: React.FunctionComponent<Props> = ({ associate }) => {
  const { t } = useTranslation();

  return (
    <div className="owner">
      <div className="tablet-columns">
        {associate.owner && (
          <>
            {associate.owner.designatedFunction && (
              <div>
                <dl>
                  <dt>{t("Designated function")}</dt>
                  <dd>{associate.owner.designatedFunction || t("Not set")}</dd>
                </dl>
              </div>
            )}
            <div>
              <dl>
                <dt>{t("Voting stake")}</dt>
                <dd>{associate.owner.votingRightsStake} %</dd>
                <dt>{t("Capital stake")}</dt>
                <dd>{associate.owner.capitalStake} %</dd>
              </dl>
            </div>
          </>
        )}

        <Contact className=" split" header={t("Owner contact")} contact={associate.contact} />
        <Address className=" split" header={t("Owner address")} address={associate.address} />
      </div>
    </div>
  );
};
