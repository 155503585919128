import { useTranslation } from "react-i18next";
import { getIntlCost, getIntlNumberFormat } from "../../../../components/utils";
import { Country, Language } from "../../../../data/models/ContractTypes";
import { CURRENCY_BY_COUNTRY } from "../../../../i18n";

import styles from "./SurchargesAuthorizationFees.module.scss";

interface Props {
  country: Country;
  chargebackFee: number;
  surchargesCommercialCards: number;
  surchargesNonEea: number;
}

export const Surcharges = ({
  country,
  chargebackFee,
  surchargesCommercialCards,
  surchargesNonEea,
}: Props) => {
  const { i18n, t } = useTranslation();
  const language = i18n.language as Language;
  const currency = CURRENCY_BY_COUNTRY[country];

  // TODO: Should this be in percent or currency, or can it differ?
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <strong>{t("Surcharges")}</strong>
      </div>

      <dl className={styles.list}>
        <div className={styles.row}>
          <dt>
            <div className={styles.label}>{t("Surcharge commercial cards")}</div>
          </dt>
          <dd>{getIntlNumberFormat(language, surchargesCommercialCards / 100, 2, "percent")}</dd>
        </div>
        <div className={styles.row}>
          <dt>
            <div className={styles.label}>{t("Surcharge non-EEA, excl. commercial cards")}</div>
          </dt>
          <dd>{getIntlNumberFormat(language, surchargesNonEea / 100, 2, "percent")}</dd>
        </div>
        <div className={styles.row}>
          <dt>
            <div className={styles.label}>{t("Chargeback Fee")}</div>
          </dt>
          <dd>{getIntlCost(language, chargebackFee, currency, 2)}</dd>
        </div>
      </dl>
    </div>
  );
};
