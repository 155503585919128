import { useParams } from "react-router";
import { LinkId } from "../data/models/ContractTypes";

type Params = {
  linkId: LinkId;
};

export function useLinkId() {
  const { linkId } = useParams<Params>();
  return linkId ? linkId : null;
}
