import { Store } from "../data/dataMerchant";
import { TerminalType } from "../data/models/ContractTypes";
import { PricingModel } from "../data/models/PricingTypes";

export function getIntlCost(lang: string, number: number, currency: string, decimals: number = 2) {
  return new Intl.NumberFormat(lang, {
    style: "currency",
    currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export function getIntlNumberFormat(
  lang: string,
  number: number,
  decimals: number = 0,
  style?: "percent"
) {
  return new Intl.NumberFormat(lang, {
    style,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export const getPricingModelDisplayName = (model: PricingModel) => {
  switch (model) {
    case PricingModel.BLENDED:
      return "Blended";
    case PricingModel.ICPP:
      return "Interchange ++";
    case PricingModel.PACK:
      return "Pack";
    default:
      return model;
  }
};

export const getTerminalTypeString = (terminalType: TerminalType, abbreviated: boolean = false) => {
  if (abbreviated) {
    switch (terminalType) {
      case TerminalType.DESK_3500:
        return "Desk";
      case TerminalType.SOFT_POS:
        return "WL ToM";
      case TerminalType.MOVE_3500:
        return "Move";
      default:
        return terminalType;
    }
  } else {
    switch (terminalType) {
      case TerminalType.DESK_3500:
        return "Desk 3500";
      case TerminalType.SOFT_POS:
        return "Worldline Tap on Mobile";
      case TerminalType.MOVE_3500:
        return "Move 3500";
      case TerminalType.DESK_5000:
        return "Desk 5000";
      case TerminalType.MOVE_5000:
        return "Move 5000";
      case TerminalType.SATURN:
        return "Saturn";
      case TerminalType.LINK_2500:
        return "Link 2500";
      case TerminalType.DX_8000:
        return "DX 8000";
      default:
        return terminalType;
    }
  }
};

export const getTerminalImageData = (
  terminalType: TerminalType
): { src: string; ratio: number; alt: string } => {
  switch (terminalType) {
    case TerminalType.DESK_3500:
      return {
        src: "/images/terminals/desk_5000_197x432.png",
        ratio: 197 / 432,
        alt: "Desk 3500 terminal",
      };
    case TerminalType.DESK_5000:
      return {
        src: "/images/terminals/desk_5000_197x432.png",
        ratio: 197 / 432,
        alt: "Desk 5000 terminal",
      };
    case TerminalType.MOVE_3500:
      return {
        src: "/images/terminals/move_5000_210x435.png",
        ratio: 210 / 435,
        alt: "Move 3500 terminal",
      };
    case TerminalType.MOVE_5000:
      return {
        src: "/images/terminals/move_5000_210x435.png",
        ratio: 210 / 435,
        alt: "Move 5000 terminal",
      };
    case TerminalType.LINK_2500:
      return {
        src: "/images/terminals/link_2500_256x469.png",
        ratio: 256 / 469,
        alt: "Link 2500 terminal",
      };
    case TerminalType.SATURN:
      return {
        src: "/images/terminals/saturn_377x929.png",
        ratio: 377 / 929,
        alt: "Saturn terminal",
      };
    case TerminalType.SOFT_POS:
      return {
        src: "/images/terminals/softpos_239x499.png",
        ratio: 239 / 499,
        alt: "Worldline Tap on Mobile Terminal",
      };
    case TerminalType.DX_8000:
      return {
        src: "/images/terminals/DX8000.png",
        ratio: 239 / 499,
        alt: "DX8000 Terminal",
      };
  }
};

export const getStoreAddress = (store: Store) => {
  const { address } = store;
  const addressIsComplete = address.street && address.streetNumber && address.city;

  return addressIsComplete ? `${address.street} ${address.streetNumber}, ${address.city}` : "";
};
