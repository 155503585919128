import React, { useEffect, useMemo } from "react";
import { ContractLoader } from "../ContractLoader/ContractLoader";
import { Trans, useTranslation } from "react-i18next";
import { Beacon } from "../../../components/beacon/Beacon";
import { Validity } from "../../../components/icons/AssociateIcon";
import { Logo } from "../../../components/images/Logo";
import { useRecoilValue } from "recoil";
import {
  associateState,
  legalAssociatesSelector,
  viewerAsAssociateSelector,
} from "../../../state/associateState";
import {
  isAccountHolder,
  isOwner,
  isPrimary,
  isSignee,
} from "../../../state/associateState";
import { Name } from "../../../components/name/Name";
import { FieldSet } from "../../../components/fieldSet/FieldSet";
import { Associate, AssociateRole } from "../../../data/dataMerchant";
import { contractState } from "../../../state/contractState";
import { documentState } from "../../../state/documentState";
import "./Done.scss";
import { confirmedState } from "../../../state/confirmedState";
import { salesContactState } from "../../../state/salesContactState";
import { generatePath, useNavigate } from "react-router-dom";
import { CONTRACT_PAGE_URL } from "../Contract";
import { useIsDone } from "../../../hooks/useIsDone";

export const DONE_PAGE_URL = "/:linkId/done";

function getTitle(associate: Associate) {
  if (isSignee(associate)) {
    return "Legal representative";
  }

  if (isOwner(associate)) {
    return "Beneficial owner";
  }

  if (isPrimary(associate)) {
    return "Primary contact";
  }

  return "Bank Account manager";
}

export const Done: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const viewer = useRecoilValue(viewerAsAssociateSelector);
  const salesContact = useRecoilValue(salesContactState);
  const ownersAndSignees = useRecoilValue(legalAssociatesSelector);
  const contract = useRecoilValue(contractState);
  const documents = useRecoilValue(documentState);
  const confirmed = useRecoilValue(confirmedState);
  let navigate = useNavigate();
  const associates = useRecoilValue(associateState);

  const additionalAssociates = useMemo(
    () =>
      associates.filter(
        (associate) =>
          associate.associateId !== viewer?.associateId &&
          (associate.roles.includes(AssociateRole.BENEFICIAL_OWNER) ||
            associate.roles.includes(AssociateRole.SIGNATORY) ||
            associate.roles.includes(AssociateRole.PRIMARY_CONTACT) ||
            associate.roles.includes(AssociateRole.ACCOUNT_HOLDER))
      ),
    [associates, viewer]
  );
  const isDone = useIsDone();

  useEffect(() => {
    if (!viewer || 0 === associates.length || !contract.linkId) {
      return;
    }

    if (!isDone) {
      navigate(
        generatePath(CONTRACT_PAGE_URL, {
          linkId: contract.linkId,
        })
      );
    }
  }, [associates.length, contract.linkId, navigate, isDone, viewer]);

  const { email, name, phoneNumber } = salesContact;
  const mailTo = `mailto:${salesContact.email}`;
  const tel = `tel:${salesContact.phoneNumber}`;

  const allLegalDone = ownersAndSignees.every((associate) => {
    const remoteIdComplete =
      !!associate.remoteIdentity?.selfieUploaded &&
      !!associate.remoteIdentity?.idDocumentationUploaded;

    if (isOwner(associate)) {
      return remoteIdComplete;
    }

    return remoteIdComplete && !!viewer?.signatory?.signed;
  });

  const allDocsDone = documents.every((doc) => doc.fileAvailable);

  const bankDone =
    contract.contract.bankAccount.iban ||
    contract.contract.bankAccount.statementUploaded;

  const allDone = allDocsDone && allLegalDone && bankDone;

  return (
    <ContractLoader>
      <section className="done-page">
        <article>
          <div className="top-logo-wrapper">
            <Logo />
          </div>

          <div className="all-done-box">
            <Beacon validity={Validity.VALID} />
            <div>
              <strong>{t("You are done!")}</strong>
            </div>
          </div>
          {allDone ? (
            <div>
              <p>
                <Trans>
                  Thank your for submitting your application. As soon as your
                  application has been reviewed you will receive a confirmation
                  email.
                </Trans>
              </p>
              <p>
                <Trans>
                  If you have any questions, please contact your sales
                  representative
                </Trans>
              </p>
              <b>{name}</b>
              <dl className="sales-contact-flex">
                <dt>{t("Email")}:</dt>
                <dd className="truncate">
                  <a href={mailTo}>{email}</a>
                </dd>
                {salesContact.phoneNumber && (
                  <>
                    <dt>{t("Phone")}:</dt>
                    <dd className="truncate">
                      <a href={tel}>{phoneNumber}</a>
                    </dd>
                  </>
                )}
              </dl>
            </div>
          ) : (
            <div>
              <Trans>
                We are still waiting for additional information before we can
                review your application.
              </Trans>

              <FieldSet
                header={t("Stakeholders")}
                className="split split-larger"
              >
                <ul>
                  {additionalAssociates.map((associate) => {
                    const hasUploadedId =
                      !!associate.remoteIdentity?.selfieUploaded &&
                      !!associate.remoteIdentity?.idDocumentationUploaded;

                    const singleOwner =
                      isOwner(associate) && !isSignee(associate);

                    return (
                      <div
                        className="owner-status text-small"
                        key={`${associate.associateId}-progress`}
                      >
                        <b>
                          <Name contact={associate.contact} />
                        </b>{" "}
                        - <i>{t(getTitle(associate))}</i>
                        <div className="contract-uploads-info">
                          <dl>
                            <dt className="upload-beacon">
                              <Beacon
                                className="mini"
                                validity={
                                  associate.contractViewed
                                    ? Validity.VALID
                                    : Validity.MISSING
                                }
                              />
                            </dt>
                            <dd>{t("Contract viewed")}</dd>

                            {isPrimary(associate) && (
                              <>
                                <dt className="upload-beacon">
                                  <Beacon
                                    className="mini"
                                    validity={
                                      confirmed.confirmed
                                        ? Validity.VALID
                                        : Validity.MISSING
                                    }
                                  />
                                </dt>
                                <dd>{t("Contract confirmed")}</dd>
                              </>
                            )}

                            {documents.length > 0 && isPrimary(associate) && (
                              <>
                                <dt className="upload-beacon">
                                  <Beacon
                                    className="mini"
                                    validity={
                                      allDocsDone
                                        ? Validity.VALID
                                        : Validity.MISSING
                                    }
                                  />
                                </dt>
                                <dd>{t("Documents uploaded")}</dd>
                              </>
                            )}

                            {isAccountHolder(associate) && (
                              <>
                                <dt className="upload-beacon">
                                  <Beacon
                                    className="mini"
                                    validity={
                                      bankDone
                                        ? Validity.VALID
                                        : Validity.MISSING
                                    }
                                  />
                                </dt>
                                <dd>{t("Bank account registered")}</dd>
                              </>
                            )}

                            {singleOwner && (
                              <>
                                <dt className="upload-beacon">
                                  <Beacon
                                    className="mini"
                                    validity={
                                      hasUploadedId
                                        ? Validity.VALID
                                        : Validity.MISSING
                                    }
                                  />
                                </dt>
                                <dd>{t("Identification uploaded")}</dd>
                              </>
                            )}

                            {isSignee(associate) && (
                              <>
                                <dt className="upload-beacon">
                                  <Beacon
                                    className="mini"
                                    validity={
                                      hasUploadedId
                                        ? Validity.VALID
                                        : Validity.MISSING
                                    }
                                  />
                                </dt>
                                <dd>{t("Identification uploaded")}</dd>
                                <dt className="upload-beacon">
                                  <Beacon
                                    className="mini"
                                    validity={
                                      associate.signatory?.signature
                                        ? Validity.VALID
                                        : Validity.MISSING
                                    }
                                  />
                                </dt>
                                <dd>{t("Contract signed")}</dd>
                              </>
                            )}
                          </dl>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              </FieldSet>
            </div>
          )}
        </article>
      </section>
    </ContractLoader>
  );
};
