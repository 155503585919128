import React from "react";
import cx from "classnames";
import { Checkmark } from "../icons/Checkmark";
import { ErrorOutline } from "../icons/ErrorOutline";
import { Profile } from "../icons/Profile";
import { WarningOutline } from "../icons/WarningOutline";
import "./AssociateIcon.scss";

export enum Validity {
    MISSING = "has-missing-information",
    PARTIAL = "has-partial-information",
    VALID = "has-required-information",
    DEFAULT = "",
  }

interface Props {
  validity: Validity;
}

export const AssociateIcon: React.FunctionComponent<Props> = ({ validity }) => {
  if (validity === Validity.MISSING) {
    return (
      <div className={cx("associate-icon", validity)}>
        <ErrorOutline />
      </div>
    );
  }

  if (validity === Validity.PARTIAL) {
    return (
      <div className={cx("associate-icon", validity)}>
        <WarningOutline />
      </div>
    );
  }

  if (validity === Validity.VALID) {
    return (
      <div className={cx("associate-icon", validity)}>
        <Checkmark />
      </div>
    );
  }

  return (
    <div className={cx("associate-icon", validity)}>
      <Profile />
    </div>
  );
};
