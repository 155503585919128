import { useRecoilValue } from "recoil";
import { AssociateRole } from "../data/dataMerchant";
import { getCurrentAssociate } from "../state/associateState";
import { associateState } from "../state/associateState";
import { viewerState } from "../state/viewerState";

export function useRequiresSignature() {
  const associates = useRecoilValue(associateState);
  const viewer = useRecoilValue(viewerState);
  const currentAssociate = getCurrentAssociate(associates, viewer);
  const isSignee =
    !!currentAssociate &&
    currentAssociate.roles.indexOf(AssociateRole.SIGNATORY) > -1;

  return isSignee && currentAssociate?.signatory?.signed === undefined;
}
