import { viewerState } from "./viewerState";
import { atom, selector } from "recoil";
import {
  Associate,
  AssociateContractViewer,
  AssociateRole,
} from "../data/dataMerchant";

export const associateState = atom<Associate[]>({
  key: "associate-state",
  default: [],
});

export const legalAssociatesSelector = selector({
  key: "legal-associates-selector",
  get: ({ get }) => {
    return get(associateState).filter(
      (item) =>
        item.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1 ||
        item.roles.indexOf(AssociateRole.SIGNATORY) > -1
    );
  },
});

export const viewerAsAssociateSelector = selector({
  key: "viewer-as-associate-selector",
  get: ({ get }) => {
    const associates = get(associateState);
    const viewer = get(viewerState);
    return associates.find((item) => item.associateId === viewer.associateId);
  },
});

export function getCurrentAssociate(
  associates: Associate[],
  viewer: AssociateContractViewer
) {
  return associates.find((item) => item.associateId === viewer.associateId);
}

export function isPrimary(associate?: Associate) {
  return (
    !!associate && associate.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1
  );
}

export function isOwner(associate?: Associate) {
  return (
    !!associate && associate.roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1
  );
}

export function isSignee(associate?: Associate) {
  return !!associate && associate.roles.indexOf(AssociateRole.SIGNATORY) > -1;
}

export function isAccountHolder(associate?: Associate) {
  return (
    !!associate && associate.roles.indexOf(AssociateRole.ACCOUNT_HOLDER) > -1
  );
}
