import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import ReactSignatureCanvas from "react-signature-canvas";
import { Button } from "../../../components/interactions/Buttons/Button";
import { useWidth } from "../../../hooks/useWidth";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import "./SignatureModal.scss";
import { Status } from "../../../data/types";
import { dataMerchant } from "../../../data/dataMerchant";
import { useLinkId } from "../../../hooks/useLinkId";
import { useRecoilState, useRecoilValue } from "recoil";
import { associateState } from "../../../state/associateState";
import { viewerState } from "../../../state/viewerState";
import { UTCDate } from "../../../data/models/ContractTypes";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { StatusBoxes } from "../../../components/boxes/StatusBoxes";
import { SuccessBox } from "../../../components/boxes/SuccessBox";

interface StatusMessage {
  message?: string | ReactNode;
  status: Status;
}

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  onSignCompleted: (signature: string) => void;
}

interface Area {
  w: number;
  h: number;
}

export const SignatureModal: VoidFunctionComponent<Props> = ({
  isOpen,
  onRequestClose,
  onSignCompleted,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [canvasArea, setCanvasArea] = useState<Area>({
    w: 0,
    h: 0,
  });
  const canvasRef = useRef<any>(null);
  const width = useWidth();
  const [status, setStatus] = useState<StatusMessage>({
    status: Status.DEFAULT,
    message: "&nbsp;",
  });
  const [, setAssociates] = useRecoilState(associateState);
  const viewer = useRecoilValue(viewerState);
  const associateId = viewer.associateId;
  const linkId = useLinkId();

  const onClear = useCallback(() => {
    canvasRef.current?.clear();
  }, []);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const boxWidth = ref.current.getBoundingClientRect().width;
    setCanvasArea({
      w: Math.min(boxWidth, 1024),
      h: Math.min(boxWidth * 0.4, 200),
    });
  }, [width, isOpen]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const afterCloseHandler = () => enableBodyScroll(document.body);

  const afterOpenHandler = () => {
    disableBodyScroll(document.body, {
      reserveScrollBarGap: true,
    });

    if (!ref?.current) {
      return;
    }

    const boxWidth = ref.current.getBoundingClientRect().width;
    setCanvasArea({
      w: Math.min(boxWidth, 1024),
      h: Math.min(boxWidth * 0.4, 200),
    });
  };

  const onSaveSignature = useCallback(() => {
    if (!linkId) return;

    if (canvasRef.current.isEmpty()) {
      setStatus({
        status: Status.ERROR,
        message: t("Signature is missing"),
      });
      setTimeout(() => {
        setStatus({
          status: Status.DEFAULT,
          message: t("Signature is missing"),
        });
      }, 2000);
      return;
    }

    const newSignature = canvasRef.current.toDataURL();
    dataMerchant
      .uploadSignature(linkId, newSignature)
      .then(() => {
        setStatus({
          status: Status.SUCCESS,
          message: t("Thank you! We have saved your signature."),
        });
        setAssociates((items) => {
          return items.map((associate) => {
            if (associate.associateId === associateId) {
              return {
                ...associate,
                signatory: {
                  signature: newSignature,
                  signed: new Date().toISOString() as UTCDate,
                },
              };
            }
            return associate;
          });
        });
        // window.location.reload();
        onSignCompleted(newSignature);
      })
      .catch((err) => {
        setStatus({
          status: Status.ERROR,
          message: t("Something went wrong. Try again?"),
        });
        setTimeout(() => {
          setStatus({
            status: Status.DEFAULT,
            message: t("Something went wrong. Try again?"),
          });
        }, 3000);
      });
  }, [associateId, linkId, setAssociates, t, onSignCompleted]);

  return (
    <ReactModal
      overlayClassName="signature-modal"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backgroundColor: "rgba(242, 242, 242, 0.75)",
        },
        content: {
          padding: 0,
          position: "initial",
          width,
          border: 0,
          borderRadius: 0,
          margin: 0,
        },
      }}
      shouldCloseOnOverlayClick={true}
      onAfterClose={afterCloseHandler}
      onAfterOpen={afterOpenHandler}
    >
      <div className="signature-wrapper" ref={ref}>
        <ReactSignatureCanvas
          ref={canvasRef}
          canvasProps={{
            width: canvasArea.w,
            height: canvasArea.h,
            className: "canvas",
          }}
        />
        <h4>{t("Please draw your signature in the box above")}</h4>
        <div className="">
          <StatusBoxes status={status.status}>
            <ErrorBox relative>{status.message}</ErrorBox>
            <SuccessBox>{status.message}</SuccessBox>
          </StatusBoxes>
        </div>

        <div className="text-right m-top-10">
          <Button ghost onClick={onClear}>
            {t("Clear")}
          </Button>
          <Button onClick={onSaveSignature} status={status.status}>
            {t("Confirm")}
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};
