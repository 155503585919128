import { API } from "../network/API";
import { KlarnaToken, LinkId } from "./models/ContractTypes";
const KLARNA_SCRIPT_URI = `https://x.klarnacdn.net/xs2a/app-launcher/v0/xs2a-app-launcher.js`;

// export interface TokenResponse {
//   token?: KlarnaToken;
// }

interface KlarnaInitResponse {
  state: string;
  clientToken: KlarnaToken;
}

export interface KlarnaError {
  category: string;
  message?: string;
  reason?: Error;
}

export enum KlarnaState {
  PROCESSING = "PROCESSING",
  CONSUMER_INPUT_NEEDED = "CONSUMER_INPUT_NEEDED",
  ABORTED = "ABORTED",
  EXCEPTION = "EXCEPTION",
  FINISHED = "FINISHED",
}

export interface KlarnaPollResponse {
  state: string;
  clientToken?: KlarnaToken;
  accounts?: KlarnaAccount[];
}

enum AccountType {
  DEFAULT = "DEFAULT",
  SAVING = "SAVING",
  CREDITCARD = "CREDITCARD",
  DEPOT = "DEPOT",
  UNKNOWN = "UNKNOWN",
}

export interface KlarnaAccount {
  id: string;
  iban?: string;
  accountName?: string;
  accountHolder?: string;
  accountType: AccountType;
  accountStatus:
    | "MATCH"
    | "VALID"
    | "NO_HOLDER"
    | "NO_IBAN"
    | "INVALID_TYPE"
    | "INVALID_CURRENCY";
}

export const dataKlarna = {
  getKlarnaSDK: (): Promise<any> => {
    if (window.XS2A) {
      return Promise.resolve();
    }
    if (process.env.REACT_APP_STATIC) {
      return window.scripts.importScript(KLARNA_SCRIPT_URI);
    }

    return window.scripts.importScript("/libs/xs2a-app-launcher.js");
  },

  initKlarna: (linkId: LinkId) =>
    API.post<KlarnaInitResponse>(`/api/merchant/${linkId}/klarna`),

  pollForClientAccounts: (linkId: LinkId) =>
    API.get<KlarnaPollResponse>(`/api/merchant/${linkId}/klarna`, true),

  deleteSession: (linkId: LinkId) =>
    API.delete(`/api/merchant/${linkId}/klarna`),

  selectAccount: (linkId: LinkId, iban: string) => {
    return API.post(`/api/merchant/${linkId}/klarna/bank-account`, {
      iban,
    });
  },

  // POST /api/merchant/{linkId}/bank-statement kan uploadas fler gånger så
  // länge som inte kontraktet inte är konfirmat

  // POST /api/merchant/{linkId}/bank iban välj konto från lista
};
