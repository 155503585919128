import React, { ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { ContractFeature } from "../../data/dataMerchant";
import { contractState } from "../../state/contractState";

interface FeatureProps {
  children: ReactNode;
}

interface Props {
  feature: ContractFeature;
  children: ReactNode;
}

export const FeatureSwitch: React.FunctionComponent<Props> & {
  Default: React.FunctionComponent<FeatureProps>;
  Feature: React.FunctionComponent<FeatureProps>;
} = ({ feature, children }) => {
  const { features } = useRecoilValue(contractState);
  const showFeature = features.includes(feature);

  return (
    <>
      {React.Children.map(children, (child) => {
        const type = (child as any).type;

        if (type !== Feature && type !== Default) {
          throw Error("FeatureSwitch: Child type not supported");
        }

        if (showFeature && type === Feature) {
          return child;
        }

        if (!showFeature && type === Default) {
          return child;
        }

        return null;
      })}
    </>
  );
};

export const Default: React.FunctionComponent<FeatureProps> = ({
  children,
}) => {
  return <>{children}</>;
};

export const Feature: React.FunctionComponent<FeatureProps> = ({
  children,
}) => {
  return <>{children}</>;
};

FeatureSwitch.Default = Default;
FeatureSwitch.Feature = Feature;
