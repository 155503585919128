import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Card } from "../../../components/cards/Card";
import { Associate } from "../../../data/dataMerchant";
import { FcSelfie } from "react-icons/fc";
import { Button } from "../../../components/interactions/Buttons/Button";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { DEVICE_SELECTION_URL } from "../Identification/DeviceSelection/DeviceSelection";
import { LinkId } from "../../../data/models/ContractTypes";
import { useRequiresIndentification } from "../../../hooks/useRequiresIndentification";
import { useRecoilValue } from "recoil";
import { legalAssociatesSelector } from "../../../state/associateState";
import styles from "./IdentificationCard.module.scss";
import { ids } from "../Contract";

interface Props {
  associate?: Associate;
}

export const IdentificationCard: FC<Props> = ({ associate }) => {
  const signeesAndOwners = useRecoilValue(legalAssociatesSelector);
  const { t } = useTranslation();
  const { linkId } = useParams<{ linkId: LinkId }>();
  const navigate = useNavigate();
  const currentAssociate = signeesAndOwners.find(
    (item) => item.associateId === associate?.associateId
  );
  const requiresIdentification = useRequiresIndentification();

  if (!currentAssociate) {
    return null;
  }

  return (
    <div className="identify" id={ids.IDENTIFICATION}>
      <Card
        header={t("Proof of identity")}
        completed={!requiresIdentification}
        maxContent
        headerIcon
      >
        <div className="">
          <React.Fragment>
            <div>
              <div className={styles.icon_container}>
                <FcSelfie size={74} />
              </div>
              {requiresIdentification ? (
                <div>
                  <Trans>
                    Due to anti-money laundering regulations we need to ask you
                    to provide us with proof of identity.
                  </Trans>
                  <br />
                  <br />
                  <Trans>
                    When you click the button below, you will be asked to take a
                    picture of yourself as well as a picture of a valid
                    goverment id.
                  </Trans>
                  <br />
                  <br />
                  <i>
                    <Trans>
                      Please have your smart phone available as this process can
                      only be completed on a mobile phone.
                    </Trans>
                  </i>
                </div>
              ) : (
                <div>
                  <p className={styles.success}>
                    {t("You've provided all the necessary information")}
                  </p>
                </div>
              )}
            </div>
            {requiresIdentification && (
              <Button
                block
                className="card-action-button m-top-20"
                onClick={() => {
                  navigate(
                    generatePath(DEVICE_SELECTION_URL, {
                      linkId: linkId ? linkId : null,
                    })
                  );
                }}
              >
                {t("Confirm my identity")}
              </Button>
            )}
          </React.Fragment>
        </div>
      </Card>
    </div>
  );
};
