import { FunctionComponent, useCallback, useEffect, useState, PropsWithChildren } from "react";
import { useSetRecoilState } from "recoil";
import { Retry } from "../../../components/retry/Retry";
import { AssociateRole, Contract, dataMerchant } from "../../../data/dataMerchant";
import { LinkId, defaultLanguageFromCountry } from "../../../data/models/ContractTypes";
import { Status } from "../../../data/types";
import i18n from "../../../i18n";
import { cache } from "../../../network/Cache";
import { associateState } from "../../../state/associateState";
import { confirmedState } from "../../../state/confirmedState";
import { ACTION_TYPE, contractState } from "../../../state/contractState";
import { documentState } from "../../../state/documentState";
import { salesContactState } from "../../../state/salesContactState";
import { viewerState } from "../../../state/viewerState";
import "./ContractLoader.scss";
import { useLinkId } from "../../../hooks/useLinkId";
import ContractHeader from "./ContractHeader";

export function getType(contract: Contract) {
  const associateId = contract.contractViewer.associateId;
  const associate = contract.associates.find((item) => item.associateId === associateId);

  if (!associate) {
    return ACTION_TYPE.VERIFICATION_ONLY;
  }

  const { roles } = associate;

  if (contract.confirmedContract.confirmed) {
    if (roles.indexOf(AssociateRole.SIGNATORY) > -1) {
      return ACTION_TYPE.UPLOAD_AND_SIGNATURE;
    }

    if (roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1) {
      return ACTION_TYPE.UPLOAD;
    }

    return ACTION_TYPE.DOCUMENTS_ONLY;
  } else {
    if (roles.indexOf(AssociateRole.SIGNATORY) > -1) {
      return ACTION_TYPE.VERIFICATION_UPLOAD_AND_SIGNATURE;
    }

    if (roles.indexOf(AssociateRole.BENEFICIAL_OWNER) > -1) {
      return ACTION_TYPE.VERIFICATION_UPLOAD;
    }

    return ACTION_TYPE.VERIFICATION_ONLY;
  }
}

export const ContractLoader: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [contract, setContract] = useState<Contract>();
  const setContractState = useSetRecoilState(contractState);
  const setAssociate = useSetRecoilState(associateState);
  const setConfirmed = useSetRecoilState(confirmedState);
  const setDocuments = useSetRecoilState(documentState);
  const setViewer = useSetRecoilState(viewerState);
  const setSalesContact = useSetRecoilState(salesContactState);

  const linkId = useLinkId();

  const load = useCallback(
    (idParam: LinkId) => {
      setStatus(Status.PENDING);

      dataMerchant
        .getContract(idParam)
        .then((response) => {
          const type = getType(response);
          setStatus(Status.SUCCESS);
          setContract(response);
          setContractState({
            contract: response.contract,
            linkId: idParam as LinkId,
            hasShownContract: !response.confirmedContract.confirmed,
            type,
            hasShownIntro: false,
            features: response.enabledFeatures,
          });
          setConfirmed((prev) => ({
            ...prev,
            ...response.confirmedContract,
          }));
          setSalesContact(response.salesContact);
          setViewer(response.contractViewer);
          setDocuments(response.documents);
          setAssociate(response.associates);

          const viewerLanguage =
            response.contractViewer.language ||
            defaultLanguageFromCountry(response.contract.contractData.country);

          let lang;
          try {
            const urlSearchParams = new URLSearchParams(window.location.search);
            ({ lang } = Object.fromEntries(urlSearchParams.entries()));
          } catch (err) {}

          console.log("lang in loader: ", lang);
          console.log("viewerLanguage in loader: ", viewerLanguage);
          i18n.changeLanguage(lang || viewerLanguage);
        })
        .catch((err) => {
          console.log("err", err);
          setStatus(Status.ERROR);
        });
    },
    [setContractState, setAssociate, setSalesContact, setViewer, setDocuments, setConfirmed]
  );

  useEffect(() => {
    if (!linkId) return;
    load(linkId);
    return () => {
      /**
       * Everything should be fetched from the server on navigate
       */
      cache.clear();
    };
  }, [load, linkId]);

  const retry = useCallback(() => {
    if (!linkId) return;
    setStatus(Status.PENDING);
    setTimeout(() => {
      load(linkId);
    }, 500);
  }, [load, linkId]);

  return (
    <div className="confirm-page">
      <Retry retry={retry} status={status}>
        {contract && (
          <>
            <ContractHeader />
            {children}
          </>
        )}
      </Retry>
    </div>
  );
};
