import { NO_COUNTRY } from "./../data/models/ContractTypes";
import { atom } from "recoil";
import { MerchantContractInformation, PricingStep } from "../data/dataMerchant";
import { ContractVersion, Country, LinkId, PackageId } from "../data/models/ContractTypes";
import { ContractFeature } from "../data/dataMerchant";
import { ContractId } from "../data/models/CommonTypes";

export enum ACTION_TYPE {
  DOCUMENTS_ONLY = "DOCUMENTS_ONLY",
  VERIFICATION_ONLY = "VERIFICATION_ONLY",
  VERIFICATION_UPLOAD = "VERIFICATION_UPLOAD",
  VERIFICATION_UPLOAD_AND_SIGNATURE = "VERIFICATION_UPLOAD_AND_SIGNATURE",
  UPLOAD = "UPLOAD",
  UPLOAD_AND_SIGNATURE = "UPLOAD_AND_SIGNATURE",
}

export interface ContractState {
  contract: MerchantContractInformation;
  linkId: LinkId;
  type: ACTION_TYPE;
  hasShownContract: boolean;
  hasShownIntro: boolean;
  features: ContractFeature[];
}

export const defaultContract: MerchantContractInformation = {
  contractData: {
    version: 0 as ContractVersion,
    country: Country.POLAND,
    companyName: "",
    companyType: "",
    companyRegistrationId: "",
    cardholderStatementText: "",
    primaryAddress: {
      street: "",
      streetNumber: "",
      postalCode: "",
      city: "",
      countryCode: NO_COUNTRY,
    },
    website: "",
    mcc: "",
    mccIndustry: "",
    vatConfirmed: false,
    companyRegistrationConfirmed: false,
    contractId: "" as ContractId,
  },
  contractPackage: {
    packageId: PackageId.SOFTPOS_BUNDLE,
    pricingStep: PricingStep.STANDARD,
    promotionMonths: 0,
    cashlessPromotion: false,
  },
  contractPricing: [],
  stores: [],
  portalLogins: [],
  bankAccount: {
    iban: "",
    accountHolder: "",
    bank: "",
    bic: "",
  },
};

export const contractState = atom<ContractState>({
  key: "contract-state",
  default: {
    contract: defaultContract,
    linkId: "" as LinkId,
    type: ACTION_TYPE.VERIFICATION_ONLY,
    hasShownContract: false,
    hasShownIntro: false,
    features: [],
  },
});

export function hasFeature(feature: ContractFeature, features: ContractFeature[]) {
  return features.indexOf(feature) > -1;
}
