import cx from "classnames";
import "./DataList.scss";
import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  type?: "vertical" | "horizontal";
  align?: "left" | "right" | "center";
  small?: boolean;
  spaced?: boolean;
}

export const DataList: React.FunctionComponent<Props> = ({
  children,
  type = "horizontal",
  align = "right",
  small = false,
  spaced = false,
}) => {
  return (
    <dl
      className={cx("data-list", {
        "--vertical": type === "vertical",
        "--horizontal": type === "horizontal",
        "--left": align === "left",
        "--right": align === "right",
        "--center": align === "center",
        "--small": small,
        "--spaced": spaced,
      })}
    >
      {children}
    </dl>
  );
};
