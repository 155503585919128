import { BRAND_DATA_MAP, Brand } from "../../../data/models/PricingTypes";
import "./FeeBrandLogo.scss";
import { FC } from "react";
import cx from "classnames";
interface Props {
  brand: Brand;
  greyVariant?: boolean;
}

export const FeeBrandLogo: FC<Props> = ({ brand, greyVariant }) => {
  const { logo: Logo, type } = BRAND_DATA_MAP[brand];
  const isDebit = type === "Debit";
  const isCredit = type === "Credit";

  return (
    <div className="fee-brand-logo">
      <div className={cx("fee-logo", { "fee-logo-grey": greyVariant })}>{<Logo />}</div>
      <div className="fee-type-wrapper">
        {isDebit && <div className="fee-type">Debit</div>}
        {isCredit && <div className="fee-type">Credit</div>}
      </div>
    </div>
  );
};

const brandOrder = [
  Brand.VISA_DEBIT,
  Brand.VISA_CREDIT,
  Brand.MASTERCARD_DEBIT,
  Brand.MASTERCARD_CREDIT,
  Brand.MAESTRO,
  Brand.VPAY,
];

export const sortBrands = (a: Brand, b: Brand) => {
  const brandA = a;
  const brandB = b;

  const indexA = brandOrder.indexOf(brandA);
  const indexB = brandOrder.indexOf(brandB);

  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  if (indexA !== -1) {
    return -1;
  }

  if (indexB !== -1) {
    return 1;
  }

  return brandA.localeCompare(brandB);
};
