import React from "react";
import cx from "classnames";
import { Image } from "../../components/images/Image";
import { Country } from "../../data/models/ContractTypes";
import { useRecoilValue } from "recoil";
import { contractState } from "../../state/contractState";

interface Props {
  className?: string;
}

export const Logo: React.FunctionComponent<Props> = ({ className }) => {
  const { contract } = useRecoilValue(contractState);

  if (contract.contractData.country === Country.CZECHIA) {
    return (
      <div className={cx("logo", className)}>
        <Image
          src="/images/kblogo_550x78.png"
          ratio={550 / 78}
          alt="KB Smart pay logo"
        />
      </div>
    );
  }

  return (
    <div className={cx("logo", className)}>
      <Image
        src="/images/logo-worldline.png"
        ratio={225 / 23}
        alt="Worldline logo"
      />
    </div>
  );
};
