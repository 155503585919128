import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import {
  associateState,
  legalAssociatesSelector,
  viewerAsAssociateSelector,
} from "../../state/associateState";
import { confirmedState } from "../../state/confirmedState";
import { contractState } from "../../state/contractState";
import { documentState } from "../../state/documentState";
import { ActionButton } from "./actionButton/ActionButton";
import { Associates } from "../ContractVerification/associates/Associates";
import { BankAccount } from "../ContractVerification/bankAccount/BankAccount";
import { ContractData } from "../ContractVerification/contractData/ContractData";
import { DocumentsCard } from "./documentCard/DocumentsCard";
import { IdentificationCard } from "./identificationCard/IdentificationCard";
import { PortalLogins } from "../ContractVerification/portalLogins/PortalLogins";
import { Signatures } from "./signatures/Signatures";
import { Stores } from "../ContractVerification/stores/Stores";
import { ConfirmContract } from "./ConfirmContract/ConfirmContract";
import { ContractLoader } from "./ContractLoader/ContractLoader";
import { SalesContact } from "./SalesContact/SalesContact";
import { Actions } from "./Actions/Actions";
import { Visible } from "../../components/visible/Visible";
import { AssociateRole, ContractFeature } from "../../data/dataMerchant";
import "./Contract.scss";
import { Terms } from "./Terms/Terms";
import { Button } from "../../components/interactions/Buttons/Button";
import { Overlay } from "../../components/overlay/Overlay";
import { Status } from "../../data/types";
import { pageShowVersionWarning } from "../../state/pageState";
import { useTranslation } from "react-i18next";
import { FeatureSwitch } from "../../components/featureSwitch/FeatureSwitch";
import { MultipleStores } from "../ContractVerification/stores/MultipleStores";
import { ContractSheetSection } from "../../components/contractSheet/ContractSheetSection";
import styles from "./Contract.module.scss";
import Intro from "./Intro/Intro";
import AdvancedPricing from "../ContractVerification/pricing/AdvancedPricing";

export const CONTRACT_PAGE_URL_REDIRECT = "/new/:linkId";
export const CONTRACT_PAGE_URL = "/:linkId";

export enum ids {
  TERMS = "terms",
  CONFIRM = "confirm",
  BANK_ACCOUNT = "bank-account",
  DOCUMENTS = "documents",
  IDENTIFICATION = "identification",
  SIGNATURE = "signature",
}

export const Contract = () => {
  const { contract, features } = useRecoilValue(contractState);
  const associates = useRecoilValue(associateState);
  const signeesAndOwners = useRecoilValue(legalAssociatesSelector);
  const signees = associates.filter(
    (associate) => associate.roles.indexOf(AssociateRole.SIGNATORY) > -1
  );
  const confirmed = useRecoilValue(confirmedState);
  const documents = useRecoilValue(documentState);
  const currentAssociate = useRecoilValue(viewerAsAssociateSelector);
  const showVersionWarning = useRecoilValue(pageShowVersionWarning);
  const terminals = contract.stores.flatMap((store) => store.terminals);
  const { t } = useTranslation();

  const reload = useCallback(() => {
    window.scrollTo(0, 0);
    window.location.reload();
  }, []);

  return (
    <ContractLoader>
      <section className="contract">
        <article>
          <Overlay
            open={showVersionWarning}
            onClose={reload}
            className="overlay-warning"
            status={Status.ERROR}
          >
            <h5>{t("Oh no!")}</h5>
            <p>
              {t(
                "This contract has been updated while you were looking at it. We will have to reload the page in order to confirm the updated contract."
              )}
            </p>
            <p>{t("Sorry about that.")}</p>

            <Button onClick={reload}>{t("Reload page")}</Button>
          </Overlay>

          <div>
            <Actions />

            <div id={ids.CONFIRM} className={styles.section_container}>
              <Intro />

              <ContractData contract={contract} associates={associates} />

              <FeatureSwitch feature={ContractFeature.ADVANCED_PRICING}>
                <FeatureSwitch.Feature>
                  <AdvancedPricing contract={contract} />
                  <MultipleStores contract={contract} />
                </FeatureSwitch.Feature>
                <FeatureSwitch.Default>
                  <Stores contract={contract} />
                </FeatureSwitch.Default>
              </FeatureSwitch>

              <PortalLogins associates={associates} />

              <BankAccount contract={contract} associates={associates} features={features} />

              <Associates associates={signeesAndOwners} />

              <FeatureSwitch feature={ContractFeature.ADVANCED_PRICING}>
                <FeatureSwitch.Feature>
                  <ContractSheetSection />
                </FeatureSwitch.Feature>
                <FeatureSwitch.Default>
                  <div />
                </FeatureSwitch.Default>
              </FeatureSwitch>

              <div className="section-wrapper">
                <SalesContact />
              </div>
              <div className="section-wrapper">
                <ConfirmContract />
              </div>
            </div>

            <Visible show={confirmed.confirmed} presence>
              <div className="merchant-todo-section">
                <Terms
                  terminals={terminals}
                  mcc={contract.contractData.mcc}
                  country={contract.contractData.country}
                  associate={currentAssociate}
                />
                {/* <AddBankAccount associate={currentAssociate} /> */}
                <DocumentsCard associate={currentAssociate} documents={documents} />
                <IdentificationCard associate={currentAssociate} />
                <Signatures associate={currentAssociate} signees={signees} />
                <ActionButton associate={currentAssociate} />
              </div>
            </Visible>
          </div>
        </article>
      </section>
    </ContractLoader>
  );
};
