import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router";
import { Beacon } from "../../components/beacon/Beacon";
import { Validity } from "../../components/icons/AssociateIcon";
import { Contract, dataMerchant } from "../../data/dataMerchant";
import { DocumentUpload } from "../ContractVerification/documents/DocumentUpload";
import { CardPageLayout } from "../../components/cardPageLayout/CardPageLayout";
import { Button } from "../../components/interactions/Buttons/Button";
import { useRecoilValue } from "recoil";
import { documentState } from "../../state/documentState";
import { CONTRACT_PAGE_URL } from "../SignableContract/Contract";
import { ContractLoader } from "../SignableContract/ContractLoader/ContractLoader";
import "./DocumentUploadPage.scss";
import { useLinkId } from "../../hooks/useLinkId";

export const DOCUMENT_UPLOAD_PAGE = "/:linkId/upload";

export const DocumentUploadPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const documents = useRecoilValue(documentState);
  const [contract, setContract] = useState<Contract>();
  const linkId = useLinkId();

  useEffect(() => {
    if (!linkId) return;
    dataMerchant.getContract(linkId).then(setContract);
  }, [linkId]);

  if (!contract) {
    return null;
  }

  const completedDocs = documents.filter((doc) => doc.fileAvailable);

  return (
    <div className="document-upload-page">
      <ContractLoader>
        <CardPageLayout>
          <div>
            <h1>{t("Upload documents")}</h1>
            <ul>
              <li>
                <div className="m-bottom-30 tablet-columns">
                  <div>
                    <div>{t("Upload statements and/or documentation")}</div>

                    <div className="todo-list-additional">
                      {completedDocs.length} / {documents.length} {t("uploaded")}
                      {completedDocs.length === documents.length && (
                        <Beacon validity={Validity.VALID} className="mini" />
                      )}
                    </div>
                  </div>
                  <div className="documents text-small">
                    {documents.map((document) => (
                      <DocumentUpload document={document} key={document.documentId} />
                    ))}
                  </div>
                </div>
              </li>
            </ul>
            <Button
              ghost={completedDocs.length < documents.length}
              onClick={() => {
                navigate(generatePath(CONTRACT_PAGE_URL, { linkId }));
              }}
            >
              {t("Return to contract")}
            </Button>
          </div>
        </CardPageLayout>
      </ContractLoader>
    </div>
  );
};
