import React from "react";
import { useTranslation } from "react-i18next";
import { Associate } from "../../../data/dataMerchant";

interface Props {
  associate: Associate;
}

export const PortalLogin: React.FunctionComponent<Props> = ({ associate }) => {
  const { t } = useTranslation();
  return (
    <div className="portal-login">
      <div className="tablet-columns">
        <div>
          <dl>
            <dt>{t("Name")}</dt>
            <dd>{`${associate.contact.firstName} ${associate.contact.lastName}`}</dd>
          </dl>
        </div>
        <div>
          <dl>
            <dt>{t("Email")}</dt>
            <dd>{associate.contact.email}</dd>
          </dl>
        </div>
      </div>
    </div>
  );
};
