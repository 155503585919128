import React, { useState, useEffect, useCallback, useMemo } from "react";
import i18n from "../../i18n";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Retry } from "../../components/retry/Retry";
import { Contract, dataMerchant } from "../../data/dataMerchant";
import { Country, defaultLanguageFromCountry, LinkId } from "../../data/models/ContractTypes";
import { Status } from "../../data/types";
import { associateState } from "../../state/associateState";
import { confirmedState } from "../../state/confirmedState";
import { contractState, defaultContract } from "../../state/contractState";
import { documentState } from "../../state/documentState";
import { pageState, ConfirmPages } from "../../state/pageState";
import { salesContactState } from "../../state/salesContactState";
import { viewerState } from "../../state/viewerState";
import { InvalidLink } from "../InvalidLink";
import { ContractInfo } from "../ContractVerification/ContractInfo";
import { ErrorBox } from "../../components/boxes/ErrorBox";
import { useTranslation } from "react-i18next";
import { Logo } from "../../components/images/Logo";
import { ContractAdditionalDocs } from "../ContractVerification/ContractAdditionalDocs";
import { getType } from "../SignableContract/ContractLoader/ContractLoader";
import "./PreviewPage.scss";

export const PREVIEW_PAGE_URL = "/preview/:id";

interface Props {
  contract?: Contract;
}

export const PreviewPageInner: React.FunctionComponent<Props> = ({ contract }) => {
  const { t } = useTranslation();
  const confirmed = useRecoilValue(confirmedState);
  const { mcc, country } = contract?.contract.contractData ?? {
    mcc: "0000",
    country: Country.POLAND,
  };

  if (!contract) {
    return null;
  }

  const terminals = contract.contract.stores.flatMap((store) => store.terminals);

  return (
    <div>
      <ErrorBox relative>
        <b>{t("This is a preview link for internal use. It should not be shared.")}</b>
      </ErrorBox>

      <div className="intro">
        <div className="logo-wrapper">
          <Logo />
        </div>
      </div>

      <hr />

      <ContractInfo />

      <ContractAdditionalDocs
        mcc={mcc}
        confirmedTerms={confirmed.confirmedTerms}
        country={country}
        linkId="preview"
        terminals={terminals}
      />

      <ErrorBox relative>
        <b>{t("This is a preview link for internal use. It should not be shared.")}</b>
      </ErrorBox>
    </div>
  );
};

export function PreviewPage() {
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [contract, setContract] = useState<Contract>();
  const setPage = useSetRecoilState(pageState);
  const setContractState = useSetRecoilState(contractState);
  const setAssociate = useSetRecoilState(associateState);
  const setConfirmed = useSetRecoilState(confirmedState);
  const setDocuments = useSetRecoilState(documentState);
  const setViewer = useSetRecoilState(viewerState);
  const setSalesContact = useSetRecoilState(salesContactState);

  const id = useMemo(() => {
    return window.location.pathname.split("/").pop();
  }, []);

  const load = useCallback(
    (idParam: any) => {
      setStatus(Status.PENDING);

      dataMerchant
        .getPreviewContract(idParam)
        .then((response) => {
          setStatus(Status.SUCCESS);
          setPage(ConfirmPages.VERIFICATION);

          setContract(response);
          setContractState({
            contract: { ...defaultContract, ...response.contract },
            linkId: idParam as LinkId,
            type: getType(response),
            hasShownContract: false,
            hasShownIntro: false,
            features: response.enabledFeatures,
          });
          setConfirmed(response.confirmedContract);
          setSalesContact(response.salesContact);
          setViewer(response.contractViewer);
          setDocuments(response.documents);
          setAssociate(response.associates);

          const viewerLanguage =
            response.contractViewer.language ||
            defaultLanguageFromCountry(response.contract.contractData.country);

          const urlSearchParams = new URLSearchParams(window.location.search);
          let lang;
          try {
            ({ lang } = Object.fromEntries(urlSearchParams.entries()));
          } catch (err) {}

          i18n.changeLanguage(lang || viewerLanguage);
        })
        .catch((err) => {
          console.log("err", err);
          setStatus(Status.ERROR);
        });
    },
    [
      setContractState,
      setPage,
      setAssociate,
      setSalesContact,
      setViewer,
      setDocuments,
      setConfirmed,
    ]
  );

  useEffect(() => {
    if (!id) {
      return;
    }

    load(id);
  }, [load, id]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    setTimeout(() => {
      load(id);
    }, 500);
  }, [load, id]);

  if (!id) {
    return <InvalidLink />;
  }

  return (
    <div className="preview-page contract">
      <section>
        <article>
          <Retry retry={retry} status={status}>
            <PreviewPageInner contract={contract} />
          </Retry>
        </article>
      </section>
    </div>
  );
}
