import React from "react";
import { MerchantContractInformation } from "../../../data/dataMerchant";
import countries from "../../../components/countries.json";
import { useTranslation } from "react-i18next";
import { Address } from "../../../components/address/Address";
import { Contact } from "../../../components/contact/Contact";
import {
  Associate as AssociateInterface,
  AssociateRole,
} from "../../../data/dataMerchant";
import "./ContractData.scss";
import { Card } from "../../../components/cards/Card";
import { Language } from "../../../data/models/ContractTypes";
import { TRANSLATION_NAMESPACE } from "../../../i18n";

interface Props {
  contract: MerchantContractInformation;
  associates: AssociateInterface[];
}

const TranslateMCC: Record<Language, boolean> = {
  [Language.POLAND]: false,
  [Language.CROATIA]: false,
  [Language.UK]: false,
  [Language.FRANCE]: false,
  [Language.CZECHIA]: true,
};

export const ContractData: React.FunctionComponent<Props> = ({
  contract,
  associates,
}) => {
  const data = contract.contractData;
  const { t, i18n } = useTranslation();
  const primary = associates.find(
    (associate) => associate.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1
  );

  const lang = i18n.language as Language;

  const mccIndustry =
    TranslateMCC[lang] && data.mccIndustry
      ? t(data.mccIndustry, { ns: TRANSLATION_NAMESPACE.MCC })
      : data.mccIndustry;

  const registrationIdName = (country: string, registrationId: string) => {
    if (country === "PL") {
      return "NIP";
    }
    if (country === "HR") {
      if (registrationId.length === 8) {
        return "MBO";
      }
      return "OIB";
    }
    return "ID";
  };

  return (
    <div className="contract-data">
      <Card header={t("Company information")} variant="v2">
        <dl className="company-information">
          <dt>{t("Company name")}</dt>
          <dd>{data.companyName}</dd>

          <dt>
            {registrationIdName(data.country, data.companyRegistrationId)}
          </dt>
          <dd>{data.companyRegistrationId}</dd>
          <dt>{t("Country")}</dt>
          <dd>{t(countries[data.country as keyof typeof countries])}</dd>
          {data.website ? (
            <>
              <dt>{t("Website")}</dt>
              <dd>
                <a href={data.website} target="_blank" rel="noreferrer">
                  {data.website}
                </a>
              </dd>{" "}
            </>
          ) : null}
          <dt>{t("Industry")}</dt>
          <dd>{mccIndustry}</dd>

          {data.vatConfirmed && (
            <>
              <dt>{t("VAT number")}</dt>
              <dd>{data.vatNumber}</dd>
            </>
          )}
        </dl>

        <div className="tablet-columns split">
          <Address
            header={t("Company address")}
            address={data.primaryAddress}
          />

          {primary && (
            <Contact header={t("Primary contact")} contact={primary.contact} />
          )}
        </div>
      </Card>
    </div>
  );
};
