import React from "react";
import cx from "classnames";
import { Status } from "../../data/types";
import { External } from "../icons/External";
import { useTranslation } from "react-i18next";

export enum FileType {
  PDF,
  IMAGE,
}

export interface IdImageInterface {
  url?: string;
  type?: FileType;
  fileName?: string;
}

interface Props {
  image: IdImageInterface;
  status: Status;
}

export const IdImage: React.FunctionComponent<Props> = ({ image, status }) => {
  const { t } = useTranslation();
  if (status === Status.ERROR) {
    return (
      <div className="id-image default-error-box">
        {t("Something went wrong. We couldn't upload the image")}
      </div>
    );
  }

  if (!image.url) {
    return (
      <div className="id-image">
        <i>{t("No file has yet been uploaded for this user")}</i>
      </div>
    );
  }

  if (image.type === FileType.PDF) {
    return (
      <div className="id-image pdf-image">
        <object
          data={image.url}
          type="application/pdf"
          width="100%"
          height="700px"
        >
          <a
            className={cx("link", "external-link")}
            href={image.url}
            target="_blank"
            rel="noreferrer"
          >
            {t("PDF-viewer not supported. Download here")}:
            <External />
          </a>
        </object>
      </div>
    );
  }

  return (
    <div className="id-image">
      <img src={image.url} alt="identification" />
    </div>
  );
};
