import { Trans, useTranslation } from "react-i18next";
import { Link } from "../../../components/link/Link";
import { Country } from "../../../data/models/ContractTypes";

import "./CashlessPromotion.scss";

interface Props {
  country: Country;
}

const CASHLESS_LINK_CZ =
  "https://www.payphone.cz/wp-content/uploads/2022/04/Podminky-nabidky-%E2%80%9ECesko-plati-kartou-100-sleva-na-12-mesicu-PayPhone.pdf";

export const CashlessPromotion = ({ country }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="cashless-promotion">
      <h5 className="section-subtitle">{t("Cashless payments")}</h5>
      <p style={{ margin: 0 }}>
        {country === Country.CZECHIA ? (
          <Trans>
            I have applied to the government program and benefit from 100% discount on fees from
            transactions made via the PayPhone application up to CZK 50,000 (EUR 2,000) per month
            for 12 months. I agree with the{" "}
            <Link link={CASHLESS_LINK_CZ} external>
              conditions of the special offer
            </Link>
            .
          </Trans>
        ) : (
          <Trans>I have applied to the government program.</Trans>
        )}
      </p>
    </div>
  );
};
