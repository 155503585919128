import { FC } from "react";
import styles from "./PomcaSummary.module.scss";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { Store } from "../../../../data/dataMerchant";
import { Language, Country } from "../../../../data/models/ContractTypes";
import { Pomca } from "../../../../data/models/PricingTypes";
import { contractState } from "../../../../state/contractState";
import { CURRENCY_BY_COUNTRY } from "../../../../i18n";
import { getIntlCost } from "../../../../components/utils";

interface Props {
  pomca: Pomca[];
  country: Country;
}

type storePomca = {
  store: Store;
  terminalAmount: number;
  fee: number;
};

const PomcaSummary: FC<Props> = ({ pomca, country }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as Language;

  const { contract } = useRecoilValue(contractState);
  const { stores } = contract;

  const storePomcas = groupStoresAndPomca(stores, pomca);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <h5>{t("POMCA summary")}</h5>
        </div>

        {storePomcas.map((storePomca, index) => (
          <dl className={styles.storePomca}>
            <dt>{t("Location")}</dt>
            <dd>{storePomca.store.commercialName}</dd>
            <dt>{t("Terminals")}</dt>
            <dd>{storePomca.terminalAmount}</dd>
            <dt>{t("Monthly fee")}</dt>
            <dd>{getIntlCost(language, storePomca.fee, CURRENCY_BY_COUNTRY[country])}</dd>
          </dl>
        ))}

        <div className={styles.footer}>
          <div className={styles.total}>{t("Total minimum monthly fee")}:</div>
          <div className={styles.fee}>
            {getIntlCost(language, calculateTotalFee(storePomcas), CURRENCY_BY_COUNTRY[country])}
          </div>
        </div>
      </div>
    </>
  );
};

const calculateMonthlyFee = (terminalAmount: number, pomca: Pomca[]): number => {
  const found = pomca.find((fee) => fee.terminalAmount === terminalAmount);
  return found?.fee ?? 0;
};

const groupStoresAndPomca = (stores: Store[], pomca: Pomca[]): storePomca[] => {
  return stores.map((store) => {
    const terminalAmount = store.terminals.length;
    const fee = calculateMonthlyFee(terminalAmount, pomca);
    return { store: store, terminalAmount, fee };
  });
};

const calculateTotalFee = (storePomcas: storePomca[]) => {
  return storePomcas.reduce((acc, curr) => acc + curr.fee, 0);
};

export default PomcaSummary;
