import React from "react";
import {
  ContractPackage,
  MerchantContractInformation,
  Store as StoreInterface,
} from "../../../data/dataMerchant";
import { useTranslation } from "react-i18next";
import { Card } from "../../../components/cards/Card";
import { PricingHR } from "./PricingHR";
import { PricingPL } from "./PricingPL";
import { Store } from "./Store/Store";
import { Country } from "../../../data/models/ContractTypes";
import { PricingCZ } from "./PricingCZ";

interface Props {
  contract: MerchantContractInformation;
}

function getPricing(
  contractPackage: ContractPackage,
  stores: StoreInterface[],
  country: Country
) {
  if (country === Country.POLAND) {
    return <PricingPL contractPackage={contractPackage} stores={stores} />;
  }

  if (country === Country.CZECHIA) {
    return <PricingCZ contractPackage={contractPackage} stores={stores} />;
  }

  return <PricingHR contractPackage={contractPackage} stores={stores} />;
}

export const Stores: React.FunctionComponent<Props> = ({ contract }) => {
  const { stores, contractPackage } = contract;
  const { mcc, country } = contract.contractData;
  const { t } = useTranslation();

  return (
    <div className="contract-package">
      <Card header={t("Pricing and location")}>
        {getPricing(contractPackage, stores, country)}
        {stores.map((store, idx) => (
          <div className="m-top-40" key={`${store.commercialName}${idx}`}>
            <Store store={store} mcc={mcc} />
          </div>
        ))}
      </Card>
    </div>
  );
};
