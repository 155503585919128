import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { AssociateRole } from "../../../data/dataMerchant";
import { associateState } from "../../../state/associateState";
import { confirmedState } from "../../../state/confirmedState";
import { contractState } from "../../../state/contractState";
import { salesContactState } from "../../../state/salesContactState";
import "./SalesContact.scss";
import { TI } from "../../../data/models/CommonTypes";

export function SalesContact() {
  const salesContact = useRecoilValue(salesContactState);
  const associates = useRecoilValue(associateState);
  const primary = associates.find((item) => item.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1);
  const primaryName = `${primary?.contact.firstName} ${primary?.contact.lastName}`;
  const { confirmed } = useRecoilValue(confirmedState);
  const { t } = useTranslation();
  const { email, name, phoneNumber } = salesContact;
  const mailTo = `mailto:${salesContact.email}`;
  const tel = `tel:${salesContact.phoneNumber}`;
  const [isConfirmed, setIsConfirmed] = useState<boolean>();
  const { linkId } = useRecoilValue(contractState);

  useEffect(() => {
    if (!linkId) {
      return;
    }
    // Okey, now contract is loaded

    // Allow for confirmed to be set once only
    if (isConfirmed !== undefined) {
      return;
    }

    setIsConfirmed(confirmed);
  }, [linkId, confirmed, isConfirmed]);

  if (!linkId) {
    return null;
  }

  if (isConfirmed) {
    return (
      <div className="sales-contact">
        <p className="m-bottom-10">
          <Trans>
            The contract details have been confirmed by <strong>{{ primaryName } as TI}</strong>.
          </Trans>
          {/* {t(`The contract details have been confirmed by {{primaryName}}. `, {
          primaryName,
        })} */}{" "}
          <Trans>If you have any questions, please contact your sales representative</Trans>
        </p>
        <div className="sales-rep-info">
          <strong>{name}</strong>
          <dl className="sales-contact-flex">
            <dt>{t("Email")}:</dt>
            <dd className="truncate">
              <a href={mailTo}>{email}</a>
            </dd>
            {salesContact.phoneNumber && (
              <>
                <dt>{t("Phone")}:</dt>
                <dd className="truncate">
                  <a href={tel}>{phoneNumber}</a>
                </dd>
              </>
            )}
          </dl>
        </div>
      </div>
    );
  }

  return (
    <div className="sales-contact">
      <div className="m-bottom-10">
        {t("If the information is incorrect, please contact your sales representative")}:
      </div>
      <div className="sales-rep-info ">
        <strong>{name}</strong>
        <dl className="sales-contact-flex">
          <dt>{t("Email")}:</dt>
          <dd className="truncate">
            <a href={mailTo}>{email}</a>
          </dd>
          {salesContact.phoneNumber && (
            <>
              <dt>{t("Phone")}:</dt>
              <dd className="truncate">
                <a href={tel}>{phoneNumber}</a>
              </dd>
            </>
          )}
        </dl>
      </div>
    </div>
  );
}
