import { LinkId } from "../../../../data/models/ContractTypes";
import { SelfieUploadPhone } from "./SelfieUploadPhone";

export const SELFIE_UPLOAD_URL = "/:linkId/identification/selfie";

export type SelfieUploadParams = {
  linkId: LinkId;
};

export const SelfieUpload = () => {
  return <SelfieUploadPhone />;
};
