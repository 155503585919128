import React from "react";
import { useTranslation } from "react-i18next";
import { Contact as ContactInterface } from "../../data/models/ContractTypes";

interface Props {
  contact: ContactInterface;
  header?: string;
  className?: string;
}

export const Contact: React.FunctionComponent<Props> = ({ contact, header, className }) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <b className="section-subtitle">{header || t("Contact")}</b>
      <dl>
        <dt>{t("Name")}</dt>
        <dd>{`${contact.firstName} ${contact.lastName}`}</dd>
        <dt>{t("Email")}</dt>
        <dd>{contact.email}</dd>
        <dt>{t("Phone")}</dt>
        <dd>{contact.phoneNumber ?? "-"}</dd>
      </dl>
    </div>
  );
};
