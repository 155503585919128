import React, { ReactNode } from "react";
import cx from "classnames";
import "./Visible.scss";
import { AnimatePresence } from "framer-motion";
import FadeAnimation from "../animate/FadeAnimation";

interface Props {
  show: boolean;
  children: ReactNode;
  presence?: boolean;
}

export const Visible: React.FunctionComponent<Props> = ({
  children,
  show,
  presence = false,
}) => {
  if (presence) {
    return (
      <AnimatePresence>
        <FadeAnimation layout open={show}>
          {children}
        </FadeAnimation>
      </AnimatePresence>
    );
  } else {
    return <div className={cx("visible-component", { show })}>{children}</div>;
  }
};
