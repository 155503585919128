import React, { VoidFunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Modal } from "../../../../components/modal/Modal";
import { MdError } from "react-icons/md";
import "./CameraError.scss";

interface Props {
  error?: string | DOMException;
  retry: () => void;
}

export const CameraError: VoidFunctionComponent<Props> = ({ error, retry }) => {
  const { t } = useTranslation();

  let errorMessage = t("An error occured while accessing the camera");

  if (typeof error === "string") {
    console.error(error);
  } else if (
    error?.name === "NotFoundError" ||
    error?.name === "DevicesNotFoundError"
  ) {
    // required track is missing
    console.error("Required track is missing");
  } else if (
    error?.name === "NotReadableError" ||
    error?.name === "TrackStartError"
  ) {
    // webcam or mic are already in use
    console.error("Webcam or mic are already in use");
    errorMessage = t("Webcam is already in use");
  } else if (
    error?.name === "OverconstrainedError" ||
    error?.name === "ConstraintNotSatisfiedError"
  ) {
    // constraints can not be satisfied by avb. devices
    console.error("Constraints can not be satisfied by available devices");
  } else if (
    error?.name === "NotAllowedError" ||
    error?.name === "PermissionDeniedError"
  ) {
    // permission denied in browser
    console.log("Permission Denied.");
    errorMessage = t(
      "Camera permission was denied. This feature will not work unless you provide access to your devices camera"
    );
  } else if (error?.name === "TypeError" || error?.name === "TypeError") {
    // empty constraints object
    console.log("Both audio and video are FALSE");
  } else if (error !== undefined) {
    // other errors
    console.error(error);
    console.log("Sorry! Another error occurred.");
  }

  return (
    <div className="camera-error-modal">
      <Modal showModal={error !== undefined} closeOnOverlayClick={false}>
        <div className="camera-error-modal-content">
          <h2>
            <MdError size={32} color={"var(--color-red-heavy)"} />
            <Trans>Camera access failed</Trans>
          </h2>
          <p>{errorMessage}</p>
          <Button onClick={retry}>
            <Trans>Try again</Trans>
          </Button>
        </div>
      </Modal>
    </div>
  );
};
