import React from "react";
import styles from "./ContractHeader.module.scss";
import { Logo } from "../../../components/images/Logo";
import { LangSelector } from "./LangSelector";

const ContractHeader = () => {
  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <Logo />
      </div>

      <LangSelector />
    </div>
  );
};

export default ContractHeader;
