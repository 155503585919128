import React, { VoidFunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Modal } from "../../../../components/modal/Modal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SelfieUploadModal: VoidFunctionComponent<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal showModal={isOpen} closeOnOverlayClick={true} onClose={onClose}>
      <div className="id-upload-modal-content">
        <div className="m-bottom-30">
          <h2 className="m-bottom-10">{t("Selfie")}</h2>
          <p>
            {t(
              "To verify your identify we need a selfie of you. It's important that you're in a well lit room and that the picture is clear and in focus."
            )}
          </p>
        </div>
      </div>
      <Button onClick={onClose} block>
        {t("Continue")}
      </Button>
    </Modal>
  );
};
