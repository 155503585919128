import React from "react";
import cx from "classnames";
import { Checkmark } from "../icons/Checkmark";
import "./CheckmarkTag.scss";

interface Props {
  animated?: boolean;
  active?: boolean;
}

export const CheckmarkTag: React.FunctionComponent<Props> = ({
  animated = false,
  active = false,
}) => {
  return (
    <div
      className={cx("image-checkmark-wrapper", {
        animated,
        active,
      })}
    >
      <div>
        <div className="triangle" />
        <Checkmark />
      </div>
    </div>
  );
};
