import { API } from "../network/API";
import { Country } from "./models/CommonTypes";
import { ContractId, TerminalType } from "./models/ContractTypes";

export const BASE_URL = "/api/sales/contracts/terminal-prices";

export interface TerminalPriceDetails {
  terminalType: TerminalType;
  availableMonthlyCosts: number[];
  oneTimeCost: number;
  defaultMonthlyCost: number;
  defaultOneTimeCost: number;
}

export interface TerminalPricingByCountries {
  [Country.CROATIA]: TerminalPriceDetails[];
  [Country.POLAND]: TerminalPriceDetails[];
  [Country.CZECHIA]: TerminalPriceDetails[];
}

export interface TerminalPricingResponse {
  terminalType: TerminalType;
  monthlyCost: number;
  oneTimeCost: number;
}

export type TerminalPricing = Omit<TerminalPricingResponse, "terminalType">;

export interface TerminalPricingState {
  [TerminalType.MOVE_3500]: TerminalPricing;
  [TerminalType.SOFT_POS]: TerminalPricing;
  [TerminalType.DESK_3500]: TerminalPricing;
}

// TODO: create different default state depending on country
export const DEFAULT_TERMINAL_PRICING_STATE: TerminalPricingState = {
  [TerminalType.DESK_3500]: {
    monthlyCost: 35,
    oneTimeCost: 0,
  },
  [TerminalType.MOVE_3500]: {
    monthlyCost: 39,
    oneTimeCost: 0,
  },
  [TerminalType.SOFT_POS]: {
    monthlyCost: 5,
    oneTimeCost: 0,
  },
};

export const OLD_TERMINAL_PRICES = {
  [TerminalType.DESK_3500]: {
    monthlyCost: 39,
    oneTimeCost: 0,
  },
  [TerminalType.MOVE_3500]: {
    monthlyCost: 45,
    oneTimeCost: 0,
  },
  [TerminalType.SOFT_POS]: {
    monthlyCost: 6.75,
    oneTimeCost: 0,
  },
};

// modify later if the defaults are different for countries
export const getDefaultTerminalPricing = (
  country: Country
): TerminalPricingState => {
  switch (country) {
    case Country.POLAND:
      return DEFAULT_TERMINAL_PRICING_STATE;
    case Country.CROATIA:
      return DEFAULT_TERMINAL_PRICING_STATE;
    case Country.CZECHIA:
      return DEFAULT_TERMINAL_PRICING_STATE;
    default:
      return DEFAULT_TERMINAL_PRICING_STATE;
  }
};

export const dataTerminalPricing = {
  getContractTerminalPrices: (contractId: ContractId) =>
    API.get<TerminalPricingResponse[]>(
      `${BASE_URL}/contract-id/${contractId}`,
      true
    ),
};
