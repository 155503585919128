import { API } from "../network/API";
import { cache } from "../network/Cache";
import { ContractPricing } from "./models/PricingTypes";
import {
  Address,
  AssociateId,
  Contact,
  ContractDocument,
  ContractId,
  ContractVersion,
  DocumentId,
  Language,
  LinkId,
  NO_COUNTRY,
  UTCDate,
  Country,
  PackageId,
  TerminalId,
  TerminalType,
} from "./models/ContractTypes";

export const VERSION_ERROR_CODE = 409;

export interface MerchantContractConfirmation {
  confirmedTerms: string;
  confirmed: boolean;
  pendingSalesConfirmation: boolean;
  contractComplete: boolean;
  sentForConfirmation: UTCDate;
  confirmedTime?: UTCDate;
  confirmedBy?: string;
}

export interface SalesContact {
  name: string;
  email: string;
  phoneNumber?: string;
}

export enum BankAccountSource {
  BANK_STATEMENT = "BANK_STATEMENT",
  OFFICIAL_REGISTRY = "OFFICIAL_REGISTRY",
  PSD_LOOKUP = "PSD_LOOKUP",
}

export interface BankAccount {
  iban?: string;
  bank?: string;
  bic?: string;
  accountHolder?: string;
  signedOff?: UTCDate;
  statementUploaded?: UTCDate;
  fileName?: string;
  source?: BankAccountSource;
}

export interface ContractData {
  version: ContractVersion;
  country: Country;
  companyName: string;
  companyType?: string;
  companyRegistrationId: string;
  cardholderStatementText: string;
  countryOfOrigin?: Country;
  primaryAddress: Address;
  website?: string;
  mcc: string;
  mccIndustry: string;
  vatNumber?: string;
  vatConfirmed: boolean;
  companyRegistrationConfirmed: boolean;
  contractId: ContractId;
}

export enum PricingStep {
  LIGHT = "LIGHT",
  STANDARD = "STANDARD",
  ADVANCED = "ADVANCED",
}

export interface ContractPackage {
  packageId: PackageId;
  pricingStep: PricingStep;
  promotionMonths: number;
  cashlessPromotion: boolean;
}

enum PriceType {
  NONE = "NONE",
  ONE_TIME = "ONE_TIME",
  PER_MONTH = "PER_MONTH",
  PER_TRANSACTION = "PER_TRANSACTION",
}

export enum ArticleName {
  INSTALLATION_PLUG_AND_PLAY = "INSTALLATION_PLUG_AND_PLAY",
  SIM_CARD = "SIM_CARD",
}

export interface Article {
  article: ArticleName;
  articleNo: string;
  articleDescription: string;
  priceType: PriceType;
  price: number | undefined;
}

export interface Terminal {
  id: TerminalId;
  terminalType: TerminalType;
  terminalClosure: string;
  tip: boolean;
  bookAfterReservation: boolean;
  articles?: Article[];
}

export interface Store {
  commercialName: string;
  address: Address;
  contact: Contact;
  terminals: Terminal[];
}

export interface PortalLogin {
  email: string;
  name: string;
}

export interface MerchantContractInformation {
  contractData: ContractData;
  contractPackage: ContractPackage;
  contractPricing: ContractPricing[];
  stores: Store[];
  portalLogins?: PortalLogin[];
  bankAccount: BankAccount;
}

export enum AssociateRole {
  PRIMARY_CONTACT = "PRIMARY_CONTACT",
  ACCOUNT_HOLDER = "ACCOUNT_HOLDER",
  SIGNATORY = "SIGNATORY",
  BENEFICIAL_OWNER = "BENEFICIAL_OWNER",
  PORTAL_LOGIN = "PORTAL_LOGIN",
  STORE_CONTACT = "STORE_CONTACT",
}

export interface AssociateOwner {
  designatedOwner: boolean;
  designatedFunction?: string;
  capitalStake: number; //0.00 -> 100.00
  votingRightsStake: number; //0.00 -> 100.00
  hasInderectOwership: boolean;
  companyName?: string;
  ompanyOwnershipStake?: number; //0.00 -> 100.00,
  companyStreet?: string;
  companyPostalCode?: string;
  companyCity?: string;
  companyCountry?: string;
}

export interface AssociateSignatory {
  signed?: UTCDate;
  signature?: string;
}

export const defaultAddress: Address = {
  street: "",
  streetNumber: "",
  postalCode: "",
  countryCode: NO_COUNTRY,
  city: "",
};

export interface Associate {
  associateId: AssociateId;
  roles: AssociateRole[];
  signedOff: UTCDate;
  contractViewed: UTCDate;
  address?: Address;
  contact: Contact;
  owner?: AssociateOwner;
  signatory?: AssociateSignatory;
  documents: {
    idCard: ContractDocument;
  };
  remoteIdentity?: {
    selfieUploaded?: string;
    idDocumentationUploaded?: string;
  };
}

export interface AssociateContractViewer {
  name: string;
  email: string;
  phoneNumber?: string;
  showConfirmation: boolean; //Är den här true så ska användaren få se Bekräfta kontraktsuppgifter, den blir false när kontraktet är bekräftat eller om det är en användare som inte kan / får bekräfta
  showUploadCompanyDocuments: boolean;
  associateId: AssociateId;
  language: Language;
}

export enum ContractFeature {
  KLARNA = "KLARNA",
  ADVANCED_PRICING = "ADVANCED_PRICING",
}

export interface Contract {
  enabledFeatures: ContractFeature[];
  confirmedContract: MerchantContractConfirmation;
  contractViewer: AssociateContractViewer;
  salesContact: SalesContact;
  contract: MerchantContractInformation;
  associates: Associate[];
  documents: ContractDocument[];
}

export enum IdDocumentType {
  PASSPORT = "passport",
  ID_CARD = "idCard",
}

export const dataMerchant = {
  getContract: (linkId: LinkId) => API.get<Contract>(`/api/merchant/${linkId}`),

  getPreviewContract: (contractId: ContractId) =>
    API.get<Contract>(`/api/merchant/preview/${contractId}`),

  confirmContract: (linkId: LinkId, version: ContractVersion) =>
    API.post(`/api/merchant/${linkId}/confirm`, { version: version }).then((resp) => {
      cache.delete(`/api/merchant/${linkId}`);
      return resp;
    }),

  deleteUploadedDocument: (linkId: LinkId, documentId: DocumentId) =>
    API.delete(`/api/merchant/${linkId}/documents/${documentId}`).then((resp) => {
      cache.delete(`/api/merchant/${linkId}`);
      return resp;
    }),

  uploadSignature: (linkId: LinkId, signature: string) =>
    API.post(`/api/merchant/${linkId}/signature`, {
      signature: signature,
    }).then((resp) => {
      cache.delete(`/api/merchant/${linkId}`);
      return resp;
    }),

  sendDeviceSwitchLink: (linkId: LinkId, phoneNumber: string) =>
    API.post(`/api/merchant/identity/${linkId}/send-link`, {
      phoneNumber,
    }).then((resp) => {
      cache.delete(`/api/merchant/${linkId}`);
      return resp;
    }),
};
