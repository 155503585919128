import { Country, TerminalType } from "../../../../data/models/ContractTypes";
import { useTranslation } from "react-i18next";
import {
  getTerminalImageData,
  getTerminalTypeString,
} from "../../../../components/utils";
import styles from "./TerminalPreview.module.scss";
import { Image } from "../../../../components/images/Image";

interface Props {
  type: TerminalType;
  count: number;
  country: Country;
  advancedPricing?: boolean;
}

interface TerminalOption {
  name: string;
  src: string;
  ratio: number;
  alt: string;
}

export const TerminalPreview = ({ type, count, country }: Props) => {
  const { t } = useTranslation();

  // Fugly replace of softPos with payPhone if country is CZ
  let terminalOption: TerminalOption;

  if (country === Country.CZECHIA && type === TerminalType.SOFT_POS) {
    terminalOption = PAY_PHONE_OFFERING;
  } else {
    terminalOption = TERMINAL_OFFERINGS[type];
  }

  return (
    <div className={styles.root}>
      <div className={styles.image_wrapper}>
        <Image
          src={terminalOption.src}
          alt={terminalOption.alt}
          ratio={terminalOption.ratio}
        />
      </div>
      <div className={styles.data}>
        <div className={styles.terminal_name}>
          <span>{t(terminalOption.name)}</span>
        </div>
        <span className={styles.terminal_amount}>&#215; {count}</span>
      </div>
    </div>
  );
};

const TERMINAL_OFFERINGS: Record<TerminalType, TerminalOption> = {
  [TerminalType.SOFT_POS]: {
    name: `${getTerminalTypeString(TerminalType.SOFT_POS)} account`,
    ...getTerminalImageData(TerminalType.SOFT_POS),
  },
  [TerminalType.DESK_3500]: {
    name: getTerminalTypeString(TerminalType.DESK_3500),
    ...getTerminalImageData(TerminalType.DESK_3500),
  },
  [TerminalType.MOVE_3500]: {
    name: getTerminalTypeString(TerminalType.MOVE_3500),
    ...getTerminalImageData(TerminalType.MOVE_3500),
  },
  [TerminalType.MOVE_5000]: {
    name: getTerminalTypeString(TerminalType.MOVE_5000),
    ...getTerminalImageData(TerminalType.MOVE_5000),
  },
  [TerminalType.DESK_5000]: {
    name: getTerminalTypeString(TerminalType.DESK_5000),
    ...getTerminalImageData(TerminalType.DESK_5000),
  },
  [TerminalType.LINK_2500]: {
    name: getTerminalTypeString(TerminalType.LINK_2500),
    ...getTerminalImageData(TerminalType.LINK_2500),
  },
  [TerminalType.SATURN]: {
    name: getTerminalTypeString(TerminalType.SATURN),
    ...getTerminalImageData(TerminalType.SATURN),
  },
  [TerminalType.DX_8000]: {
    name: getTerminalTypeString(TerminalType.DX_8000),
    ...getTerminalImageData(TerminalType.DX_8000),
  },
};

const PAY_PHONE_OFFERING: TerminalOption = {
  name: "PayPhone account",
  src: "/images/terminals/payphone_237x483.png",
  ratio: 237 / 483,
  alt: "Payphone terminal",
};
