import React, { ChangeEvent, useCallback, VoidFunctionComponent } from "react";
import "./TextInput.scss";

interface Props {
  label: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}

export const TextInput: VoidFunctionComponent<Props> = ({
  value,
  onChange,
  label,
  placeholder,
}) => {
  const innerOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <div className="text-input">
      <label>{label}</label>
      <input
        type="text"
        value={value}
        onChange={innerOnChange}
        placeholder={placeholder}
      />
    </div>
  );
};
