import React, { FunctionComponent, PropsWithChildren } from "react";
import { Logo } from "../images/Logo";
import "./CardPageLayout.scss";

export const CardPageLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <div className="card-page-layout">
      <div className="top-logo-wrapper">
        <Logo />
      </div>
      <div className="child-wrapper">{children}</div>
    </div>
  );
};
