import { AliPay } from "../../components/logos/AliPay";
import { BlikLogo } from "../../components/logos/BlikLogo";
import { Diners } from "../../components/logos/Diners";
import { JCB } from "../../components/logos/JCB";
import { Maestro } from "../../components/logos/Maestro";
import { Mastercard } from "../../components/logos/Mastercard";
import { Union } from "../../components/logos/Union";
import { VPay } from "../../components/logos/VPay";
import { Visa } from "../../components/logos/Visa";
import { Country } from "./CommonTypes";
import { ContractType, TerminalType } from "./ContractTypes";

export enum PricingModel {
  BLENDED = "BLENDED",
  ICPP = "ICPP",
  PACK = "PACK",
}

export enum PricingView {
  INDIVIDUAL = "individual",
  GROUP = "group",
}

export enum FeeType {
  FIXED = "fixedFee",
  TRANSACTION = "transactionFee",
  MIN = "minFee",
  MAX = "maxFee",
}

export enum Brand {
  VISA_DEBIT = "VISA_DEBIT",
  VISA_CREDIT = "VISA_CREDIT",
  VPAY = "VPAY",
  MASTERCARD_DEBIT = "MASTERCARD_DEBIT",
  MASTERCARD_CREDIT = "MASTERCARD_CREDIT",
  MAESTRO = "MAESTRO",
  DINERS = "DINERS",
  UNION = "UNION",
  JCB = "JCB",
  ALIPAY = "ALIPAY",
  BLIK = "BLIK",
}

export const brandArray: Brand[] = Object.values(Brand);

export interface PricingStep {
  fromThreshold: number;
  toThreshold: number;
  transactionFee: number;
}

export interface SteppedBasedPricing {
  cashlessPromotionPricing: PricingStep[] | undefined;
  regularPricing: PricingStep[];
}

export interface Pomca {
  terminalAmount: number;
  fee: number;
}

export interface ContractPricing {
  contractType: ContractType;
  cas: number;
  templateId: number;
  pricingModel: PricingModel;
  customized: boolean;
  dcc: number;
  promotionMonths: number;
  cashlessPromotion: boolean;
  transactionFees: TransactionFee[];
  view: PricingView;
  chargebackFee: number;
  surchargesCommercialCards: number;
  surchargesNonEea: number;
  preAuthorizationFees: boolean;

  terminalType?: TerminalType;
  monthlyAcquiringFee?: number;
  monthlyTurnoverThreshold?: number;

  name: string;

  steppedBasedPricing: SteppedBasedPricing;
  simplePricing: boolean;

  installationFee?: number;
  cashBack?: number;
  acceptance: boolean;

  pomca?: Pomca[];
}

export interface TransactionFee {
  brand: Brand;
  [FeeType.MIN]: number;
  [FeeType.FIXED]: number;
  [FeeType.TRANSACTION]: number;
  [FeeType.MAX]?: number;
}

type BrandData = {
  logo: React.FunctionComponent;
  label: string;
  type?: string;
  disabled?: boolean;
  brand: Brand;
};

const visaCreditData: BrandData = {
  brand: Brand.VISA_CREDIT,
  logo: Visa,
  type: "Credit",
  label: "Visa",
};

const visaDebitData = {
  brand: Brand.VISA_DEBIT,
  logo: Visa,
  type: "Debit",
  label: "Visa Debit",
};

const mastercardCreditData = {
  brand: Brand.MASTERCARD_CREDIT,
  logo: Mastercard,
  type: "Credit",
  label: "Mastercard",
};

const mastercardDebitData = {
  brand: Brand.MASTERCARD_DEBIT,
  logo: Mastercard,
  type: "Debit",
  label: "Mastercard Debit",
};

const unionData = {
  brand: Brand.UNION,
  logo: Union,
  label: "UnionPay",
};

const dinersData = {
  brand: Brand.DINERS,
  logo: Diners,
  label: "Diners",
};

const jcbData = {
  brand: Brand.JCB,
  logo: JCB,
  label: "eftpos",
};

const vpayData = {
  brand: Brand.VPAY,
  logo: VPay,
  label: "VPay",
};

const maestroData = {
  brand: Brand.MAESTRO,
  logo: Maestro,
  label: "Maestro",
};

const blinkData: BrandData = {
  brand: Brand.BLIK,
  logo: BlikLogo,
  label: "BLIK",
};

const alipayData: BrandData = {
  brand: Brand.ALIPAY,
  logo: AliPay,
  label: "Alipay",
};

const DEFAULT_BRAND_MAP: BrandData[] = [
  visaCreditData,
  visaDebitData,
  mastercardCreditData,
  mastercardDebitData,
  unionData,
  dinersData,
  jcbData,
  vpayData,
  maestroData,
];

const getCountrySpecifcBrandData = (country: Country): BrandData[] => {
  if (country === Country.POLAND) return [blinkData];
  return [];
};

export const getBrandData = (country: Country): BrandData[] => {
  const baseBrand = [...DEFAULT_BRAND_MAP];
  const extraBrand = getCountrySpecifcBrandData(country);
  return [...baseBrand, ...extraBrand];
};

export const BRAND_DATA_MAP: Record<Brand, BrandData> = {
  [Brand.VISA_CREDIT]: visaCreditData,
  [Brand.VISA_DEBIT]: visaDebitData,
  [Brand.MASTERCARD_CREDIT]: mastercardCreditData,
  [Brand.MASTERCARD_DEBIT]: mastercardDebitData,
  [Brand.UNION]: unionData,
  [Brand.DINERS]: dinersData,
  [Brand.JCB]: jcbData,
  [Brand.VPAY]: vpayData,
  [Brand.MAESTRO]: maestroData,
  [Brand.BLIK]: blinkData,
  [Brand.ALIPAY]: alipayData,
};
