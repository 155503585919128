import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { API } from "./network/API";
import { Country, Currency, Language } from "./data/models/ContractTypes";

const SENT_CACHE = new Set<string>();

export enum TRANSLATION_NAMESPACE {
  SALES = "sales",
  MERCHANT = "merchant",
  MCC = "mcc",
}

export const DEFAULT_TRANSLATION_NAMESPACE = TRANSLATION_NAMESPACE.MERCHANT;

export const CURRENCY_BY_COUNTRY: Record<Country, Currency> = {
  [Country.CZECHIA]: Currency.CZECHIA,
  [Country.POLAND]: Currency.POLAND,
  [Country.CROATIA]: Currency.CROATIA,
};

const HttpApi = new Backend(null, {
  loadPath: API.getUrl("/api/translation/{{lng}}/{{ns}}.json"),
  allowMultiLoading: false,
  crossDomain: true,
  withCredentials: true,
  requestOptions: {
    mode: "cors",
    credentials: "include",
  },
});

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns: [DEFAULT_TRANSLATION_NAMESPACE, TRANSLATION_NAMESPACE.MCC],
    defaultNS: DEFAULT_TRANSLATION_NAMESPACE,
    debug: false,
    fallbackLng: false,
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    saveMissingTo: "current",
    missingKeyHandler: (
      lng: any,
      ns: any,
      key: any,
      fallbackValue: any,
      updateMissing: any,
      options: any
    ): any => {
      const language = lng[0];

      if (language === "en") {
        return;
      }

      const cacheKey = language + ns + key;
      if (SENT_CACHE.has(cacheKey)) {
        return;
      }
      SENT_CACHE.add(cacheKey);
      fetch(API.getUrl(`/api/translation/${lng}/${ns}.json`), {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key,
          site: DEFAULT_TRANSLATION_NAMESPACE,
        }),
      }).catch(() => {
        console.warn("Couldnt send missing translation [" + key + "]");
      });
    },
  });

export function getCountryByLanguage(lang: Language) {
  switch (lang) {
    case Language.CROATIA:
      return Country.CROATIA;
    case Language.POLAND:
      return Country.POLAND;
    case Language.CZECHIA:
      return Country.CZECHIA;
    default:
      return "gb";
  }
}

export function getLanguageFromCountry(country: Country) {
  switch (country) {
    case Country.CROATIA:
      return Language.CROATIA;
    case Country.POLAND:
      return Language.POLAND;
    case Country.CZECHIA:
      return Language.CZECHIA;
    default:
      return Language.UK;
  }
}

export default i18n;
