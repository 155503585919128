import { Suspense, useEffect, useRef } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PreviewPage, PREVIEW_PAGE_URL } from "./pages/Preview/PreviewPage";
import { useTranslation } from "react-i18next";
import { Button } from "./components/interactions/Buttons/Button";
import { KlarnaTestPage } from "./pages/Klarna/KlarnaTestPage";
import {
  SelfieUpload,
  SELFIE_UPLOAD_URL,
} from "./pages/SignableContract/Identification/SelfieUpload/SelfieUpload";

import {
  DeviceSelection,
  DEVICE_SELECTION_URL,
} from "./pages/SignableContract/Identification/DeviceSelection/DeviceSelection";
import {
  IdentificationSelection,
  ID_SELECTION_URL,
} from "./pages/SignableContract/Identification/IdentificationSelection/IdentificationSelection";
import {
  ID_UPLOAD_URL,
  DocumentUpload,
} from "./pages/SignableContract/Identification/DocumentUpload/DocumentUpload";
import {
  DocumentUploadPage,
  DOCUMENT_UPLOAD_PAGE,
} from "./pages/DocumentUpload/DocumentUploadPage";
import {
  Contract,
  CONTRACT_PAGE_URL,
  CONTRACT_PAGE_URL_REDIRECT,
} from "./pages/SignableContract/Contract";
import { Done, DONE_PAGE_URL } from "./pages/SignableContract/Done/Done";
import {
  AccountSelection,
  ACCOUNT_SELECTION_URL,
} from "./pages/SignableContract/AddBankAccount/AccountSelection/AccountSelection";
import { Pending } from "./components/icons/Pending";
import { useRecoilValue } from "recoil";
import { contractState } from "./state/contractState";
import { Country } from "./data/models/ContractTypes";
import { ErrorBoundary } from "@sentry/react";

function FallbackComponent() {
  const { t } = useTranslation();

  return (
    <section>
      <article>
        <h5>{t("Oh no!")}</h5>
        <p>{t("Something went wrong. Sorry about that.")}</p>
        <Button onClick={() => window.location.reload()}>
          {t("Reload page")}
        </Button>
      </article>
    </section>
  );
}

const myFallback = <FallbackComponent />;

function App() {
  const { contract } = useRecoilValue(contractState);
  const prevCountry = useRef<Country>(Country.POLAND);

  useEffect(() => {
    const { country } = contract.contractData;
    if (!country) {
      return;
    }

    // cover React Portal
    document.body.classList.remove(prevCountry.current);
    prevCountry.current = country;
    document.body.classList.add(country);
  }, [contract.contractData]);

  return (
    <div className="app">
      <ErrorBoundary fallback={myFallback} showDialog={false}>
        <Suspense
          fallback={
            <div style={{ margin: "100px auto" }}>
              <section>
                <article>
                  Loading <Pending />
                </article>
              </section>
            </div>
          }
        >
          <Routes>
            <Route path="/klarna" element={<KlarnaTestPage />} />
            <Route path={PREVIEW_PAGE_URL} element={<PreviewPage />}></Route>
            <Route path={DEVICE_SELECTION_URL} element={<DeviceSelection />} />
            <Route
              path={ACCOUNT_SELECTION_URL}
              element={<AccountSelection />}
            />

            <Route path={SELFIE_UPLOAD_URL} element={<SelfieUpload />} />
            <Route
              path={ID_SELECTION_URL}
              element={<IdentificationSelection />}
            />
            <Route path={ID_UPLOAD_URL} element={<DocumentUpload />} />
            <Route
              path={DOCUMENT_UPLOAD_PAGE}
              element={<DocumentUploadPage />}
            />
            <Route path={DONE_PAGE_URL} element={<Done />} />
            <Route path={CONTRACT_PAGE_URL} element={<Contract />} />
            <Route
              path={CONTRACT_PAGE_URL_REDIRECT}
              element={<Navigate to={CONTRACT_PAGE_URL} replace />}
            />
            {/* <Redirect
              exact
              from={CONTRACT_PAGE_URL_REDIRECT}
              to={CONTRACT_PAGE_URL}
            /> */}
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default App;
