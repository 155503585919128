import { PropsWithChildren } from "react";
import { FC } from "react";
import { AnimatePresence, motion } from "framer-motion";

interface Props extends PropsWithChildren {
  className?: string;
  layout?: boolean;
  open: boolean;
}

const FadeAnimation: FC<Props> = ({ children, className, layout, open }) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          layout={layout}
          className={className}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FadeAnimation;
