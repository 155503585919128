import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function useFirstInvoiceMonth(promotionMonths: number) {
  const { i18n } = useTranslation();

  const month = useMemo(() => {
    const invoiceMonth = new Date();
    invoiceMonth.setDate(1);

    invoiceMonth.setMonth(invoiceMonth.getMonth() + 1 + promotionMonths);

    return invoiceMonth.toLocaleDateString(i18n.language, {
      year: "numeric",
      month: "long",
    });
  }, [i18n.language, promotionMonths]);

  return month;
}
