import React from "react";
import { MerchantContractInformation } from "../../../data/dataMerchant";
import { useTranslation } from "react-i18next";
import { Associate, ContractFeature } from "../../../data/dataMerchant";
import { Card } from "../../../components/cards/Card";
import { hasFeature } from "../../../state/contractState";

interface Props {
  contract: MerchantContractInformation;
  associates: Associate[];
  features: ContractFeature[];
}

export const BankAccount: React.FunctionComponent<Props> = ({ contract, associates, features }) => {
  const { bankAccount, contractData } = contract;

  let holder;

  if (bankAccount.accountHolder === "COMPANY") {
    holder = contractData.companyName;
  } else {
    const associate = associates.find((item) => item.associateId === bankAccount.accountHolder);
    holder = associate ? `${associate.contact.firstName} ${associate.contact.lastName}` : "-";
  }

  const { t } = useTranslation();

  if (hasFeature(ContractFeature.KLARNA, features)) {
    return null;
  }

  return (
    <div className="bank-account">
      <Card header={t("Bank account")} withBackground={false}>
        <div className="tablet-columns  split split-larger">
          <dl>
            <dt>{t("IBAN")}</dt>
            <dd>{bankAccount.iban}</dd>
            <dt>{t("Account holder")}</dt>
            <dd>{holder}</dd>
            <dt>{t("Bank")}</dt>
            <dd>{bankAccount.bank}</dd>
            <dt>{t("BIC")}</dt>
            <dd>{bankAccount.bic}</dd>
          </dl>
        </div>
      </Card>
    </div>
  );
};
