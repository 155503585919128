import React, { ReactNode } from "react";
import cx from "classnames";
import { Link as RouterLink } from "react-router-dom";
import { External } from "../icons/External";
import "./Link.scss";
import { DownloadIcon } from "../icons/DownloadIcon";

interface Props {
  link: string;
  download?: string;
  external?: boolean;
  children: ReactNode;
  className?: string;
  buttonStyle?: boolean;
  externalDownloadIcon?: boolean;
}

export const Link: React.FunctionComponent<Props> = ({
  link,
  external,
  children,
  className,
  download,
  buttonStyle,
  externalDownloadIcon,
}) => {
  if (external) {
    return (
      <a
        className={cx("link", "external-link", "postfix", className, {
          "link-button-style": buttonStyle,
        })}
        href={link}
        target="_blank"
        rel="noreferrer"
        download={download}
      >
        {children}
        {externalDownloadIcon ? <DownloadIcon /> : <External />}
      </a>
    );
  }
  return (
    <RouterLink className={cx("link", className)} to={link}>
      {children}
    </RouterLink>
  );
};
