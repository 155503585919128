import React from "react";
import { Address as AddressInterface } from "../../data/models/ContractTypes";
import { useTranslation } from "react-i18next";
import countries from "../countries.json";
import { defaultAddress } from "../../data/dataMerchant";

interface Props {
  address?: AddressInterface;
  header?: string;
  className?: string;
}

export const Address: React.FunctionComponent<Props> = ({
  address = defaultAddress,
  header,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <b className="section-subtitle">{header || t("Address")}</b>
      <dl>
        <dt>{t("Street")}</dt>
        <dd>
          {address.street} {address.streetNumber}
        </dd>
        <dt>{t("Postal code")}</dt>
        <dd>{address.postalCode}</dd>
        <dt>{t("City")}</dt>
        <dd>{address.city}</dd>
        <dt>{t("Country")}</dt>
        <dd>{address.countryCode ? t(countries[address.countryCode]) : "-"}</dd>
      </dl>
    </div>
  );
};
