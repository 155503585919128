import { VoidFunctionComponent } from "react";
import { Associate } from "../../../data/dataMerchant";
import { FaSignature } from "react-icons/fa";
import "./ActionButton.scss";
import ReactDOM from "react-dom";
import { useRequiresIndentification } from "../../../hooks/useRequiresIndentification";
import { useRequiresSignature } from "../../../hooks/useRequiresSignature";

interface Props {
  associate?: Associate;
}

export const ActionButton: VoidFunctionComponent<Props> = ({ associate }) => {
  const requiresIdentification = useRequiresIndentification();
  const requiresSignature = useRequiresSignature();

  if (requiresIdentification) {
    return ReactDOM.createPortal(
      <button
        type="button"
        className="floating-action-button"
        onClick={() => {
          document.querySelector(".identify")?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }}
      >
        <FaSignature
          size={64}
          color={"#FFF"}
          style={{ transform: "rotate(180deg)" }}
        />
      </button>,
      document.body
    );
  } else if (requiresSignature) {
    return ReactDOM.createPortal(
      <button
        type="button"
        className="floating-action-button"
        onClick={() => {
          document.querySelector(".signatures")?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }}
      >
        <FaSignature
          size={64}
          color={"#FFF"}
          style={{ transform: "rotate(180deg)" }}
        />
      </button>,
      document.body
    );
  }

  return null;
};
