import React from "react";
import cx from "classnames";
import { Checkmark } from "../icons/Checkmark";
import { ErrorOutline } from "../icons/ErrorOutline";
import { Profile } from "../icons/Profile";
import { WarningOutline } from "../icons/WarningOutline";
import { Validity } from "../icons/AssociateIcon";
import "./Beacon.scss";
import { Pending } from "../icons/Pending";

interface Props {
  validity: Validity;
  className?: string;
  isPending?: boolean;
}

export const Beacon: React.FunctionComponent<Props> = ({
  validity,
  className,
  isPending,
}) => {
  if (isPending) {
    return (
      <div className={cx("beacon", className)}>
        <Pending />
      </div>
    );
  }

  if (validity === Validity.MISSING) {
    return (
      <div className={cx("beacon", validity, className)}>
        <ErrorOutline />
      </div>
    );
  }

  if (validity === Validity.PARTIAL) {
    return (
      <div className={cx("beacon", validity, className)}>
        <WarningOutline />
      </div>
    );
  }

  if (validity === Validity.VALID) {
    return (
      <div className={cx("beacon", validity, className)}>
        <Checkmark />
      </div>
    );
  }

  return (
    <div className={cx("beacon", validity, className)}>
      <Profile />
    </div>
  );
};
