import { useEffect, useState } from "react";

const MESSAGE_SOURCE = "pdfjs";

enum Event {
  RENDERED = "pageRendered",
}

const SLOW_TIMEOUT = 15 * 1000;

export const usePdfViewer = () => {
  const [isRendered, setIsRendered] = useState(false);
  const [isSlow, setIsSlow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsSlow(true);
    }, SLOW_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const handleMessage = (
      event: MessageEvent<{ message: Event; source?: string }>
    ) => {
      if (event.data.source !== MESSAGE_SOURCE) {
        return;
      }

      switch (event.data.message) {
        case Event.RENDERED:
          setIsRendered(true);
          setIsSlow(false);
          break;
      }
    };

    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage, false);
    };
  }, []);

  return { isLoading: !isRendered, isSlow };
};
