import { Article, ArticleName, Store } from "../../../../data/dataMerchant";
import { useRecoilValue } from "recoil";
import { contractState } from "../../../../state/contractState";
import styles from "./ArticlesTable.module.scss";
import { useTranslation } from "react-i18next";
import cx from "classnames";

const ArticlesTable = () => {
  const { contract } = useRecoilValue(contractState);
  const { stores } = contract;
  const { t } = useTranslation();

  const groupedArticles = groupArticlesFromStore(stores);
  if (groupedArticles.length < 1) return null;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h5>{t("Articles summary")}</h5>
      </div>
      <div className={styles.articles}>
        {groupedArticles.map((article) => (
          <dl className={styles.article}>
            <dt>{t("Article")}</dt>
            <dd>{article.article.articleDescription}</dd>

            <dt>{t("Amount")}</dt>
            <dd>{article.amount}</dd>

            <dt>{t("Price type")}</dt>
            <dd>{article.article.priceType}</dd>

            <dt>{t("Total cost")}</dt>
            <dd className={cx({ [styles.free]: article.totalPrice === 0 })}>
              {article.totalPrice === 0 ? t("Free") : article.totalPrice}
            </dd>
          </dl>
        ))}
      </div>
    </div>
  );
};

type GroupedArticle = {
  articleName: ArticleName;
  totalPrice: number;
  amount: number;
  article: Article;
};

const groupArticlesFromStore = (stores: Store[]) => {
  const articleMap = new Map<string, GroupedArticle>();

  stores.forEach((store) =>
    store.terminals.forEach((terminal) =>
      terminal.articles?.forEach((article) => {
        const { article: articleName, price = 0 } = article;

        if (!articleMap.has(articleName)) {
          articleMap.set(articleName, {
            articleName,
            article,
            totalPrice: price,
            amount: 1,
          });
        } else {
          const foundArticle = articleMap.get(articleName)!;
          foundArticle.totalPrice += price;
          foundArticle.amount += 1;
        }
      })
    )
  );

  return Array.from(articleMap.values());
};

export default ArticlesTable;
