import { useRecoilValue } from "recoil";
import {
  isAccountHolder,
  isOwner,
  isPrimary,
  isSignee,
} from "../state/associateState";
import { viewerAsAssociateSelector } from "../state/associateState";
import { confirmedState } from "../state/confirmedState";
import { useRequiresBankAccount } from "./useRequiresBankAccount";
import { useRequiresDocs } from "./useRequiresDocs";
import { useRequiresIndentification } from "./useRequiresIndentification";
import { useRequiresSignature } from "./useRequiresSignature";

export function useIsDone(): boolean {
  const viewer = useRecoilValue(viewerAsAssociateSelector);
  const confirmed = useRecoilValue(confirmedState);

  const requiresIdentification = useRequiresIndentification();
  const requiresSignature = useRequiresSignature();
  const requiresBankAccount = useRequiresBankAccount();
  const requiresDocs = useRequiresDocs();

  const isAssociatePrimary = isPrimary(viewer);
  const isAssociateAccountHolder = isAccountHolder(viewer);
  const isAssociateSignee = isSignee(viewer);
  const isAssociateOwner = isOwner(viewer);

  if (!viewer) {
    return false;
  }

  if (isAssociatePrimary) {
    if (requiresDocs) {
      return false;
    }

    if (!confirmed.confirmed) {
      return false;
    }
  }

  if (isAssociateAccountHolder) {
    if (requiresBankAccount) {
      return false;
    }
  }

  if (isAssociateSignee) {
    if (requiresIdentification) {
      return false;
    }

    if (requiresSignature) {
      return false;
    }
  }

  if (isAssociateOwner) {
    if (requiresIdentification) {
      return false;
    }
  }

  return true;
}
