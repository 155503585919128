import { useTranslation } from "react-i18next";
import { DataList } from "../../../../components/datalist/DataList";
import { DataListItem } from "../../../../components/datalist/DataListItem";
import { getIntlCost, getTerminalTypeString } from "../../../../components/utils";
import { Country, Language } from "../../../../data/models/ContractTypes";
import { ContractPricing } from "../../../../data/models/PricingTypes";
import { FC } from "react";
import styles from "./PackPricing.module.scss";
import { TerminalPreview } from "../../stores/components/TerminalPreview";

import { CURRENCY_BY_COUNTRY } from "../../../../i18n";

interface Props {
  contractPricing: ContractPricing;
  country: Country;
}

export const PackPricing: FC<Props> = ({ contractPricing, country }) => {
  const { i18n, t } = useTranslation();
  const language = i18n.language as Language;
  const currency = CURRENCY_BY_COUNTRY[country];
  const payPerUse = false;

  const { terminalType, monthlyTurnoverThreshold, monthlyAcquiringFee } = contractPricing;

  if (!terminalType || !monthlyTurnoverThreshold || !monthlyAcquiringFee)
    return <div className={styles.error}>An error occured, the price plan is missing data</div>;

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.header}>{t("Pack details")}</div>
        <div className={styles.pack}>
          <div className={styles.table}>
            <div className={styles.line} />
            <div className={styles.line_2} />
            <DataList small={false} align="left">
              <DataListItem text="Included terminal" value={getTerminalTypeString(terminalType)} />
              {payPerUse ? (
                <DataListItem
                  text="Terminal rental fee"
                  value={
                    123
                    // getIntlNumberFormat(
                    //   TERMINAL_COST[terminalType] * terminalCount,
                    //   2,
                    //   "currency"
                    // ) + " / month"
                  }
                />
              ) : (
                <>
                  <DataListItem
                    text="Included monthly volume"
                    value={getIntlCost(language, monthlyTurnoverThreshold, currency, 2)}
                  />
                  <DataListItem
                    text="Fixed monthly price"
                    value={getIntlCost(language, monthlyAcquiringFee, currency, 2)}
                  />
                </>
              )}
            </DataList>
          </div>

          <div className={styles.terminal}>
            <TerminalPreview
              count={1}
              type={terminalType}
              country={country}
              advancedPricing={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
