import React from "react";
import { isPrimary, isSignee } from "../../../state/associateState";
import { useTranslation } from "react-i18next";
import { FcViewDetails } from "react-icons/fc";
import { Card } from "../../../components/cards/Card";
import { Associate, Terminal } from "../../../data/dataMerchant";
import { ContractAdditionalDocs } from "../../ContractVerification/ContractAdditionalDocs";
import { Country } from "../../../data/models/ContractTypes";
import { useRecoilState, useRecoilValue } from "recoil";
import { confirmedState, NAME_CONFIRMED } from "../../../state/confirmedState";
import { contractState } from "../../../state/contractState";
import "./Terms.scss";
import { ids } from "../Contract";

interface Props {
  associate?: Associate;
  country: Country;
  mcc: string;
  terminals: Terminal[];
}

export function storeTermsValue(confirmedTerms: string) {
  window.localStorage.setItem(
    NAME_CONFIRMED,
    JSON.stringify({
      confirmedTerms,
    })
  );
}

export const Terms: React.FunctionComponent<Props> = ({ associate, country, mcc, terminals }) => {
  const { t } = useTranslation();
  const [confirmed, setConfirmedState] = useRecoilState(confirmedState);
  const contract = useRecoilValue(contractState);

  if (!isSignee(associate)) {
    return null;
  }

  if (isPrimary(associate)) {
    // Has already been shown
    return null;
  }

  return (
    <div id={ids.TERMS}>
      <div className="add-bank-account">
        <Card
          header={t("Terms and conditions")}
          completed={confirmed.confirmedTerms === contract.linkId}
          headerIcon
          maxContent
        >
          <div className="center m-bottom-10">
            <FcViewDetails size={74} />
          </div>
          <ContractAdditionalDocs
            terminals={terminals}
            mcc={mcc}
            confirmedTerms={confirmed.confirmedTerms}
            country={country}
            linkId={contract.linkId}
            onChange={() => {
              const prevMatch = contract.linkId === confirmed.confirmedTerms;
              if (prevMatch) {
                storeTermsValue("");
              } else {
                storeTermsValue(contract.linkId);
              }
              setConfirmedState((prev) => ({
                ...prev,
                confirmedTerms: prevMatch ? "" : contract.linkId,
              }));
            }}
          />
        </Card>
      </div>
    </div>
  );
};
