import { useRecoilValue } from "recoil";
import { isPrimary } from "../state/associateState";
import { viewerAsAssociateSelector } from "../state/associateState";
import { documentState } from "../state/documentState";

export function useRequiresDocs() {
  const viewer = useRecoilValue(viewerAsAssociateSelector);
  const documents = useRecoilValue(documentState);

  if (!isPrimary(viewer)) {
    return false;
  }

  return !documents.every((doc) => doc.fileAvailable);
}
