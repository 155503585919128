import React from "react";
import cx from "classnames";

import "./Radio.scss";

interface Props {
  className?: string;
  checked: boolean;
}

export const Radio: React.FunctionComponent<Props> = ({
  className,
  checked,
}) => {
  return (
    <div
      className={cx("radio", className, {
        checked,
      })}
    >
      <div
        className={cx("radio-background", {
          "radio-background-checked": checked,
        })}
      >
        <div
          className={cx("radio-indicator", {
            "radio-indicator-checked": checked,
          })}
        ></div>
      </div>
      {/* <RadioUnchecked />
      <RadioChecked /> */}
    </div>
  );
};
