import { useTranslation } from "react-i18next";
import { getIntlNumberFormat } from "../../../../components/utils";
import { Language } from "../../../../data/models/ContractTypes";
import styles from "./SurchargesAuthorizationFees.module.scss";

const PRESENCE_FEE = 0.08;
const DISTANCE_FEE = 0.08;
const FINAL_FEE = 0.009;
const INTEGRITY_FEE = 0.28;

export const AuthorizationFees = () => {
  const { i18n, t } = useTranslation();
  const language = i18n.language as Language;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <strong>{t("Authorization fees")}</strong>
      </div>
      <dl className={styles.list}>
        <div className={styles.row}>
          <dt>
            <div className={styles.label}>{t("Pre-authorization fee")}</div>
            <div className={styles.i}>Mastercard, Maestro, DebitMastercard</div>
          </dt>
          <dd>{getIntlNumberFormat(language, PRESENCE_FEE / 100, 2, "percent")}</dd>
        </div>
        <div className={styles.row}>
          <dt>
            <div className={styles.label}>{t("Pre-authorization fee distance")}</div>
          </dt>
          <dd>{getIntlNumberFormat(language, DISTANCE_FEE / 100, 2, "percent")}</dd>
        </div>
        <div className={styles.row}>
          <dt>
            <div className={styles.label}>{t("Final authorization fee")}</div>
            <div className={styles.i}>Mastercard, Maestro, DebitMastercard</div>
          </dt>
          <dd>{getIntlNumberFormat(language, FINAL_FEE / 100, 3, "percent")}</dd>
        </div>
        <div className={styles.row}>
          <dt>
            <div className={styles.label}>{t("Integrity fee for final authorizations")}</div>
            <div className={styles.i}>Mastercard, Maestro, DebitMastercard</div>
          </dt>
          <dd>{getIntlNumberFormat(language, INTEGRITY_FEE / 100, 2, "percent")}</dd>
        </div>
      </dl>
    </div>
  );
};
