import React from "react";
import cx from "classnames";
import { Image } from "../images/Image";
import "./ProviderLogo.scss";

interface Props {
  className?: string;
}

export const VPay: React.FunctionComponent<Props> = ({ className }) => (
  <div className={cx("provider-logo", "logo-vpay", className)}>
    <Image src="/images/cards/vpay_logo_300x325.svg" ratio={300 / 325} alt="VPay logo" />
  </div>
);
