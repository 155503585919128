import { useTranslation } from "react-i18next";
import { Link } from "../../../../components/link/Link";
import { INTERCHANGE_URL, SCHEMA_URL } from "./PricingModelDescription";
import styles from "./PricingTable.module.scss";

export const PricingTable = () => {
  const { t } = useTranslation();

  const components = [
    {
      title: t("Interchange fees"),
      text: t("As applied by the respective Card Scheme on behalf of the Card Issuers."),
      action: (
        <Link link={INTERCHANGE_URL} external>
          {t("Interchange fees")}
        </Link>
      ),
    },
    {
      title: t("Card Scheme fees"),
      text: t(
        "As applied by worldline, to cover the costs for transaction processing and settlement, fraud monitoring and customer service."
      ),
      action: (
        <Link link={SCHEMA_URL} external className={styles.turquoise}>
          {t("Card Scheme fees")}
        </Link>
      ),
    },

    {
      title: t("Worldline acquiring fees"),
      text: t(
        "As applied by worldline, to cover the costs for transaction processing and settlement, fraud monitoring and customer service."
      ),
      action: (
        <button
          className={`${styles.blue} link`}
          onClick={() => {
            const ele = document.getElementById("transaction-fees");
            if (ele) {
              ele.scrollIntoView({
                behavior: "smooth",
              });
            }
          }}
        >
          {t("Transaction fees")}
        </button>
      ),
    },
  ];

  return (
    <div className={styles.boxes}>
      {components.map((box, index) => (
        <div className={styles.box} key={index}>
          <div className={styles.box_line} data-type={index + 1} />
          <strong>{box.title}</strong>
          <p className={styles.text}>{box.text}</p>
          {box.action}
        </div>
      ))}
    </div>
  );
};
