import React from "react";
import { PortalLogin } from "./PortalLogin";
import { Associate, AssociateRole } from "../../../data/dataMerchant";
import { useTranslation } from "react-i18next";
import { Card } from "../../../components/cards/Card";
import "./PortalLogins.scss";

interface Props {
  associates: Associate[];
}

export const PortalLogins: React.FunctionComponent<Props> = ({ associates }) => {
  const logins = associates.filter(
    (associate) => associate.roles.indexOf(AssociateRole.PORTAL_LOGIN) > -1
  );

  const { t } = useTranslation();

  if (logins.length === 0) {
    return null;
  }

  return (
    <div className="portal-logins">
      <Card header={t("Portal logins")}>
        {logins.map((portalLogin, idx) => (
          <PortalLogin associate={portalLogin} key={idx} />
        ))}
      </Card>
    </div>
  );
};
