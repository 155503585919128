import React, { ReactNode } from "react";
import cx from "classnames";
import { Status } from "../../data/types";
import "./StatusBoxes.scss";
import { Validity } from "../icons/AssociateIcon";

interface Props {
  status: Validity | Status;
  children: ReactNode;
}

export const StatusBoxes: React.FunctionComponent<Props> = ({
  children,
  status,
}) => {
  return <div className={cx("status-boxes", status)}>{children}</div>;
};
