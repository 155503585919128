import React from "react";
import cx from "classnames";
import { Image } from "../images/Image";
import "./ProviderLogo.scss";

interface Props {
  className?: string;
}

export const AliPay: React.FunctionComponent<Props> = ({ className }) => (
  <div className={cx("provider-logo", "logo-alipay", className)}>
    <Image
      src="/images/cards/alipay_logo_149369x37664.svg"
      ratio={149369 / 37664}
      alt="Alipay logo"
    />
  </div>
);
