import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router";
import { Card } from "../../../../components/cards/Card";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Contract, dataMerchant } from "../../../../data/dataMerchant";
import { InvalidLink } from "../../../InvalidLink";
import MobileDetect from "mobile-detect";
import "./DeviceSelection.scss";
import { TextInput } from "../../../../components/interactions/Input/TextInput";
import { CardPageLayout } from "../../../../components/cardPageLayout/CardPageLayout";
import QRCode from "react-qr-code";
import { CONTRACT_PAGE_URL } from "../../Contract";
import { Or } from "../../../../components/or/Or";
import { Link } from "react-router-dom";
import { ID_SELECTION_URL } from "../IdentificationSelection/IdentificationSelection";
import { SELFIE_UPLOAD_URL } from "../SelfieUpload/SelfieUpload";
import { useLinkId } from "../../../../hooks/useLinkId";
import { AnimatePresence, motion } from "framer-motion";
import { ServerError } from "../../../../network/API";
import { Status } from "../../../../data/types";

export const DEVICE_SELECTION_URL = "/:linkId/identification";

export const DeviceSelection = () => {
  const linkId = useLinkId();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [contract, setContract] = useState<Contract>();
  const [error, setError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [serverErrorMessage, setServerErrorMessage] = useState<string | null>(null);
  const [phoneValidationMessage, setPhoneValidationMessage] = useState<string | null>(null);
  const [status, setStatus] = useState(Status.DEFAULT);

  useEffect(() => {
    if (!linkId) return;

    const fetchContract = async () => {
      try {
        setError(false);
        const contract = await dataMerchant.getContract(linkId);

        const currentAssociate = contract.associates.find(
          (associate) => associate.associateId === contract.contractViewer.associateId
        );

        if (!currentAssociate) return;

        const device = new MobileDetect(window.navigator.userAgent);
        const isPhoneSized = device.isPhoneSized();
        const needsSelfieUpload = !currentAssociate.remoteIdentity?.selfieUploaded;

        if (isPhoneSized) {
          const redirectTo = needsSelfieUpload ? SELFIE_UPLOAD_URL : ID_SELECTION_URL;
          return navigate(generatePath(redirectTo, { linkId }), { replace: true });
        }

        setContract(contract);
        if (contract.contractViewer.phoneNumber) {
          setPhoneNumber(contract.contractViewer.phoneNumber);
        }
      } catch (error) {
        setError(true);
      }
    };

    fetchContract();
  }, [linkId, navigate]);

  const onChangePhoneInput = (value: string) => {
    setPhoneValidationMessage(null);
    setPhoneNumber(value);
  };

  const onSendLinkToPhone = () => {
    setServerErrorMessage(null);
    if (!linkId) return;

    const errorMessage = validatePhoneNumber(phoneNumber);
    setPhoneValidationMessage(errorMessage);
    if (errorMessage) return;

    dataMerchant
      .sendDeviceSwitchLink(linkId, phoneNumber)
      .then(() => {
        setStatus(Status.SUCCESS);
        setTimeout(() => setStatus(Status.DEFAULT), 5000);
      })
      .catch((err: ServerError<any>) => {
        setStatus(Status.ERROR);
        setTimeout(() => setStatus(Status.DEFAULT), 5000);

        setPhoneValidationMessage(null);

        const serverErrorMsg = getServerErrorMessage(err.status);
        setServerErrorMessage(serverErrorMsg);
      });
  };

  if (error) return <InvalidLink />;

  if (!contract) return null;

  return (
    <CardPageLayout>
      <div className="device-selection">
        <Card header={t("Continue on mobile")} className="device-card">
          <div className="device-card">
            <Or className="white-bg">
              <div>
                <p className="m-bottom-30">
                  <Trans>
                    The document upload requires you to use your phone. Scan the QR code to continue
                    on your phone.
                  </Trans>
                </p>
                <div className="qr-code-wrapper m-bottom-30">
                  <QRCode value={window.location.href} size={128} />
                </div>
                <p className="m-bottom-10">
                  <Trans>Or click the link below to send a link to your phone.</Trans>
                </p>
                <TextInput
                  label={t("Phone number")}
                  placeholder={t("+46712345678")}
                  value={phoneNumber}
                  onChange={(value) => onChangePhoneInput(value)}
                />

                <Button onClick={onSendLinkToPhone} block status={status}>
                  {t("Text me a link")}
                </Button>

                <AnimatePresence>
                  {phoneValidationMessage && (
                    <motion.span
                      key="phone-validation-error"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="error-message fw-500 fs-small"
                    >
                      {t(phoneValidationMessage)}
                    </motion.span>
                  )}

                  {serverErrorMessage && (
                    <motion.span
                      key="server-error-message"
                      className="error-message fw-500 fs-small"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <Trans>{t(serverErrorMessage)}</Trans>
                    </motion.span>
                  )}

                  {status === Status.SUCCESS && (
                    <motion.span
                      key="sent-link-success-message"
                      className="success-message fw-500 fs-small"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <Trans>Sent link to phone</Trans>
                    </motion.span>
                  )}
                </AnimatePresence>
              </div>
            </Or>
          </div>
        </Card>
        <div className="m-top-20 center">
          <Link to={generatePath(CONTRACT_PAGE_URL, { linkId })}>{t("Go back")}</Link>
        </div>
      </div>
    </CardPageLayout>
  );
};

const TOO_MANY_REQUEST_STATUS_CODE = 429;

const validatePhoneNumber = (phoneNumber: string): string | null => {
  if (phoneNumber.length === 0) return "Phone number is required";
  if (!phoneNumber.startsWith("+")) return "Phone number must start with a country code. Ex. +46";
  if (phoneNumber.length < 8) return "A phone number has to be at least 8 characters";

  // Check that the rest of the phone number contains only digits
  const numberWithoutCountryCode = phoneNumber.slice(1); // Remove the leading '+'
  if (!/^\d+$/.test(numberWithoutCountryCode))
    return "Phone number must contain only digits after the country code";

  return null;
};

const getServerErrorMessage = (status: number) => {
  if (status === TOO_MANY_REQUEST_STATUS_CODE) {
    return "You've sent to many requests recently. Please wait a couple of minutes before sending the next one.";
  }

  return "Failed to send link to phone";
};
