import { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getIntlCost, getTerminalTypeString } from "../../../../components/utils";
import { Store } from "../../../../data/dataMerchant";
import { Country, Language, TerminalType } from "../../../../data/models/ContractTypes";
import { CURRENCY_BY_COUNTRY } from "../../../../i18n";
import styles from "./TerminalPricing.module.scss";
import {
  TerminalPricingResponse,
  dataTerminalPricing,
  TerminalPricing as ITerminalPricing,
} from "../../../../data/dataTerminalPricing";
import { useRecoilValue } from "recoil";
import { contractState } from "../../../../state/contractState";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Status } from "../../../../data/types";

interface Props {
  stores: Store[];
  country: Country;
}

type PricingMap = Map<TerminalType, ITerminalPricing>;

export const TerminalPricing = ({ stores, country }: Props) => {
  const [pricingMap, setPricingMap] = useState<PricingMap>(new Map());
  const [status, setStatus] = useState(Status.DEFAULT);

  const { t, i18n } = useTranslation();
  const language = i18n.language as Language;
  const { contractId } = useRecoilValue(contractState).contract.contractData;

  const terminalTypeMap = useMemo(() => getTerminalTypeMap(stores), [stores]);

  const totalTerminalCost = useMemo(
    () => calculateTotalTerminalCost(terminalTypeMap, pricingMap),
    [terminalTypeMap, pricingMap]
  );

  const fetchTerminalPrices = useCallback(async () => {
    try {
      setStatus(Status.PENDING);
      const newMap: PricingMap = new Map();
      const terminalPricings = await dataTerminalPricing.getContractTerminalPrices(contractId);
      terminalPricings.forEach((pricing) => newMap.set(pricing.terminalType, toPricing(pricing)));
      setPricingMap(newMap);
      setStatus(Status.DEFAULT);
    } catch (err) {
      setStatus(Status.ERROR);
    }
  }, [contractId]);

  useEffect(() => {
    fetchTerminalPrices();
  }, [fetchTerminalPrices]);

  if (status === Status.ERROR)
    return (
      <div className={styles.error}>
        <div>{t("Could not fetch terminal prices")}</div>
        <Button onClick={fetchTerminalPrices} block className="m-top-20">
          {t("Retry")}
        </Button>
      </div>
    );

  return (
    <div className={styles.root}>
      <div className="section-subtitle">{t("Terminals")}</div>
      <ul className={styles.list}>
        {Array.from(terminalTypeMap.entries()).map(([terminalType, terminalCount]) => {
          const terminalPrice = pricingMap.get(terminalType);
          return (
            <li className={styles.li} key={terminalType}>
              <div className={styles.type}>{getTerminalTypeString(terminalType)}</div>
              <div className={styles.count}>{`x ${terminalCount}`}</div>
              <div className={styles.subcost}>
                {terminalPrice === undefined ? (
                  <span className={styles.error_color}>Error</span>
                ) : (
                  <span>
                    {getIntlCost(
                      language,
                      terminalPrice.monthlyCost * terminalCount,
                      CURRENCY_BY_COUNTRY[country]
                    )}
                  </span>
                )}
              </div>
            </li>
          );
        })}
        <div className={styles.total_row}>
          <div className={styles.total}>{t("Total")}</div>
          <div className={styles.total_price}>
            {t("{{cost}} per month", {
              cost: getIntlCost(language, totalTerminalCost, CURRENCY_BY_COUNTRY[country]),
            })}
          </div>
        </div>
      </ul>
    </div>
  );
};

const getTerminalTypeMap = (stores: Store[]): Map<TerminalType, number> => {
  const map = new Map<TerminalType, number>();
  stores.forEach(({ terminals }) =>
    terminals.forEach(({ terminalType }) => {
      map.has(terminalType)
        ? map.set(terminalType, map.get(terminalType)! + 1)
        : map.set(terminalType, 1);
    })
  );
  return map;
};

const calculateTotalTerminalCost = (
  terminalMap: Map<TerminalType, number>,
  pricingMap: PricingMap
) => {
  let cost = 0;
  terminalMap.forEach(function (amount, terminalType) {
    const terminalCost = pricingMap.get(terminalType);
    if (terminalCost !== undefined) {
      cost += terminalCost.monthlyCost * amount;
    }
  });
  return cost;
};

const toPricing = (terminalPricingResponse: TerminalPricingResponse): ITerminalPricing => {
  const { monthlyCost, oneTimeCost } = terminalPricingResponse;
  return { monthlyCost, oneTimeCost };
};

/* const TEST_PRICES: TerminalPricingResponse[] = [
  {
    terminalType: TerminalType.LINK_2500,
    monthlyCost: 5.9,
    oneTimeCost: 0.0,
  },
  {
    terminalType: TerminalType.DX_8000,
    monthlyCost: 12.9,
    oneTimeCost: 0.0,
  },
  {
    terminalType: TerminalType.MOVE_5000,
    monthlyCost: 10.9,
    oneTimeCost: 0.0,
  },

  {
    terminalType: TerminalType.DESK_3500,
    monthlyCost: 8.9,
    oneTimeCost: 0.0,
  },
  {
    terminalType: TerminalType.DESK_5000,
    monthlyCost: 8.9,
    oneTimeCost: 0.0,
  },
  {
    terminalType: TerminalType.SATURN,
    monthlyCost: 12.9,
    oneTimeCost: 0.0,
  },
];
 */
