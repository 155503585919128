import React, { useEffect, useState } from "react";
import cx from "classnames";
import { useRecoilState, useRecoilValue } from "recoil";
import { useRequiresIndentification } from "../../../hooks/useRequiresIndentification";
import {
  associateState,
  viewerAsAssociateSelector,
} from "../../../state/associateState";
import { confirmedState } from "../../../state/confirmedState";
import { contractState } from "../../../state/contractState";
import {
  isAccountHolder,
  isOwner,
  isPrimary,
  isSignee,
} from "../../../state/associateState";
import { Beacon } from "../../../components/beacon/Beacon";
import { Validity } from "../../../components/icons/AssociateIcon";
import { documentState } from "../../../state/documentState";
import { ids } from "../Contract";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate } from "react-router-dom";
import { DONE_PAGE_URL } from "../Done/Done";
import { useIsMountedRef } from "../../../hooks/useIsMounted";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";
import { WarningOutline } from "../../../components/icons/WarningOutline";
import { actionState } from "../../../state/actionsState";
import { useRequiresDocs } from "../../../hooks/useRequiresDocs";
import { Button } from "../../../components/interactions/Buttons/Button";
import { Close } from "../../../components/icons/Close";
import "./Actions.scss";
import { useIsDone } from "../../../hooks/useIsDone";

export function Actions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const viewer = useRecoilValue(viewerAsAssociateSelector);
  const associates = useRecoilValue(associateState);
  const contract = useRecoilValue(contractState);
  const confirmed = useRecoilValue(confirmedState);
  const requiresIdentification = useRequiresIndentification();
  const requiresDocs = useRequiresDocs();
  const [open, setOpen] = useRecoilState(actionState);
  const [showPulse, setShowPulse] = useState<boolean>(false);
  // const completedDocs = documents?.every((doc) => doc.fileAvailable) ?? false;
  const isAssociatePrimary = isPrimary(viewer);
  const isAssociateAccountHolder = isAccountHolder(viewer);
  const isAssociateSignee = isSignee(viewer);
  const isAssociateOwner = isOwner(viewer);
  const documents = useRecoilValue(documentState);
  const hasDocs = isAssociatePrimary && !!documents.length;
  const mounted = useIsMountedRef();
  const isDone = useIsDone();
  let index = 0;

  useEffect(() => {
    if (!confirmed.confirmed) {
      return;
    }

    setTimeout(() => {
      if (!mounted.current) {
        return;
      }

      setShowPulse(true);
    }, 1000);
  }, [confirmed.confirmed, mounted]);

  useEffect(() => {
    if (!viewer || 0 === associates.length || !contract.linkId) {
      return;
    }

    if (isDone) {
      navigate(
        generatePath(DONE_PAGE_URL, {
          linkId: contract.linkId,
        })
      );
    }
  }, [associates.length, contract.linkId, navigate, isDone, viewer]);

  if (!viewer || 0 === associates.length) {
    return null;
  }

  const items = [];
  if (isAssociatePrimary) {
    index = index + 1;
    const key = `${viewer.associateId}-primary`;
    items.push(
      <li key={key} className={confirmed.confirmed ? "done" : ""}>
        <ActionConfirm
          done={confirmed.confirmed}
          setOpen={setOpen}
          t={t}
          index={index}
        />
      </li>
    );
  }

  if (!isAssociatePrimary && isAssociateSignee) {
    index = index + 1;
    const key = `${viewer.associateId}-terms`;
    items.push(
      <li
        key={key}
        className={confirmed.confirmedTerms === contract.linkId ? "done" : ""}
      >
        <ActionTerms
          done={confirmed.confirmedTerms === contract.linkId}
          setOpen={setOpen}
          t={t}
          index={index}
        />
      </li>
    );
  }

  if (isAssociateAccountHolder) {
    index = index + 1;
    const key = `${viewer.associateId}-holder`;
    items.push(
      <li
        key={key}
        className={
          !!(
            contract.contract.bankAccount.iban ||
            contract.contract.bankAccount.statementUploaded
          )
            ? "done"
            : ""
        }
      >
        <ActionBankAccount
          setOpen={setOpen}
          t={t}
          done={
            !!(
              contract.contract.bankAccount.iban ||
              contract.contract.bankAccount.statementUploaded
            )
          }
          index={index}
        />
      </li>
    );
  }

  if (hasDocs) {
    index = index + 1;
    items.push(
      <li
        key={`${viewer.associateId}-docs`}
        className={!requiresDocs ? "done" : ""}
      >
        <ActionDocs
          setOpen={setOpen}
          t={t}
          done={!requiresDocs}
          index={index}
        />
      </li>
    );
  }

  if (isAssociateSignee) {
    index = index + 1;
    items.push(
      <li
        key={`${viewer.associateId}-owner`}
        className={!requiresIdentification ? "done" : ""}
      >
        <ActionIdUpload
          setOpen={setOpen}
          t={t}
          done={!requiresIdentification}
          index={index}
        />
      </li>
    );
    index = index + 1;
    items.push(
      <li
        key={`${viewer.associateId}-signee`}
        className={!requiresIdentification ? "done" : ""}
      >
        <ActionSignature
          setOpen={setOpen}
          t={t}
          done={!!viewer.signatory?.signed}
          index={index}
        />
      </li>
    );
  } else if (isAssociateOwner) {
    index = index + 1;
    const key = `${viewer.associateId}-owner`;
    items.push(
      <li key={key}>
        <ActionIdUpload
          setOpen={setOpen}
          t={t}
          done={!requiresIdentification}
          index={index}
        />
      </li>
    );
  }

  return (
    <div
      className={cx(
        "contract-actions",
        `items-${index + (isAssociateSignee ? 1 : 0)}`,
        {
          open,
          visible: confirmed.confirmed,
          "show-pulse": showPulse,
          "has-signature": isAssociateSignee,
        }
      )}
    >
      <div
        onClick={() => {
          enableBodyScroll(document.body);
          clearAllBodyScrollLocks();
          setOpen(false);
        }}
        className="contract-actions-bg"
      />
      <div className="actions-toggle-button-bg" />

      <div className="contract-actions-wrapper">
        <button
          className="actions-toggle-button"
          onClick={() => {
            setShowPulse(false);
            setOpen(true);
            disableBodyScroll(document.body, {
              reserveScrollBarGap: true,
            });
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            {/* <path d="M4 17.5V14.5H22V17.5ZM4 33.5V30.5H22V33.5ZM32.95 21.3 25.85 14.2 27.95 12.1 32.95 17.05 41.9 8.1 44 10.25ZM32.95 37.3 25.85 30.2 27.95 28.1 32.95 33.05 41.9 24.1 44 26.25Z" /> */}
            <path d="M14.5 33.25H27.25V31H14.5ZM14.5 25.15H33.5V22.85H14.5ZM14.5 17H33.5V14.75H14.5ZM9.9 41Q8.7 41 7.85 40.15Q7 39.3 7 38.1V9.9Q7 8.7 7.85 7.85Q8.7 7 9.9 7H19.5Q19.75 5.4 21 4.2Q22.25 3 24.05 3Q25.8 3 27.05 4.2Q28.3 5.4 28.5 7H38.1Q39.3 7 40.15 7.85Q41 8.7 41 9.9V38.1Q41 39.3 40.15 40.15Q39.3 41 38.1 41ZM24 8.5Q24.65 8.5 25.175 8Q25.7 7.5 25.7 6.85Q25.7 6.15 25.175 5.65Q24.65 5.15 24 5.15Q23.3 5.15 22.825 5.65Q22.35 6.15 22.35 6.85Q22.35 7.5 22.825 8Q23.3 8.5 24 8.5ZM9.9 38.75H38.1Q38.35 38.75 38.55 38.55Q38.75 38.35 38.75 38.1V9.9Q38.75 9.65 38.55 9.45Q38.35 9.25 38.1 9.25H9.9Q9.65 9.25 9.45 9.45Q9.25 9.65 9.25 9.9V38.1Q9.25 38.35 9.45 38.55Q9.65 38.75 9.9 38.75ZM9.25 9.2Q9.25 9.2 9.25 9.375Q9.25 9.55 9.25 9.8V37.85Q9.25 38.05 9.25 38.25Q9.25 38.45 9.25 38.45Q9.25 38.45 9.25 38.25Q9.25 38.05 9.25 37.85V9.8Q9.25 9.55 9.25 9.375Q9.25 9.2 9.25 9.2Z" />
          </svg>
        </button>

        <ul className="contract-actions-list-items">
          <li className="contract-actions-list">
            <b>{t("Todo list")}</b>
            <Button
              className="ghost action"
              onClick={() => {
                setOpen(false);
                clearAllBodyScrollLocks();
              }}
            >
              <Close />
            </Button>
          </li>
          {items}
        </ul>
        <div className="additional-signature-info text-small">
          <span className="signature-info-icon">
            <WarningOutline />
          </span>{" "}
          {t("Complete all other tasks before signing the contract")}.
        </div>
      </div>
    </div>
  );
}

const ActionConfirm: React.FC<{
  done: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: any;
  index: number;
}> = ({ done, setOpen, t, index }) => {
  return (
    <button
      onClick={() => {
        const elem = document.getElementById(ids.CONFIRM);
        clearAllBodyScrollLocks();
        if (!elem) {
          return;
        }
        elem.scrollIntoView({ behavior: "smooth" });
        setOpen(false);
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path d="M11.35 43Q9.5 43 8.2 41.7Q6.9 40.4 6.9 38.55V33.35H13.15V5.4L15.9 7.75L18.7 5.4L21.5 7.75L24.3 5.4L27.1 7.75L29.9 5.4L32.75 7.75L35.55 5.4L38.35 7.75L41.1 5.4V38.55Q41.1 40.4 39.8 41.7Q38.5 43 36.65 43ZM36.65 40.75Q37.6 40.75 38.225 40.125Q38.85 39.5 38.85 38.55V9.25H15.4V33.35H34.5V38.55Q34.5 39.5 35.1 40.125Q35.7 40.75 36.65 40.75ZM18.25 16.7V14.4H29.6V16.7ZM18.25 23.2V20.95H29.6V23.2ZM34.25 16.95Q33.7 16.95 33.275 16.525Q32.85 16.1 32.85 15.55Q32.85 15 33.275 14.575Q33.7 14.15 34.25 14.15Q34.8 14.15 35.225 14.575Q35.65 15 35.65 15.55Q35.65 16.1 35.225 16.525Q34.8 16.95 34.25 16.95ZM34.25 23.3Q33.7 23.3 33.275 22.875Q32.85 22.45 32.85 21.9Q32.85 21.35 33.275 20.925Q33.7 20.5 34.25 20.5Q34.8 20.5 35.225 20.925Q35.65 21.35 35.65 21.9Q35.65 22.45 35.225 22.875Q34.8 23.3 34.25 23.3ZM11.3 40.75H32.2V35.65H9.15V38.55Q9.15 39.5 9.775 40.125Q10.4 40.75 11.3 40.75ZM9.15 40.75Q9.15 40.75 9.15 40.125Q9.15 39.5 9.15 38.55V35.65V40.75Z" />
      </svg>{" "}
      <div>
        {index}. {t("Confirm")}
      </div>
      <Beacon
        className="mini"
        validity={done ? Validity.VALID : Validity.PARTIAL}
      />
    </button>
  );
};

const ActionBankAccount: React.FC<{
  done: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: any;
  index: number;
}> = ({ done, setOpen, t, index }) => {
  return (
    <button
      onClick={() => {
        const elem = document.getElementById(ids.BANK_ACCOUNT);
        clearAllBodyScrollLocks();
        if (!elem) {
          return;
        }
        elem.scrollIntoView({ behavior: "smooth", block: "center" });
        setOpen(false);
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path d="M32.45 27.15Q33.7 27.15 34.65 26.2Q35.6 25.25 35.6 24Q35.6 22.7 34.65 21.775Q33.7 20.85 32.45 20.85Q31.2 20.85 30.25 21.775Q29.3 22.7 29.3 24Q29.3 25.25 30.25 26.2Q31.2 27.15 32.45 27.15ZM9.25 34.8V36.2Q9.25 36.45 9.25 37.6Q9.25 38.75 9.25 38.75Q9.25 38.75 9.25 38.575Q9.25 38.4 9.25 38.1V9.9Q9.25 9.6 9.25 9.425Q9.25 9.25 9.25 9.25Q9.25 9.25 9.25 10.425Q9.25 11.6 9.25 11.85V13.25Q9.25 13.25 9.25 14.825Q9.25 16.4 9.25 18.85V29.15Q9.25 31.65 9.25 33.225Q9.25 34.8 9.25 34.8ZM9.9 41Q8.75 41 7.875 40.15Q7 39.3 7 38.1V9.9Q7 8.75 7.875 7.875Q8.75 7 9.9 7H38.1Q39.3 7 40.15 7.875Q41 8.75 41 9.9V16.55H38.75V9.9Q38.75 9.6 38.575 9.425Q38.4 9.25 38.1 9.25H9.9Q9.6 9.25 9.425 9.425Q9.25 9.6 9.25 9.9V38.1Q9.25 38.4 9.425 38.575Q9.6 38.75 9.9 38.75H38.1Q38.4 38.75 38.575 38.575Q38.75 38.4 38.75 38.1V31.45H41V38.1Q41 39.3 40.15 40.15Q39.3 41 38.1 41ZM27.25 32.5Q25.75 32.5 24.8 31.6Q23.85 30.7 23.85 29.15V18.85Q23.85 17.3 24.8 16.4Q25.75 15.5 27.25 15.5H39.65Q41.2 15.5 42.15 16.4Q43.1 17.3 43.1 18.85V29.15Q43.1 30.7 42.15 31.6Q41.2 32.5 39.65 32.5ZM40.2 30.25Q40.45 30.25 40.625 30.075Q40.8 29.9 40.8 29.65V18.4Q40.8 18.15 40.625 17.975Q40.45 17.8 40.2 17.8H26.75Q26.5 17.8 26.325 17.975Q26.15 18.15 26.15 18.4V29.65Q26.15 29.9 26.325 30.075Q26.5 30.25 26.75 30.25Z" />
      </svg>
      <div>
        {index}. {t("Add bank account")}
      </div>
      <Beacon
        className="mini"
        validity={done ? Validity.VALID : Validity.PARTIAL}
      />
    </button>
  );
};

const ActionIdUpload: React.FC<{
  done: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: any;
  index: number;
}> = ({ done, setOpen, t, index }) => {
  return (
    <button
      onClick={() => {
        const elem = document.getElementById(ids.IDENTIFICATION);
        clearAllBodyScrollLocks();
        if (!elem) {
          return;
        }
        elem.scrollIntoView({ behavior: "smooth", block: "center" });
        setOpen(false);
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path d="M28.3 26.75H36.65V24.75H28.3ZM28.3 31.9H36.65V29.85H28.3ZM28.4 15H40.1Q41.3 15 42.15 15.85Q43 16.7 43 17.9V40.1Q43 41.3 42.15 42.15Q41.3 43 40.1 43H7.9Q6.7 43 5.85 42.15Q5 41.3 5 40.1V17.9Q5 16.7 5.85 15.85Q6.7 15 7.9 15H19.6V7.25Q19.6 6.35 20.3 5.675Q21 5 21.9 5H26.15Q27.05 5 27.725 5.675Q28.4 6.35 28.4 7.25ZM21.9 17.25H26.15V7.25H21.9ZM24 29.25Q24 29.25 24 29.25Q24 29.25 24 29.25Q24 29.25 24 29.25Q24 29.25 24 29.25Q24 29.25 24 29.25Q24 29.25 24 29.25Q24 29.25 24 29.25Q24 29.25 24 29.25Q24 29.25 24 29.25Q24 29.25 24 29.25Q24 29.25 24 29.25Q24 29.25 24 29.25ZM17.8 29.85Q18.8 29.85 19.525 29.125Q20.25 28.4 20.25 27.4Q20.25 26.35 19.525 25.625Q18.8 24.9 17.8 24.9Q16.75 24.9 16.025 25.625Q15.3 26.35 15.3 27.4Q15.3 28.4 16.025 29.125Q16.75 29.85 17.8 29.85ZM12 35.15H23.35V34.7Q23.35 33.9 22.95 33.275Q22.55 32.65 21.9 32.4Q20.5 31.9 19.575 31.725Q18.65 31.55 17.8 31.55Q16.85 31.55 15.8 31.75Q14.75 31.95 13.5 32.4Q12.85 32.65 12.425 33.275Q12 33.9 12 34.7ZM19.6 17.25H7.9Q7.6 17.25 7.425 17.425Q7.25 17.6 7.25 17.9V40.1Q7.25 40.4 7.425 40.575Q7.6 40.75 7.9 40.75H40.1Q40.4 40.75 40.575 40.575Q40.75 40.4 40.75 40.1V17.9Q40.75 17.6 40.575 17.425Q40.4 17.25 40.1 17.25H28.4Q28.4 18.45 27.675 19Q26.95 19.55 25.8 19.55H22.2Q21.1 19.55 20.35 19Q19.6 18.45 19.6 17.25Z" />
      </svg>
      <div>
        {index}. {t("Identification")}
      </div>
      <Beacon
        className="mini"
        validity={done ? Validity.VALID : Validity.PARTIAL}
      />
    </button>
  );
};

const ActionSignature: React.FC<{
  done: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: any;
  index: number;
}> = ({ done, setOpen, t, index }) => {
  return (
    <button
      onClick={() => {
        const elem = document.getElementById(ids.SIGNATURE);
        clearAllBodyScrollLocks();
        if (!elem) {
          return;
        }
        elem.scrollIntoView({ behavior: "smooth", block: "center" });
        setOpen(false);
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path d="M28 41.5Q27.55 41.5 27.2 41.175Q26.85 40.85 26.85 40.35Q26.85 39.85 27.2 39.55Q27.55 39.25 28 39.25Q31.05 39.25 33.15 38Q35.25 36.75 35.25 35Q35.25 33.7 33.7 32.5Q32.15 31.3 29.45 30.7L31.3 28.9Q34.45 29.8 35.975 31.425Q37.5 33.05 37.5 35Q37.5 38.1 34.575 39.8Q31.65 41.5 28 41.5ZM12.35 27.05Q9.25 26.3 7.875 24.9Q6.5 23.5 6.5 22Q6.5 20.35 7.8 19.05Q9.1 17.75 13.45 16.1Q16.8 14.8 17.825 13.975Q18.85 13.15 18.85 12.05Q18.85 10.7 17.625 9.725Q16.4 8.75 14 8.75Q12.6 8.75 11.55 9.15Q10.5 9.55 9.7 10.35Q9.4 10.65 8.925 10.7Q8.45 10.75 8.1 10.5Q7.65 10.2 7.65 9.725Q7.65 9.25 7.9 8.9Q8.7 7.85 10.375 7.175Q12.05 6.5 14 6.5Q17.2 6.5 19.175 8.025Q21.15 9.55 21.15 12.05Q21.15 13.9 19.75 15.25Q18.35 16.6 14.35 18.15Q11.05 19.45 9.9 20.275Q8.75 21.1 8.75 22Q8.75 22.9 10.1 23.7Q11.45 24.5 14.2 25.25ZM36.4 18.65 31.1 13.35 33.15 11.3Q33.9 10.55 34.825 10.525Q35.75 10.5 36.5 11.3L38.45 13.25Q39.25 14 39.225 14.925Q39.2 15.85 38.45 16.6ZM11.1 38.65H13.15L30.55 21.25L28.5 19.2L11.1 36.6ZM8.85 40.9V35.65L28.5 15.95L33.8 21.25L14.1 40.9ZM28.5 19.2 30.55 21.25Z" />
      </svg>
      {index}. {t("Add signature")}
      {/* <span className="signature-last text-small">
        <span className="signature-last-icon">
          <WarningOutline />
        </span>{" "}
      </span> */}
      {/* {t("Signature must be added last")} */}
      {/* {t("Complete all other tasks before signing the contract")} */}
      <Beacon
        className="mini"
        validity={done ? Validity.VALID : Validity.PARTIAL}
      />
    </button>
  );
};

const ActionDocs: React.FC<{
  done: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: any;
  index: number;
}> = ({ done, setOpen, t, index }) => {
  return (
    <button
      onClick={() => {
        const elem = document.getElementById(ids.DOCUMENTS);
        clearAllBodyScrollLocks();
        if (!elem) {
          return;
        }
        elem.scrollIntoView({ behavior: "smooth", block: "center" });
        setOpen(false);
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path d="M22.85 35.7H25.15V29.4H31.5V27.1H25.15V20.75H22.85V27.1H16.5V29.4H22.85ZM36.1 43H11.9Q10.7 43 9.85 42.15Q9 41.3 9 40.1V7.9Q9 6.7 9.85 5.85Q10.7 5 11.9 5H29.25L39 14.75V40.1Q39 41.3 38.15 42.15Q37.3 43 36.1 43ZM28.15 15.75V7.25H11.9Q11.65 7.25 11.45 7.45Q11.25 7.65 11.25 7.9V40.1Q11.25 40.35 11.45 40.55Q11.65 40.75 11.9 40.75H36.1Q36.35 40.75 36.55 40.55Q36.75 40.35 36.75 40.1V15.75ZM11.25 7.25V15.75V7.25V15.75V40.1Q11.25 40.35 11.25 40.55Q11.25 40.75 11.25 40.75Q11.25 40.75 11.25 40.55Q11.25 40.35 11.25 40.1V7.9Q11.25 7.65 11.25 7.45Q11.25 7.25 11.25 7.25Z" />
      </svg>
      <div>
        {index}. {t("Upload documents")}
      </div>
      <Beacon
        className="mini"
        validity={done ? Validity.VALID : Validity.PARTIAL}
      />
    </button>
  );
};

const ActionTerms: React.FC<{
  done: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  t: any;
  index: number;
}> = ({ done, setOpen, t, index }) => {
  return (
    <button
      onClick={() => {
        const elem = document.getElementById(ids.TERMS);
        clearAllBodyScrollLocks();
        if (!elem) {
          return;
        }
        elem.scrollIntoView({ behavior: "smooth", block: "center" });
        setOpen(false);
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
        <path d="M21.55 41.3H9q-1.2 0-2.1-.9-.9-.9-.9-2.1V9.2q0-1.2.775-2.1.775-.9 1.325-.9h10.1q.35-1.75 1.725-2.875T23.1 2.2q1.8 0 3.175 1.125Q27.65 4.45 28 6.2h10.1q1.2 0 2.1.9.9.9.9 2.1v10.15h-3V9.2h-5.3v6.5H14.3V9.2H9v29.1h12.55ZM31 40.05l-8-8 2.15-2.15L31 35.75 42.95 23.8l2.15 2.15ZM24 9q.85 0 1.425-.575Q26 7.85 26 7q0-.85-.575-1.425Q24.85 5 24 5q-.85 0-1.425.575Q22 6.15 22 7q0 .85.575 1.425Q23.15 9 24 9Z" />
      </svg>
      <div>
        {index}. {t("Terms and conditions")}
      </div>
      <Beacon
        className="mini"
        validity={done ? Validity.VALID : Validity.PARTIAL}
      />
    </button>
  );
};
