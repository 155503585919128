import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import "./LangSelector.scss";
import { Flag } from "../../../components/icons/flags/Flag";
import { Language } from "../../../data/models/ContractTypes";
import { getCountryByLanguage, getLanguageFromCountry } from "../../../i18n";
import { useRecoilValue } from "recoil";
import { contractState } from "../../../state/contractState";

interface Props {
  className?: string;
}

export const LangSelector: React.FunctionComponent<Props> = ({ className }) => {
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const { contract } = useRecoilValue(contractState);
  const { country } = contract.contractData;
  const contractLanguage = getLanguageFromCountry(country);
  const allowFrance = search.indexOf("lang=fr") > -1;

  return (
    <ul className={cx("lang-selector", className)}>
      {[contractLanguage, Language.UK].map((lang) => {
        if (lang === Language.FRANCE && !allowFrance) {
          return null;
        }

        return (
          <li key={lang} className={cx({ selected: lang === i18n.language })}>
            <button onClick={() => i18n.changeLanguage(lang)}>
              <Flag lang={getCountryByLanguage(lang)} />
            </button>
          </li>
        );
      })}
    </ul>
  );
};
