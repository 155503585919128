import { useRecoilValue } from "recoil";
import { isAccountHolder } from "../state/associateState";
import { viewerAsAssociateSelector } from "../state/associateState";
import { contractState } from "../state/contractState";

export function useRequiresBankAccount() {
  const viewer = useRecoilValue(viewerAsAssociateSelector);
  const contract = useRecoilValue(contractState);

  if (!isAccountHolder(viewer)) {
    return false;
  }

  return (
    !contract.contract.bankAccount.iban &&
    !contract.contract.bankAccount.statementUploaded
  );
}
