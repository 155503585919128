import React from "react";
import {
  ContractPackage,
  PricingStep,
  Store,
} from "../../../data/dataMerchant";
import { useTranslation } from "react-i18next";
import {
  Country,
  Language,
  PackageId,
  TerminalType,
} from "../../../data/models/ContractTypes";
import { getIntlCost, getIntlNumberFormat } from "../../../components/utils";
import { useFirstInvoiceMonth } from "../../../hooks/useFirstInvoiceMonth";
import { TerminalPreview } from "./components/TerminalPreview";
import {
  OLD_TERMINAL_PRICES,
  TerminalPricingState,
} from "../../../data/dataTerminalPricing";

interface Props {
  contractPackage: ContractPackage;
  stores: Store[];
}

const CURRENCY = "PLN";
const SOFT_POS_CONTRACT_DURATION = 12;
const STANDARD_PACKAGE_CONTRACT_DURATION = 24;

function getNumberOfTerminalsOfType(stores: Store[], type: TerminalType) {
  return stores.reduce(
    (prev, currentStore) =>
      prev +
      currentStore.terminals.filter(
        (terminal) => terminal.terminalType === type
      ).length,
    0
  );
}

function getCommissionRate(step: PricingStep, lang: string, t: any) {
  if (step === PricingStep.LIGHT) {
    return (
      <>
        {t("{{value}} % per transaction", {
          value: getIntlNumberFormat(lang, 0.3, 2),
        })}
      </>
    );
  }

  if (step === PricingStep.STANDARD) {
    return (
      <>
        {t("{{value}} % per transaction", {
          value: getIntlNumberFormat(lang, 0.25, 2),
        })}
      </>
    );
  }

  return (
    <>
      {t("{{value}} % per transaction", {
        value: getIntlNumberFormat(lang, 0.2, 2),
      })}
    </>
  );
}

function getInfoForStep(step: PricingStep, lang: string, t: any) {
  if (step === PricingStep.LIGHT) {
    return (
      <>
        {t("Light - below {{value}}", {
          value: getIntlCost(lang, 3001, CURRENCY),
        })}
      </>
    );
  }

  if (step === PricingStep.STANDARD) {
    return (
      <>
        {t("Standard - from {{minValue}} to {{maxValue}}", {
          minValue: getIntlCost(lang, 3001, CURRENCY),
          maxValue: getIntlCost(lang, 6500, CURRENCY),
        })}
      </>
    );
  }

  return (
    <>
      {t("Advanced - above {{value}}", {
        value: getIntlCost(lang, 6500, CURRENCY),
      })}
    </>
  );
}

function getAccountFee(
  numberOfTerminals: number,
  terminalType: TerminalType,
  lang: string,
  terminalPrices: TerminalPricingState,
  t: any
) {
  const SOFT_POS_MONTHLY = terminalPrices[TerminalType.SOFT_POS].monthlyCost;
  const DESK_MONTHLY = terminalPrices[TerminalType.DESK_3500].monthlyCost;
  const MOVE_MONTHLY = terminalPrices[TerminalType.MOVE_3500].monthlyCost;

  if (terminalType === TerminalType.SOFT_POS) {
    return (
      <>
        {t("{{cost}} per month", {
          cost: getIntlCost(
            lang,
            numberOfTerminals * SOFT_POS_MONTHLY,
            CURRENCY,
            2
          ),
        })}
        <i>
          {t("(based on {{numberOfTerminals}} account/accounts)", {
            numberOfTerminals,
          })}
        </i>
      </>
    );
  }

  const cost =
    (terminalType === TerminalType.DESK_3500 ? DESK_MONTHLY : MOVE_MONTHLY) *
    numberOfTerminals;

  return (
    <>
      {t("{{cost}} per month", {
        cost: getIntlCost(lang, cost, CURRENCY),
      })}
      <i>
        {t("(based on {{numberOfTerminals}} terminal/terminals)", {
          numberOfTerminals,
        })}
      </i>
    </>
  );
}

export const PricingPL: React.FunctionComponent<Props> = ({
  contractPackage,
  stores,
}) => {
  const { t } = useTranslation();
  const terminalPrices = OLD_TERMINAL_PRICES;

  // useEffect(() => {
  //   dataTerminalPricing
  //     .loadTerminalPrices()
  //     .then((prices) => {
  //       setTerminalPrices(prices);
  //     })
  //     .catch((e) => {
  //       console.error("Error fetching prices: ", e);
  //     });
  // }, []);

  const firstInvoiceMonth = useFirstInvoiceMonth(
    contractPackage.promotionMonths
  );
  const step = contractPackage.pricingStep;

  let noOfTerminals = 0;
  let type = TerminalType.SOFT_POS;
  let monthlyKey = "Monthly acquiring service by Account";
  let accountOrTerminalKey = "Number of accounts";
  let contractDuration = SOFT_POS_CONTRACT_DURATION;

  if (contractPackage.packageId === PackageId.SOFTPOS_BUNDLE) {
    noOfTerminals = getNumberOfTerminalsOfType(stores, TerminalType.SOFT_POS);
    type = TerminalType.SOFT_POS;
    monthlyKey = "Monthly acquiring service by Account";
    accountOrTerminalKey = "Number of accounts";
    contractDuration = SOFT_POS_CONTRACT_DURATION;
  }

  if (contractPackage.packageId === PackageId.DESK_BUNDLE) {
    noOfTerminals = getNumberOfTerminalsOfType(stores, TerminalType.DESK_3500);
    type = TerminalType.DESK_3500;
    monthlyKey = "Monthly acquiring service by Terminal";
    accountOrTerminalKey = "Number of terminals";
    contractDuration = STANDARD_PACKAGE_CONTRACT_DURATION;
  }

  if (contractPackage.packageId === PackageId.MOVE_BUNDLE) {
    noOfTerminals = getNumberOfTerminalsOfType(stores, TerminalType.MOVE_3500);
    type = TerminalType.MOVE_3500;
    monthlyKey = "Monthly acquiring service by Terminal";
    accountOrTerminalKey = "Number of terminals";
    contractDuration = STANDARD_PACKAGE_CONTRACT_DURATION;
  }

  return (
    <div className="tablet-columns pricing">
      <div>
        <dl>
          <dt>{t("Package")}</dt>
          <dd>
            <TerminalPreview
              type={type}
              count={noOfTerminals}
              country={Country.POLAND}
            />
          </dd>
          <dt>{t("Estimated transaction volume")}</dt>
          <dd>{getInfoForStep(step, Language.POLAND, t)}</dd>
          <dt>{t("Fixed monthly commission rate")}</dt>
          <dd>{getCommissionRate(step, Language.POLAND, t)}</dd>
          <dt>{t(monthlyKey)}</dt>
          <dd>
            {getAccountFee(
              noOfTerminals,
              type,
              Language.POLAND,
              terminalPrices,
              t
            )}
          </dd>
        </dl>
      </div>
      <div>
        <dl>
          <dt>{t("Minimum contract duration")}</dt>
          <dd>{t("{{contractDuration}} months", { contractDuration })}</dd>
          <dt>{t(accountOrTerminalKey)}</dt>
          <dd>{noOfTerminals}</dd>
          {contractPackage.promotionMonths > 0 ? (
            <>
              <dt>{t("First invoice")}</dt>
              <dd>{firstInvoiceMonth}</dd>
            </>
          ) : null}
          <dt>{t("Deactivation fee")}</dt>
          <dd>{getIntlCost(Language.POLAND, 450, CURRENCY)}</dd>
        </dl>
      </div>
    </div>
  );
};
