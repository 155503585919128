import React, { useState, useCallback, useRef } from "react";
import cx from "classnames";
import { useRecoilState } from "recoil";
import { contractState } from "../../../../state/contractState";
import { FileType } from "../../../../components/idUpload/IdImage";
import { LinkId, UTCDate } from "../../../../data/models/ContractTypes";
import { nanoid } from "nanoid";
import { Beacon } from "../../../../components/beacon/Beacon";
import { Validity } from "../../../../components/icons/AssociateIcon";
import { Progress, ProgressInterface } from "../../../../components/idUpload/Progress";
import { Button } from "../../../../components/interactions/Buttons/Button";
import { Status } from "../../../../data/types";
import { useTranslation } from "react-i18next";
import { useWidth } from "../../../../hooks/useWidth";
import { Error } from "../../../../components/icons/Error";
import { API } from "../../../../network/API";
import { Visible } from "../../../../components/visible/Visible";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { CONTRACT_PAGE_URL } from "../../Contract";
import { Checkmark } from "../../../../components/icons/Checkmark";
import { desktopWidth } from "../../../../variables";
import { MIME_PDF } from "../../../ContractUploads/DocUpload";
import "./BankStatementUpload.scss";

interface Props {
  status: Status;
}

interface ImageUpload {
  fileName: string;
  url: string;
  mimeType: FileType;
}

export const BankStatementUpload: React.FunctionComponent<Props> = ({ status: externalStatus }) => {
  const width = useWidth();
  const navigate = useNavigate();
  const identifier = useRef<string>(nanoid());
  const fileElemRef = useRef<HTMLInputElement>(null);
  const [{ contract, linkId }, setContract] = useRecoilState(contractState);
  const { bankAccount } = contract;
  const { t } = useTranslation();
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);
  const [showNextButton, setShowNextButton] = useState<boolean>(!!bankAccount.statementUploaded);
  const [progress, setProgress] = useState<ProgressInterface>({
    progress: 0,
    name: "",
  });
  const isTouch = useRef<boolean>(
    window.matchMedia && window.matchMedia("(pointer:coarse)").matches && width < desktopWidth
  );

  const accept = isTouch.current
    ? "image/*, application/pdf, capture=camera"
    : "image/*, application/pdf";

  const onUpload = useCallback(
    (updated: ImageUpload) => {
      const date = new Date().toUTCString() as UTCDate;
      setContract((item) => ({
        ...item,
        contract: {
          ...item.contract,
          bankAccount: {
            ...item.contract.bankAccount,
            statementUploaded: date,
            fileName: updated.fileName || "",
            mimeType: updated.mimeType || FileType.IMAGE,
          },
        },
      }));
    },
    [setContract]
  );

  const onError = useCallback(() => {
    setContract((item) => ({
      ...item,
      contract: {
        ...item.contract,
        bankAccount: {
          ...item.contract.bankAccount,
          statementUploaded: undefined,
          fileName: undefined,
          mimeType: undefined,
        },
      },
    }));
  }, [setContract]);

  const upload = useCallback(
    (targetFile: File, linkIdentifier: LinkId) => {
      setStatus(Status.PENDING);
      const req = new XMLHttpRequest();

      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          setProgress({
            progress: (event.loaded / event.total) * 100,
            name: targetFile.name,
          });
        }
      });

      req.onload = function () {
        if (req.status === 200) {
          setProgress({ progress: 100, name: targetFile.name });

          const fileName = targetFile?.name;
          const isPdf = targetFile.type === MIME_PDF;
          const url = `${API.getUrl(
            `/api/merchant/${linkIdentifier}/bank-statement`
          )}?c=${new Date().getTime()}${isPdf ? "#view=Fit" : ""}`;
          const mimeType = isPdf ? FileType.PDF : FileType.IMAGE;

          const newImage = { fileName, url, mimeType };

          onUpload(newImage);
          setTimeout(() => {
            setShowNextButton(true);
            setStatus(Status.DEFAULT);
          }, 4000);
        } else {
          const errorMessage = req.responseText || "Could not upload file. Please try agian.";
          setApiErrorMessage(errorMessage);
          onError();
          setStatus(Status.ERROR);
          setTimeout(() => setStatus(Status.DEFAULT), 3000);
        }
      };

      const formData = new FormData();
      formData.append("document", targetFile);
      formData.append("filename", targetFile.name);
      req.open("POST", API.getUrl(`/api/merchant/${linkIdentifier}/bank-statement`));
      req.withCredentials = true;
      req.send(formData);
    },
    [onError, onUpload]
  );

  const onAttachFile = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      setApiErrorMessage(null);
      setShowNextButton(false);
      onError();
      const targetFile = ev.target.files?.[0];
      // no file was selected
      if (!targetFile) return;

      upload(targetFile, linkId);
    },
    [linkId, upload, onError]
  );

  const onNext = useCallback(() => {
    navigate(generatePath(CONTRACT_PAGE_URL, { linkId }));
  }, [navigate, linkId]);

  return (
    <div className={cx("bank-statement-upload", status)}>
      {/* <AnimateHeight name={status === Status.ERROR ? "error" : "default"}>
        {status === Status.ERROR ? (
          <div className="m-bottom-10">
            <ErrorBox relative>
              {t("Something went wrong. Try again?")}
            </ErrorBox>
          </div>
        ) : (
          <div />
        )}
      </AnimateHeight> */}
      {apiErrorMessage && (
        <div className="error-box">
          <span>{apiErrorMessage}</span>
        </div>
      )}
      <div className="doc-upload-button">
        <input
          type="file"
          id={identifier.current}
          name={identifier.current}
          onChange={onAttachFile}
          ref={fileElemRef}
          multiple={false}
          accept={accept}
        />
        <label htmlFor={identifier.current}>
          <span
            style={{ width: "100%", marginBottom: 10 }}
            className={cx(
              "button",
              "ghost",
              externalStatus === Status.PENDING ? externalStatus : status,
              {
                block: !bankAccount.statementUploaded,
              }
            )}
          >
            {t("Upload bank statement")} {status === Status.ERROR && <Error />}
          </span>
        </label>

        {bankAccount.statementUploaded && (
          <>
            <div className="bank-statement-upload-filename">
              <Beacon validity={Validity.VALID} className="mini" />{" "}
              <span className="truncate">{bankAccount.fileName || t("Bank statement")}</span>
              <a
                href={API.getUrl(`/api/merchant/${linkId}/bank-statement`)}
                target="_blank"
                rel="noreferrer"
                className="doc-upload-view-link"
              >
                {t("View file")}
              </a>
            </div>
          </>
        )}

        <Visible show={!showNextButton}>
          <div className="center m-top-20">
            <Link to={generatePath(CONTRACT_PAGE_URL, { linkId })}>{t("Back")}</Link>
          </div>
        </Visible>

        <Progress {...progress} />
      </div>

      <Visible show={showNextButton}>
        <div className="bank-statement-upload-done">
          <Button className="next-button" status={status} onClick={onNext}>
            <Checkmark />
            &nbsp;{t("Done!")}
          </Button>
        </div>
      </Visible>
    </div>
  );
};
