import { useCallback, useRef, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ErrorBox } from "../../../components/boxes/ErrorBox";
import { StatusBoxes } from "../../../components/boxes/StatusBoxes";
import { WarningBox } from "../../../components/boxes/WarningBox";
import { Validity } from "../../../components/icons/AssociateIcon";
import { getIcon } from "../../../components/interactions/Buttons/Button";
import { dataMerchant, VERSION_ERROR_CODE } from "../../../data/dataMerchant";
import { Status } from "../../../data/types";
import { confirmedState } from "../../../state/confirmedState";
import { contractState } from "../../../state/contractState";
import { pageShowVersionWarning } from "../../../state/pageState";
import { ContractAdditionalDocs } from "../../ContractVerification/ContractAdditionalDocs";

import { useWidth } from "../../../hooks/useWidth";
import { associateState } from "../../../state/associateState";
import { viewerState } from "../../../state/viewerState";
import { getCurrentAssociate, isPrimary } from "../../../state/associateState";
import { storeTermsValue } from "../Terms/Terms";
import "./ConfirmContract.scss";
import FadeAnimation from "../../../components/animate/FadeAnimation";

const BREAKPOINT_WIDTH = 1280;

enum AnimState {
  INITIAL,
  ANIMATING,
  DONE,
}

function getButtonStatus(status: Status | Validity) {
  if (status === Status.SUCCESS) {
    return Status.DISABLED;
  }

  if (status === Status.PENDING) {
    return Status.PENDING;
  }

  if (status === Status.DISABLED) {
    return Status.DISABLED;
  }

  return Status.DEFAULT;
}

export const ConfirmContract = () => {
  const { t } = useTranslation();
  const { contract, linkId } = useRecoilValue(contractState);
  const { version, mcc, country } = contract.contractData;
  const [status, setStatus] = useState<Status | Validity>(Status.DEFAULT);
  // const [checkedMcc, setCheckedMcc] = useState<string>("no");
  const [confirmed, setConfirmedState] = useRecoilState(confirmedState);
  const [animate, setAnimate] = useState<AnimState>(AnimState.INITIAL);
  const setContractState = useSetRecoilState(contractState);
  const setShowValidationWarning = useSetRecoilState(pageShowVersionWarning);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const width = useWidth();
  const associates = useRecoilValue(associateState);
  const viewer = useRecoilValue(viewerState);
  const currentAssociate = getCurrentAssociate(associates, viewer);

  const terminals = contract.stores.flatMap((store) => store.terminals);

  const onChange = () => {
    if (status === Validity.PARTIAL) {
      setStatus(Status.DEFAULT);
    }

    const prevMatch = linkId === confirmed.confirmedTerms;
    if (prevMatch) {
      storeTermsValue("");
    } else {
      storeTermsValue(linkId);
    }
    setConfirmedState((prev) => ({
      ...prev,
      confirmedTerms: prevMatch ? "" : linkId,
    }));
  };

  const onConfirm = useCallback(() => {
    if (linkId !== confirmed.confirmedTerms) {
      setStatus(Validity.PARTIAL);
      return;
    }

    setStatus(Status.PENDING);

    setTimeout(() => {
      dataMerchant
        .confirmContract(linkId, version)
        .then(() => {
          setAnimate(AnimState.ANIMATING);

          if (!buttonRef.current) {
            return;
          }

          const isSmall = width < BREAKPOINT_WIDTH;
          const buttonBox = buttonRef.current.getBoundingClientRect();
          buttonRef.current.classList.add("no-anim");
          buttonRef.current.style.position = "fixed";

          if (isSmall) {
            if (width < 480) {
              buttonRef.current.style.width = `${window.innerWidth - 32}px`;
              buttonRef.current.style.right = "16px";
            } else {
              buttonRef.current.style.width = `${window.innerWidth - 50}px`;
              buttonRef.current.style.right = "25px";
            }
          } else {
            buttonRef.current.style.width = `1024px`;
            // buttonRef.current.style.left = `${(width - 1024) / 2}px`;
            buttonRef.current.style.right = `${(width - 1024) / 2}px`;
          }

          buttonRef.current.style.bottom = `${window.innerHeight - buttonBox.bottom}px`;

          let h = buttonRef.current.offsetHeight;
          buttonRef.current.classList.remove("no-anim");
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          h = buttonRef.current.offsetHeight;
          buttonRef.current.style.width = "50px";
          buttonRef.current.style.height = "50px";
          buttonRef.current.style.borderRadius = "25px";

          if (isSmall) {
            buttonRef.current.style.right = "10px";
            buttonRef.current.style.bottom = `100px`;
          } else {
            buttonRef.current.style.right = `${width / 2 - 587}px`;
            buttonRef.current.style.bottom = `300px`;
          }

          setTimeout(() => {
            setContractState((oldState) => {
              return {
                ...oldState,
                confirmed: true,
              };
            });
            setConfirmedState((oldState) => {
              return {
                ...oldState,
                confirmed: true,
              };
            });
          }, 800);
        })
        .catch((err) => {
          if (err.status === VERSION_ERROR_CODE) {
            setShowValidationWarning(true);
            setStatus(Status.DISABLED);
            return;
          }
          setStatus(Status.ERROR);
          setTimeout(() => {
            setStatus(Status.DEFAULT);
          }, 3000);
        });
    }, 400);
  }, [
    confirmed.confirmedTerms,
    linkId,
    setConfirmedState,
    setContractState,
    setShowValidationWarning,
    version,
    width,
  ]);

  if (!contract) {
    return null;
  }

  if (!isPrimary(currentAssociate)) {
    return null;
  }

  const buttonStatus = getButtonStatus(status);

  return (
    <div>
      <ContractAdditionalDocs
        linkId={linkId}
        mcc={mcc}
        confirmedTerms={confirmed.confirmedTerms}
        onChange={onChange}
        country={country}
        disabled={confirmed.confirmed}
        terminals={terminals}
      />

      <StatusBoxes status={status}>
        <ErrorBox>{t("Could not save confirmation. Try again?")}</ErrorBox>
        <WarningBox>{t("Please confirm that you have read documents")}</WarningBox>
      </StatusBoxes>

      <FadeAnimation open={!confirmed.confirmed}>
        <div className="confirm-button">
          <button
            ref={buttonRef}
            onClick={onConfirm}
            className={cx("button", "block", "m-top-10", buttonStatus, {
              animate: animate === AnimState.ANIMATING,
              done: animate === AnimState.DONE,
              confirmed: confirmed.confirmed,
            })}
          >
            {animate === AnimState.INITIAL && (
              <div>
                {t("Confirm contract details")} {getIcon(buttonStatus)}
              </div>
            )}
          </button>
        </div>
      </FadeAnimation>
    </div>
  );
};
