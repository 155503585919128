import React, { useEffect, PropsWithChildren } from "react";
import ReactModal from "react-modal";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import {
  AnimatePresence,
  motion,
  PanInfo,
  useDragControls,
} from "framer-motion";
import "./Sheet.scss";
import { Close } from "../icons/Close";
import cx from "classnames";

ReactModal.setAppElement("#root");

interface Props {
  showModal: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  maxWidth?: number | string;
  className?: string;
  closeOnOverlayClick?: boolean;
  swipeToClose?: boolean;
  /**
   * initial sheet animation
   * @default true
   *
   */
  initial?: boolean;
  onAfterClose?: () => void;
  onAfterOpen?: () => void;
  contentClassName?: string;
  fullHeight?: boolean;
  disableScrollLock?: boolean;
  noPadding?: boolean;
}

export const Sheet: React.FunctionComponent<Props> = ({
  showModal,
  onClose,
  children,
  maxWidth,
  className,
  closeOnOverlayClick = false,
  swipeToClose = true,
  initial = true,
  onAfterClose,
  onAfterOpen,
  contentClassName,
  fullHeight = false,
  disableScrollLock = false,
  noPadding = false,
}) => {
  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const afterCloseHandler = () => {
    !disableScrollLock && enableBodyScroll(document.body);
    onAfterClose && onAfterClose();
  };

  const afterOpenHandler = () => {
    !disableScrollLock &&
      disableBodyScroll(document.body, {
        reserveScrollBarGap: true,
      });
    onAfterOpen && onAfterOpen();
  };

  return (
    <ReactModal
      overlayClassName="sheet"
      closeTimeoutMS={500}
      isOpen={showModal}
      style={{
        content: {
          padding: 0,
          border: 0,
          inset: "unset",
          width: "100%",
          overflow: "visible",
          bottom: 0,
          background: "transparent",
        },
      }}
      onRequestClose={() => {
        afterCloseHandler();
        onClose && onClose();
      }}
      shouldCloseOnOverlayClick={closeOnOverlayClick}
      onAfterClose={afterCloseHandler}
      onAfterOpen={afterOpenHandler}
    >
      <AnimatePresence initial={initial}>
        {showModal && (
          <InnerSheet
            children={children}
            onClose={() => {
              afterCloseHandler();
              onClose && onClose();
            }}
            initial={initial}
            swipeToClose={swipeToClose}
            contentClassName={contentClassName}
            className={className}
            fullHeight={fullHeight}
            maxWidth={maxWidth}
            noPadding={noPadding}
          />
        )}
      </AnimatePresence>
    </ReactModal>
  );
};

interface InnerProps {
  onClose?: () => void;
  initial?: boolean;
  swipeToClose: boolean;
  contentClassName?: string;
  className?: string;
  fullHeight?: boolean;
  maxWidth?: number | string;
  noPadding: boolean;
}

const InnerSheet: React.FunctionComponent<PropsWithChildren<InnerProps>> = ({
  onClose,
  initial = true,
  children,
  swipeToClose,
  contentClassName,
  className,
  fullHeight,
  maxWidth,
  noPadding,
}) => {
  const controls = useDragControls();

  const handleDragStart = (event: any) => {
    controls.start(event);
  };

  const handleDragEnd = (
    _: MouseEvent | TouchEvent | PointerEvent,
    { offset, velocity }: PanInfo
  ) => {
    if (swipeToClose && (velocity.y > 150 || offset.y > 150)) {
      onClose?.();
    }
  };

  return (
    <motion.div className={cx("sheet-container", className)}>
      <motion.div
        className="sheet-overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.45 }}
        exit={{ opacity: 0 }}
      />
      <motion.div
        className={cx("sheet-body", {
          "full-height": fullHeight,
        })}
        style={{
          maxWidth,
        }}
        dragConstraints={{ top: 0, bottom: swipeToClose ? undefined : 0 }}
        dragElastic={0.1}
        drag="y"
        dragSnapToOrigin={true}
        dragControls={controls}
        dragListener={false}
        onDragEnd={handleDragEnd}
        initial={{ y: initial ? "100%" : 0 }}
        animate={{
          y: 0,
          transition: { type: "spring", stiffness: 200, damping: 20 },
        }}
        exit={{ y: "100%", transition: { bounce: false } }}
      >
        <motion.div className="sheet-handle" onPointerDown={handleDragStart}>
          <div className="sheet-handle__bar" />
          {onClose && (
            <button className="sheet-handle__close" onClick={onClose}>
              <Close />
            </button>
          )}
        </motion.div>
        <div
          className={cx("sheet-content", contentClassName, {
            "sheet-content-no-padding": noPadding,
          })}
        >
          {children}
        </div>
      </motion.div>
    </motion.div>
  );
};
