import { useEffect } from "react";
import { Sheet } from "../modal/Sheet";
import { PdfViewer } from "../pdfViewer/PdfViewer";
import "./ContractSheet.scss";

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  url: string;
}

export const ContractSheet = ({
  isOpen = false,
  onRequestClose,
  url,
}: Props) => {
  return (
    <Sheet
      showModal={isOpen}
      closeOnOverlayClick={true}
      onClose={() => onRequestClose()}
      maxWidth={1200}
      contentClassName="contract-sheet-content"
      className="contract-sheet"
      fullHeight
      disableScrollLock
    >
      <SheetBody url={url} />
    </Sheet>
  );
};

const SheetBody = ({ url }: { url: string }) => {
  // disable overflow scrolling on mobile to prevent issues with pdf.js
  useEffect(() => {
    document.body.style.overscrollBehavior = "none";

    // set meta name="viewport" to prevent zooming on mobile
    const viewport = document.querySelector('meta[name="viewport"]');
    const originalViewportContent = viewport?.getAttribute("content");
    viewport?.setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    );

    const touchMoveListener = (event: TouchEvent) => {
      event.preventDefault();
    };

    document.addEventListener("touchmove", touchMoveListener, {
      passive: false,
    });

    return () => {
      document.body.style.overscrollBehavior = "auto";
      document.removeEventListener("touchmove", touchMoveListener);
      viewport?.setAttribute("content", originalViewportContent || "");
    };
  }, []);

  return (
    <>
      <PdfViewer url={url} />
    </>
  );
};
