import React from "react";
import cx from "classnames";

interface Props {
  className?: string;
}

export const Check: React.FC<Props> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={cx("icon", "icon-checkmark", className)}
  >
    <polyline points="20 6 9 17 4 12"></polyline>
  </svg>
);
