import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../../../components/cards/Card";
import { Associate as AssociateInterface } from "../../../data/dataMerchant";
import { Associate } from "./Associate";
import "./Associates.scss";

interface Props {
  associates: AssociateInterface[];
}

export const Associates: React.FunctionComponent<Props> = ({ associates }) => {
  const { t } = useTranslation();

  return (
    <div className="owners">
      <Card header={t("Owners / Legal representatives")}>
        {associates.map((associate) => (
          <Associate associate={associate} key={associate.associateId} />
        ))}
      </Card>
    </div>
  );
};
