import React from "react";
import ReactCountryFlag from "react-country-flag";
import { Language, Country } from "../../../data/models/ContractTypes";
import "./Flag.scss";

interface Props {
  lang: Language | Country | "gb";
}

export const Flag: React.FunctionComponent<Props> = ({ lang }) => {
  return (
    <ReactCountryFlag
      className="flag rounded"
      style={{
        width: "auto",
        height: "20px",
      }}
      svg
      countryCode={lang.toUpperCase()}
    />
  );
};
