import { FC } from "react";
import { useRecoilValue } from "recoil";
import {
  viewerAsAssociateSelector,
  isPrimary,
  associateState,
  isAccountHolder,
  isSignee,
} from "../../../state/associateState";
import styles from "./Intro.module.scss";
import { Trans } from "react-i18next";
import { TI } from "../../../data/models/CommonTypes";
import { SalesContact } from "../SalesContact/SalesContact";
import { viewerState } from "../../../state/viewerState";
import { contractState } from "../../../state/contractState";
import { Associate, AssociateRole } from "../../../data/dataMerchant";

const Intro = () => {
  const { name } = useRecoilValue(viewerState);

  const currentAssociate = useRecoilValue(viewerAsAssociateSelector);
  const isAssociatePrimary = isPrimary(currentAssociate);

  const { contract } = useRecoilValue(contractState);
  const { companyName } = contract.contractData;

  return (
    <div className={styles.root}>
      <div className="text-large m-bottom-1em">
        <Trans>
          Hi <strong>{{ name } as TI}</strong>!
        </Trans>
      </div>

      <p className="m-bottom-20">
        {isAssociatePrimary ? (
          <Trans>
            We're pleased that you've chosen Worldline as your payment service. This is the
            information we have put together about <strong>{{ companyName } as TI}</strong>. We
            would like you to confirm that it is correct.
          </Trans>
        ) : (
          <AssociatesIntro companyName={companyName} viewerAsAssociate={currentAssociate} />
        )}
      </p>

      <SalesContact />
    </div>
  );
};

export default Intro;

interface AssociateIntroProps {
  companyName: string;
  viewerAsAssociate: Associate | undefined;
}

export const AssociatesIntro: FC<AssociateIntroProps> = ({ companyName, viewerAsAssociate }) => {
  const associates = useRecoilValue(associateState);
  const validRoles = viewerAsAssociate?.roles.filter(
    (role) => role !== AssociateRole.PORTAL_LOGIN && role !== AssociateRole.STORE_CONTACT
  );
  const isSingleAccountHolder = isAccountHolder(viewerAsAssociate) && validRoles?.length === 1;
  const primaryContact = associates.find(
    (item) => item.roles.indexOf(AssociateRole.PRIMARY_CONTACT) > -1
  );
  const isAssociateSignee = isSignee(viewerAsAssociate);
  const primaryContactName =
    primaryContact?.contact.firstName + " " + primaryContact?.contact.lastName;

  const renderText = () => {
    if (isSingleAccountHolder)
      return (
        <Trans>
          <strong>{{ companyName } as TI}</strong> has been registered for Worldline payment
          services by <strong> {{ primaryContactName } as TI} </strong>. In order to proceed with
          the registration we need a bank account associated with the company. We've been notified
          that you can help us with verifying such a bank account.
        </Trans>
      );

    if (isAssociateSignee) {
      <Trans>
        <strong>{{ companyName } as TI}</strong> has been registered for Worldline payment services
        by <strong>{{ primaryContactName } as TI}</strong>. Since you are a person authorized to
        sign for the firm we need a signature from you and also an image of a valid identification,
        e.g. image of your passport or another type of id, in order to initiate the service.
      </Trans>;
    }

    return (
      <Trans>
        <strong>{{ companyName } as TI}</strong> has been registered for Worldline payment services
        by <strong> {{ primaryContactName } as TI}</strong>. Since you are an owner we are in need
        of an image of a valid identification, e.g. image of your passport or another type of id, in
        order to initiate the service.
      </Trans>
    );
  };

  return renderText();
};
