import { useRecoilValue } from "recoil";
import { associateState, legalAssociatesSelector } from "../../state/associateState";
import { contractState } from "../../state/contractState";
import { BankAccount } from "./bankAccount/BankAccount";
import { ContractData } from "./contractData/ContractData";
import { Associates } from "./associates/Associates";
import { PortalLogins } from "./portalLogins/PortalLogins";
import { Stores } from "./stores/Stores";
import { ContractFeature } from "../../data/dataMerchant";
import { FeatureSwitch } from "../../components/featureSwitch/FeatureSwitch";
import { MultipleStores } from "./stores/MultipleStores";
import AdvancedPricing from "./pricing/AdvancedPricing";

export function ContractInfo() {
  const contractData = useRecoilValue(contractState);
  const { contract, features } = contractData;
  const associates = useRecoilValue(associateState);
  const signeesAndOwners = useRecoilValue(legalAssociatesSelector);

  return (
    <>
      <ContractData contract={contract} associates={associates} />

      <FeatureSwitch feature={ContractFeature.ADVANCED_PRICING}>
        <FeatureSwitch.Feature>
          <AdvancedPricing contract={contract} />
          <MultipleStores contract={contract} />
        </FeatureSwitch.Feature>
        <FeatureSwitch.Default>
          <Stores contract={contract} />
        </FeatureSwitch.Default>
      </FeatureSwitch>

      <PortalLogins associates={associates} />

      <BankAccount contract={contract} associates={associates} features={features} />

      <Associates associates={signeesAndOwners} />
    </>
  );
}
