import React, { FC } from "react";
import styles from "./SteppedBasedPricing.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { FeeBrandLogo, sortBrands } from "../FeeBrandLogo";
import StepPricingTableDisplay from "./StepPricingTableDisplay/StepPricingTableDisplay";
import {
  SteppedBasedPricing as ISteppedBasedPricing,
  brandArray,
} from "../../../../data/models/PricingTypes";
import { Country } from "../../../../data/models/ContractTypes";

interface Props {
  steppedBasedPricing: ISteppedBasedPricing;
  country: Country;
}

const SteppedBasedPricing: FC<Props> = ({ steppedBasedPricing, country }) => {
  const { t } = useTranslation();

  const sortedBrands = brandArray.sort(sortBrands);

  return (
    <div className={styles.root} id="transaction-fees">
      <div className={styles.header}>
        <h5>{t("Transaction fees")}</h5>
        <p>
          <Trans>
            The selected price plan is of type stepped based pricing. This means that the
            transaction fee adjusts based on the previous month's turnover. All new merchants start
            in the lowest threshold band
          </Trans>
        </p>
      </div>

      <div className={styles.content}>
        <ul className={styles.cards}>
          {sortedBrands.map((brand) => (
            <li key={brand}>
              <FeeBrandLogo brand={brand} />
            </li>
          ))}
        </ul>
        <StepPricingTableDisplay
          country={country}
          key="stepped-display-table"
          steppedBasedPricing={steppedBasedPricing}
        />
      </div>
    </div>
  );
};

export default SteppedBasedPricing;
